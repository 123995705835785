<template>
  <div>
    <!-- {{this.objCoResult}} -->
    <v-layout class="mt-3">
      <v-col class="pb-0 pt-1" style="width:100%; height:67px; border:1px solid black">
        <v-row >
          <v-col col="7" class="pt-7">
            <v-row style="text-align:center; border-right:1px solid black; justify-content: center;" class="pt-3">
              <p class="result-number pt-0 pl-5 pr-2"><span class="meron-text">Number 1</span> =  <span class="result-number">{{ objSummaryCoResult['Number1']}}</span>, </p>
              <p class="result-number px-2"><span class="wala-text">Number 2</span>  =  <span class="result-number">{{ objSummaryCoResult['Number2']}}</span>, </p>
              <p class="result-number px-2"><span class="wala-text">Number 3</span>  =  <span class="result-number">{{ objSummaryCoResult['Number3'] }}</span>,</p>
              <p class="result-number px-2"><span class="meron-text">Number 4</span>  =  <span class="result-number">{{ objSummaryCoResult['Number4']}}</span>,</p>
              <p class="result-number px-2"><span class="wala-text">Number 5</span>  =  <span class="result-number">{{ objSummaryCoResult['Number5']}}</span>,</p>
              <p class="result-number px-2"><span class="wala-text">Number 6</span>  =  <span class="result-number">{{ objSummaryCoResult['Number6']}}</span>,</p>
              <p class="result-number px-2"><span class="cancel-text">CANCEL</span>  =  <span class="result-number">{{ objSummaryCoResult.CANCEL}}</span>,</p>
            </v-row>
          </v-col>
          <v-col col="5" class="pt-5">
            <v-row style="text-align:center; border-left:1px solid black; justify-content: center;" class="pt-3">
              <p class="result-number px-2"><span class="text-warning">Games</span>  =  <span class="result-number">{{ channel.fight_no}}</span></p>
              <p class="result-number px-2"><span class="text-success">Group</span>  =  <span class="result-number">{{  channel.group_no}}</span></p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
    <v-row>
      <v-layout class="table-result" style="width:100%!important">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          <tr v-if="orderResult.length > 0">
            <td v-for="(result, i) in orderResult" :key="i" style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span v-if="result.fight_no" height="50px"> {{result.fight_no }} </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span v-if="result.result1_name == 'CANCEL'" class="cancel-color"> <h3 style="margin-top: 10px;">C</h3> </span> 
                  <v-img v-else height="60px"  class="pair-card" :src="'images/taisiev/' + switchResult(result.result1_name)"> </v-img>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span v-if="result.result2_name == 'CANCEL'" class="cancel-color"> <h3 style="margin-top: 10px;">C</h3> </span> 
                  <v-img v-else height="60px"  class="pair-card" :src="'images/taisiev/' + switchResult(result.result2_name)"> </v-img>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span v-if="result.result3_name == 'CANCEL'" class="cancel-color"> <h3 style="margin-top: 10px;">C</h3> </span> 
                  <v-img v-else height="60px"  class="pair-card" :src="'images/taisiev/' + switchResult(result.result3_name)"> </v-img>
              </div>  
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span v-if="result.bigsmall_name == 'CANCEL'" class="cancel-color"> <h3 style="margin-top: 10px;">C</h3> </span>
                <span v-else :class="result.bigsmall_name ==  'big' ? 'big-color' : 'small-color'"> <h3 style="margin-top: 10px;">{{result.bigsmall_name == "big" ? "B" : "S"  }}</h3> </span>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span v-if="result.oddeven_name == 'CANCEL'" class="cancel-color"> <h3 style="margin-top: 10px;">C</h3> </span>
                <span v-else :class="result.oddeven_name ==  'even' ? 'big-color' : 'small-color'" > <h3 style="margin-top: 10px;">{{result.oddeven_name == "odd" ? "O" : "E" }}</h3> </span>
              </div>  
            </td>                                 
        </tr>
        <tr v-else>
          <td  style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  &nbsp;
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>  
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
                </div>  
                <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
                </div>  
            </td>  
        </tr>
        </tbody>
      </table>
    </v-layout>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ['objCoResult', 'channel', 'objSummaryCoResult', 'objCoTypeOfBetting', "loadingResult"],
  data() {
    return {
      dialogConfirm: false,
      defineResult: {
        chanel_id: null,
        resultTypeId: null,
        sub: []
      },
      disableBlue: false,
      disableRed: false,
      break: false,
      allowToClick: true,
      loadingData: false,
      noCid : 1,
      noRid : 1,
      totalResult : 0,
      rows: 3,
      cols: 20
    }
  },

  computed: {
    orderResult() {
      return this.getDragonResults();
    },
    ...mapGetters("$_taisievChannel", {
      dialogResult: "getdialogResult",
    }),
  },

  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting: function () {
    },
    channel: function () {
    },
    objSummaryCoResult: function () {
    },
    allowToClick: function () { }
  },

  mounted() {

  },

  methods: {

    _select(r, c) {
      this.disableRed = false;
      this.disableBlue = false;
      this.defineResult.rid = r
      this.defineResult.cid = c
      // this.dialogResult = true
      for (let i = 0; i < this.objCoResult.length; i = i + 1) {
        if (this.objCoResult[i].name != "CANCEL" && this.objCoResult[i].name != "TIE") {
          if (this.defineResult.rid > 1 && this.objCoResult[i].name == "MERON") this.disableRed = true;
          if (this.defineResult.rid > 1 && this.objCoResult[i].name == "WALA") this.disableBlue = true;
          if (this.defineResult.rid == 1 && this.objCoResult[i].name == "WALA") this.disableRed = true;
          if (this.defineResult.rid == 1 && this.objCoResult[i].name == "MERON") this.disableBlue = true;
          break;
        }
      }


    },
    //Match result column and row
    matchResult(r, c) {
      let flag, code, name, pBanker, pPlayer, f_no;
      let newResult = this.getDragonResults();
     
      newResult.forEach(item => {
        if (item.row === r && item.col === c) {
          flag = true
          code = item.color
          pBanker = item.is_pair_banker
          pPlayer = item.is_pair_player
          name = this.switchResult(item.name)
          f_no = item.fight_no
          return false;
        }

      })
      if (flag) {
        return {
          color: code,
          pBanker: pBanker,
          pPlayer: pPlayer,
          name: name,
          fightNo: f_no,
          disable: true
        }
      } else {
        return {
          color: "no-circle",
          disable: false
        }
      }
    },
    getDragonResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.objCoResult !== null){
          let resultList = this.objCoResult;
         
          for (var index = resultList.length - 1; index >= 0; index--) {
            const item = resultList[index];
            if(row <= this.rows){
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }else{
              row = 1
              col += 1
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }
          }
        }
        return newResult;
    },
    getListResult() {
      if (this.objCoResult) {
        let row = 1;
        let col = 1;
        let newResult = [];
        for (var index = 0 ; index <= this.objCoResult.length; index++) {
          const item = this.objCoResult[index];
          if (index == this.objCoResult.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            
              if (pre.row >= 6) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              
            }
          }
          // });
        }
        return newResult;
      }
    },

    saveResult() {
      this.defineResult.chanel_id = this.channel._id
      this.defineResult.resultTypeId = this.objCoTypeOfBetting[this.defineResult.result_code]._id;
      this.defineResult.sub[0] == 3 || this.defineResult.sub[1] == 3 ? this.defineResult.pairBanker = this.objCoTypeOfBetting[3]._id : this.defineResult.pairBanker = null;
      this.defineResult.sub[0] == 4 || this.defineResult.sub[1] == 4 ? this.defineResult.pairPlayer = this.objCoTypeOfBetting[4]._id : this.defineResult.pairPlayer = null;
      this.$emit("submitResult", this.defineResult);
      this.defineResult.result_code = null;
      this.defineResult.sub = [];
      if (this.loadingResult == false) {
        this.$emit("submitDynamicRes", false);
        this.dialogConfirm = false
        this.defineResult.rid = null
        this.defineResult.cid = null
      }
      else {
        window.location.reload();
      }
    },
    cancelResult() {
      this.defineResult.rid = null
      this.defineResult.cid = null
      this.defineResult.result_code = null
      this._closeDialogResult();
      // this.dialogResult = false
    },
    _closeDialogResult() {
      this.$emit("submitDynamicRes", false);
    },
    calculateColumn(c) {
      // if (this.channel.column - this.channel.fix_column > 0) {
      //   return c + (this.channel.column - this.channel.fix_column)
      // } else {
      //   return c;
      // }
      return c
    },
    switchResult(name) {
      let data;
      switch (name) {
        case '1':
          data = "0001.png";
          return data;
        case '2':
          data = "0002.png";
          return data;
        case '3':
          data ="0003.png";
          return data;
        case '4':
          data = "0004.png";
          return data;
        case '5':
          data = "0005.png";
          return data;
        case '6':
          data = "0006.png";
          return data;
      }
    },
    ...mapMutations("$_taisievChannel", [
      "POPUP_DIALOG_RESULT",
    ]),
  }
}
</script>


<style scoped>

.small-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.big-color {
  color: #ba2d4a;
  font-weight: bold;
}
.cancel-color {
  color: #cccccc;
  font-weight: bold;
}
.stage {
  width: 38px;
  height: 38px;
  padding: 3px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.range_4 {
  width:50px!important;
   background: radial-gradient(circle at 30% 120%, #323232, #79071b 90%, #000 100%)!important;
}
.range_5 {
   background: radial-gradient(circle at 30% 120%, #323232, #ff6882 90%, #000 100%)!important;
}
.range_1 {
  width:50px!important;
  height:50px!important;
   background: radial-gradient(circle at 30% 120%, #323232, #efc60d 90%, #000 100%)!important;
}
.range_2 {
  background: radial-gradient(circle at 30% 120%, #323232, #110de6 90%, #000 100%)!important;
}
.range_3 {
  background: radial-gradient(circle at 30% 120%, #323232, #090 90%, #000 100%)!important;
}
.l-result-icon {
  display: inline-block!important;
  width: 100%!important;
  height: 30px!important;
  margin: 0!important;
  padding-top: 16px;
    padding-left: 11px;
    color: white;
  border-radius: 50%!important;
  /* display: flex!important; */
  align-items: center!important;
  justify-content: center!important;
  position: relative!important;
  font-weight: 700!important;
  font-size: 20px!important;
  
}
.table-result {
  background-color: #acb8bd;
  overflow-x: scroll;
  width: 50%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}

.cancel-btn {
  color: white !important;
  ;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  ;
  background-color: #3F9542 !important;
}

.confirm-circle {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  padding: 20px;
  margin-top: 20px;
}

.meron {
  background-color: #b31013
}

.wala {
  background-color: #0b4592
}

.tie {
  background-color: #3F9542
}

.cancel {
  background-color: gray
}

.meron-text {
  color: #b31013
}

.wala-text {
  color: #0b4592
}

.tie-text {
  color: #3F9542
}

.cancel-text {
  color: gray
}

.fight-no-style {
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color: white
}

td:hover {
  background-color: #ffbf00 !important;
  ;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.p-banker {
  position: absolute;
  border-radius: 25px;
  left: 15px;
  top: 15px;
  width: 9px;
  height: 9px;
  background-color: #f44336;
}

.p-player {
  position: absolute;
  border-radius: 25px;
  right: 15px;
  bottom: 15px;
  width: 9px;
  height: 9px;
  background-color: #1876d2;
}

.result-block {
  position: relative;
}

.result-block .show-fightNo {
  visibility: hidden;
}

.result-block:hover .show-fightNo {
  visibility: visible;
  width: 55px;
  height: 55px;
  color: white;
  position: absolute;
  top: -28px;
  left: -27px;
  font-size: 17px;
  /* font-style: italic; */
  font-weight: bold;

}

.r-cir-blue {
  background-color: #0b4592;
}

.r-cir-red {
  background-color: #b31013;
}

.r-cir-green {
  background-color: #28a745;
}

.r-cir-cancel {
  background-color: grey;
}
.result-number{
  font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 15px
}
</style>

<template>
    <div>
      <v-app class="bgImage">
        <v-main>
          <v-container v-if="isOTPShow == true">
            <div class="otp-layout" style="margin-top: 30px;">
            <div class="otp-label">INPUT OTP CODE</div>
            <v-otp-input class="justify-content-center" ref="otpInput" inputClasses="otp-input"
                :numInputs="6" :is-input-num="true" separator="-" :shouldAutoFocus="true"
                @on-complete="handleOnComplete" @on-change="handleOnChange" />
            <div v-if="isOTPError" class="otp-error">{{ otpErrorMgs }}</div>
          </div>
        </v-container>
          <v-container v-else fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs12 sm8 md8>
                <v-card class="elevation-15 formLogin my-auto">
                  <v-row class="ma-0">
                    <v-flex xs12 sm12 md6 lg6 class="position-relative">
                      <div class="formLogin-bg"></div>
                      <v-img src="images/logo.png" class="logo"></v-img>
                      <div class="empty-space"></div>
                    </v-flex>
                    <v-flex xs12 sm12  md6 lg6 class="position-relative py-5 px-2">
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                          class="login-style"
                      >
                        <div
                          class="upper-case text-center justify-center font-weight-bold h2 text-white"
                        >
                          {{websiteName}}
                        </div>
                        <v-card-text style="padding: 2px 16px !important">
                          <p class="pt-1 pb-2 my-0 text-white" style="margin-right: 300px;">{{ $t('input.user_name') }}</p>
                          <v-text-field
                            style="color: #ffffff !important;"
                            dense
                            class="velo1"
                            v-model="user.user_name"
                            :rules="[v => !!v || 'Username is required']"
                            required
                            autocomplete="none"
                            solo
                          >
                            <template v-slot:prepend-inner style="z-index: 100000;font-size: 26px; ">
                              <v-icon color="white" class="icon-form-login" style="background: #0c407c;"
                                >mdi-account-outline
                              </v-icon>
                            </template>
                          </v-text-field>
                          <p class="my-0 pt-0 pb-2 text-white" style="margin-right: 300px;">{{ $t('input.password') }}</p>
                          <v-text-field
                            v-model="user.password"
                            class="velo1"
                            dense
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :rules="[v => !!v || 'Password is required']"
                            :type="showPassword ? 'text' : 'password'"
                            name="input-10-1"
                            counter
                            autocomplete="new-password"
                            @click:append="showPassword =! showPassword"
                            style="color: #ffffff !important"
                            solo
                          >
                            <template v-slot:prepend-inner style="font-size: 26px;">
                              <v-icon color="white" class="icon-form-login" style="background: #0c407c;"
                                >mdi-lock-outline
                              </v-icon>
                            </template>
                          </v-text-field>
                          <label style="color: red;">{{errorMessage}}</label>
                        </v-card-text>
                        <v-card-actions>
                          <v-col
                            class="col-4 pa-0 pt-0 mx-auto"
                          >
                            <v-btn
                              :loading="loading"
                              @click="login"
                              :disabled="loading"
                              block
                              outlined
                              color="#336699"
                              class="text-center justify-center font-weight-bolder submit-btn-login button-login"
                              style="border-radius: 5px; height:35px!important;"
                            >
                              {{ $t("title.login") }}
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                      </v-form>
                    </v-flex>
                  </v-row>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-main>
      </v-app>
    </div>
  </template>
  
  <script>
  import "@/assets/css/login1.css";
  import { mapActions, mapMutations } from "vuex";
  
export default {
    data: () => ({
      valid: true,
        user: {
            user_name: null,
            password: null,
            app_id: null
        },
        //rules: [(value) => !!value || "Required."],
        rules: {
          type: Object,
            default: function () {
              return {
                user_name: [(v) => !!v || "User name is required"],
                password: [(v) => !!v || "Password Name is required"]
              };
            },
        },
        loading: false,
        error: false,
        isOTPShow: false,
        tmpChannelUserId: null,
        show: false,
        isOTPError: false,
        otpErrorMgs: "Wrong OTP",
        tempChannelUser: null,
        description: "",
        websiteName:"SBC2888 ADMIN",
        showPassword: false,
        errorMessage: ""
    }),
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async handleOnComplete(value) {
            try {
                let otpData = {
                    user_id: this.tmpChannelUserId,
                    otp: value
                }
                const response = await this.fetchVerifyOtp(otpData)
                if (response.data.code) {
                    //success
                    let rolee = this.tempChannelUser.role
                    this.$cookies.set("token", response.data.data.token);
                    this.$cookies.set("channel-type", 1);
                    this.$cookies.set("user_role", rolee);
                    this.$cookies.set("local", 'kh');
                    this.$cookies.set("user", this.tempChannelUser);
                    this.$router.push({ path: "/dashboard" });
                } else {
                    this.$refs.otpInput.clearInput();
                    this.otpErrorMgs = response.data.message.descriptions;
                    this.isOTPError = true;
                }

            } catch (error) {
                this.$refs.otpInput.clearInput();
                this.otpErrorMgs = error.message;
                this.isOTPError = true;
            }
        },
        handleOnChange() {
            this.isOTPError = false;
        },
        async login() {
            if (this.$refs.form.validate()) {
                try {
                    this.loading = true;
                    this.user.app_id = process.env.VUE_APP_APP_ID;
                    const response = await this.fetchLogIn(this.user);
                    if (response.data.code
                        //condition dnt let user role player login in to admin system
                        && response.data.data.user.role != "61829df5e64b1b1768832700"
                        && response.data.data.user.role != "61829dfee64b1b1768832709"
                        && response.data.data.user.role != "61829e05e64b1b1768832712") {
                        const { token, uuid, user } = response.data.data;
                        if(user.role === "612a3fcc1a1fcead8871e822"){
                          this.UPDATE_EYE_IS_ON(false);
                        }else{
                          this.UPDATE_EYE_IS_ON(true);
                        }
                        this.$cookies.set("sbcad_sub_index", -1);
                        this.$cookies.set("sbcad_submenu_index", -1);
                        if (token == "") {
                            this.$cookies.set("uuid", uuid);
                            this.tempChannelUser = user
                            this.tmpChannelUserId = user._id
                            this.isOTPShow = true
                        } else {
                            let rolee = user.role
                            this.$cookies.set("token", token);
                            this.$cookies.set("menu_selected", '611fde700734048a0481201e');
                            this.$cookies.set("user_role", rolee);
                            this.$cookies.set("channel-type", 1);
                            this.$cookies.set("local", 'kh');
                            this.$cookies.set("user", user);
                            if(response.data.data.user.role == "6191dc644825e6f57ffa231f" || response.data.data.user.role == "6191dce94825e65937fa2340" || response.data.data.user.role == "6424080f50b77c6cf890ab11" || response.data.data.user.role == "6472bfce1ba4b8a1604940c2"){
                                this.$router.push({ path: "/userSubAgent" });
                            }else if(
                              response.data.data.user.role == "61829dc7e64b1b17688326d5" ||
                              response.data.data.user.role == "61829dd1e64b1b17688326df" ||
                              response.data.data.user.role == "61829de6e64b1b17688326f3" ||
                              response.data.data.user.role == "6424071f50b77c6cf890ab0d" ||
                              response.data.data.user.role == "6472bf9b1ba4b8a1604940c1"
                            ){
                                this.$router.push({ path: "/dashboard" });
                            }else if(response.data.data.user.role =="612a40a51a1fcead8871e833"){
                              this.$router.push({ path: "/userCompanyUnderManager" });
                            }else{
                                this.$router.push({ path: "/users" });
                            }
                        }

                    } else {
                        this.error = true;
                        this.description = response.data.message.descriptions;
                        this.errorMessage = response.data.message.descriptions;
                    }
                } catch (error) {
                    this.error = true;
                    
                    return error;
                } finally {
                    this.loading = false;
                }
            }
        },
        ...mapActions("$_auth", ["fetchLogIn", "fetchVerifyOtp"]),
        ...mapMutations("$_modules", ["UPDATE_EYE_IS_ON"])
    },
};
  </script>
  
  <style>
  .bgImage {
    background-image: url(*/../../images/login-page/login_background_pc.jpg) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: #e3e3e3 !important;
  }
  
  .bg {
    background-color: #6699cc !important;
  }
  /* 
  @media (max-width: 480px) {
    .screenMobile {
      width: 400px;
      padding: 0 25px 0 25px;
      margin-left: -2px !important;
      justify-content: center !important;
    }
  
    .noImageMobile {
      display: none !important;
    }
  } */
  
  .theme--light.v-input,
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: black !important;
    border: none !important;
  }
  </style>
  <style>
  .mdi-eye-off {
    color: black !important;
    background:#cccccc!important;
    padding:8.5px 15px;
    padding-right:5px!important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .mdi-eye {
      color: black !important;
      background:#cccccc!important;
      padding:8.5px 15px;
      padding-right:5px!important;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
  }
  .velo1 .v-text-field__slot{
    background:#cccccc !important;
    padding: 6px;

}
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
    margin-top: 0px!important;
}
  </style>
  <style scoped>
  .container{
      min-height: 90%;
  }
  .otp-layout {
    border: 1px solid hsla(0, 0%, 100%, 0.05);
    border-radius: 4px;
    padding: 20px;
    margin: auto;
    background-color: white;
    width: fit-content;
    height: fit-content;
}

.otp-label {
    color: black;
    text-shadow: red;
    font-size: 18px;
    text-align: center;
    margin: 8px;
    margin-bottom: 16px;
}

.otp-error {
    color: #f34141;
    text-shadow: #f8f6f6;
    font-size: 13px;
    text-align: center;
    margin: 8px;
}

.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;

    &.error {
        border: 1px solid red !important;
    }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  </style>
  
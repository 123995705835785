<template>
  <div>
    <div class="row" style="margin-top: 0px !important;">
      <div class="col-12" style="padding-top: 0px !important;">
        <div class="card">
          <div class="card-body">
            <div style="text-align: center; vertical-align: middle">
              <v-data-table
                :headers="headers"
                :items="getUsers ? getUsers.object : []"
                :footer-props="footerProps"
                item-key="_id"
                class="table table-striped"
                id="table-1"
                :server-items-length="
                  (getUsers && getUsers.objectCount) ? getUsers.objectCount.count : null
                "
                :page.sync="data.page"
                :items-per-page.sync="data.rowsPerPage"
                :sort-desc.sync="data.descendingFormat"
                :sort-by.sync="data.sortByFormat"
                @update:page="getData"
                @update:items-per-page="getData"
                @update:sort-desc="getData"
                :loading="getLoading"
                :value="getDeleteItems"
                @input="itemDelete"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item`]="{ item }">
                  <tr class="">
                    <td class="col-1 fit">
                      <span>
                        <a
                          style="font-weight: 400"
                          :class="
                            item.enable ? 'text-green' : 'text-red'
                          "
                          @click="_openDialogEnable(item)"
                          :disabled="userp && !userp.enable ? true : false"
                        >
                          {{ item.enable ? "Enable(Betting)" : "Not Enable(Betting)" }}
                        </a>
                      </span>
                      <span>
                        <a
                          style="font-weight: 400"
                          :class="
                            item.is_suspend ? 'text-red' : 'text-green'
                          "
                          @click="_openDialogSuspend(item)"
                          :disabled="userp && !userp.is_suspend ? false : true"
                        >
                          {{ item.is_suspend ? "| Suspend(Login)" : "| Active(Login)" }}
                        </a>
                      </span>
                    </td>
                    <td class="col-2">
                      {{ item.user_name }}
                    </td>
                    <td class="col-2 fit">
                      {{ item.app_name }}
                    </td>
                    <td class="col-2">
                      {{ item.account_info }}
                    </td>
                    <td class="col-2 fit">
                      {{ item.createdAt }}
                    </td>


                    <!--balance-->
                    <td class="col-1 fit">
                      <span v-if="item && (item.role_id == '612a3fcc1a1fcead8871e822')">
                        <span v-for="cm in item.company_balance" :key="cm.id">
                          <div v-if="cm.currency_type == 1">{{ currencyFormatUsd(cm.balance) }} $</div>
                          <div v-else-if="cm.currency_type == 2">{{ currencyFormatKh(cm.balance) }} ៛</div>
                          <div v-else-if="cm.currency_type == 4">{{ currencyFormatKh(cm.balance) }} đ</div>
                          <div v-else-if="cm.currency_type == 5">{{ currencyFormatKh(cm.balance) }} ฿</div>
                        </span>
                      </span>
                      <span v-else-if="item && (item.role_id === '61829d84e64b1b17688326a3')">
                        <span v-for="sn in item.senior_balance" :key="sn.id">
                          <div v-if="sn.currency_type == 1">{{ currencyFormatUsd(sn.balance) }} $</div>
                          <div v-else-if="sn.currency_type == 2">{{ currencyFormatKh(sn.balance) }} ៛</div>
                          <div v-else-if="sn.currency_type == 4">{{ currencyFormatKh(sn.balance) }} đ</div>
                          <div v-else-if="sn.currency_type == 5">{{ currencyFormatKh(sn.balance) }} ฿</div>
                        </span>
                      </span>
                      <span v-else-if="item && (item.role_id === '61829dbbe64b1b17688326cb')">
                        <span v-for="ms in item.master_balance" :key="ms.id">
                          <div v-if="ms.currency_type == 1">{{ currencyFormatUsd(ms.balance) }} $</div>
                          <div v-else-if="ms.currency_type == 2">{{ currencyFormatKh(ms.balance) }} ៛</div>
                          <div v-else-if="ms.currency_type == 4">{{ currencyFormatKh(ms.balance) }} đ</div>
                          <div v-else-if="ms.currency_type == 5">{{ currencyFormatKh(ms.balance) }} ฿</div>
                        </span>
                      </span>
                      <span v-else>
                        <span v-if="item && item.currency_type === 1">
                          {{ currencyFormatUsd(item.balance) }} $
                        </span>
                        <span v-if="item && item.currency_type === 2 ">
                          {{ currencyFormatKh(item.balance) }} ៛
                        </span>
                        <span v-if="item && item.currency_type === 4">
                          {{ currencyFormatKh(item.balance) }} đ
                        </span>
                        <span v-if="item && item.currency_type === 5">
                          {{ currencyFormatKh(item.balance) }} ฿
                        </span>
                      </span>
                      
                    </td>

                    <td class="col-1 fit">
                      {{ item.role_name }}
                    </td>

                    <td class="col-1 fit">
                      <a @click="_openDialogListIpUser(item)">
                        {{ item.public_ip }}
                      </a>
                    </td>
                    <td class="col-1 fit">
                      <a v-if="item && item.role_id == '61829df5e64b1b1768832700' ||
                        item.role_id == '61829dfee64b1b1768832709' ||
                        item.role_id == '61829e05e64b1b1768832712' ||
                        item.role_id == '6424075250b77c6cf890ab0e'"
                        style="color: white; font-weight: 400"
                        :class="
                          item.contact == 'WAITING' ? 'btn btn-warning' 
                          : item.contact == 'SUCCESS' ? 'btn btn-success'
                          : item.contact == 'REJECT' ? 'btn btn-danger'
                          :''
                        "
                        @click="_openDialogContact(item)"
                      >
                        {{ item.contact }}
                      </a>
                    </td>

                    <td class="col-2 fit">
                      <TransactionComponents
                        :darkmode="darkmode"
                        :item="item"
                        :params="data"
                        :userp="userp"
                        @refresh="transactionRefresh"
                      />
                    </td>
                    <td class="col-2 fit">
                      <ActionComponents
                        :darkmode="darkmode"
                        :item="item"
                        :dataDelete="getDeleteItems"
                        :params="data"
                        :userp="userp"
                        :getMenuByApp="getListMenuByApp"
                        :user-permission="userAccessPermission"
                      />
                    </td>
                  </tr>
                </template>

                <template v-slot:top>
                  <Header
                    :darkmode="darkmode"
                    :params="data"
                    :userp="userp"
                    :getAllApps="getListApp"
                    :dataDelete="getDeleteItems"
                    :user-permission="userAccessPermission"
                    @child-checkbox="checkboxValue"
                    v-on:child-checkbox="updateParent"
                  />
                </template>

              </v-data-table>
            </div>
            <DialogListIpUserComponents
              :dialog="ipDialog"
              :listData="listIpUserData"
              @onCloseDialog="ipDialog = !ipDialog"
            />
            <Dialog
              :darkmode="darkmode"
              :loading="loading"
              :user="getCurrentUser"
              :dialog="getPopUp"
              :userp="userp"
              :optionRoles="optionRoles"
              :is-validate="valid"
              :listCompany="getListUserCompany"
              :listSenior="getListUserSenior"
              :listApp="getListApp"
              :selectedChannel="selectedChannel"
              :selection="getTreeMenuSelection"
              :treeMenu="getTreeMenuByApp"
              v-on:submit="handleSubmit"
              v-on:handleListCompany="getListCompany"
              v-on:handleListSenior="getListSenior"
              v-on:onCloseDialog="closeDialogCreate"
            />

            <DialogBankUser
              :darkmode="darkmode"
              :dialog="getPopUpBankUser"
              :bankUser="getCurrentBankUser"
              :optionBankUser="getParentBankUser"
              :loading="loading"
              v-on:submit="handleSubmitBankUser"
              v-on:onCloseDialog="closeDialogBankUser"
            />

            <v-dialog v-model="suspendDialog" persistent max-width="500px">
              <div class="card">
                <div class="card-header">
                  <h4>Active(Login)/Suspend(Login)</h4>
                </div>
                <div class="card-body">
                  This will effect this user and its children. Do you want to
                  confirm?
                </div>
                <div class="card-footer text-right">
                  <button
                    class="btn btn-light mr-1"
                    @click="_closeDialogSuspend"
                  >
                    Close
                  </button>
                  <button
                    class="btn"
                    :class="
                      selectedUser && selectedUser.is_suspend
                        ? 'btn-success'
                        : 'btn-danger'
                    "
                    @click="_updateUserSuspendStatus()"
                  >
                    {{
                      selectedUser && selectedUser.is_suspend
                        ? "Active"
                        : "Suspend"
                    }}
                  </button>
                </div>
              </div>
            </v-dialog>
            <v-dialog v-model="enableDialog" persistent max-width="500px">
              <div class="card">
                <div class="card-header">
                  <h4>Enable(Betting)/Not Enable(Betting)</h4>
                </div>
                <div class="card-body">
                  This will effect this user and its children. Do you want to
                  confirm?
                </div>
                <div class="card-footer text-right">
                  <button
                    class="btn btn-light mr-1"
                    @click="_closeDialogEnable"
                  >
                    Close
                  </button>
                  <button
                    class="btn"
                    :class="
                      selectedUser && selectedUser.enable
                        ? 'btn-danger'
                        : 'btn-success'
                    "
                    @click="_updateUserEnableStatus()"
                  >
                    {{
                      selectedUser && selectedUser.enable
                        ? "Not Enable"
                        : "Enable"
                    }}
                  </button>
                </div>
              </div>
            </v-dialog>
            <v-dialog v-model="contactDialog" persistent max-width="500px">
              <v-card>
                <v-toolbar>
                  <v-btn icon @click="_closeDialogContact()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title class="text-uppercase">
                    <h4>Approve/Reject User's contact</h4>
                    </v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-container style="margin-top: 20px;">
                    <v-row justify="space-around">
                      <v-btn
                        depressed
                        color="primary"
                        @click="_approveContact">
                        Approve
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="_rejectContact">
                        Reject
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    TransactionComponents: () => import("./_components/Transaction"),
    DialogListIpUserComponents: () => import("./_components/DialogListIpUser"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
    DialogBankUser: () => import("./_components/DialogBankUser.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      loader: false,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "user_name",
        "balance",
        "account_info",
        "enable",
        "updatedAt",
      ],
      search: "",
      role_id : "all",
      app_id : "all",
    },
    role_id : "all",
    app_id : "all",
    dialog: false,
    dialogBankUser:false,
    contactDialog: false,
    loader: false,
    loading: false,
    selectedUser: [],
    suspendDialog: false,
    enableDialog: false,
    waiting: false,
    selectedChannel: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "20",
      "21",
    ],
    ipDialog: false,
    listIpUserData: [],
  }),
  computed: {
    headers() {
      return [
        { text: "Status", value: "enable", col: "col-1 fit" },
        // { text: "Suspend(Login)", value: "enable", col: "col-1 fit" },
        {
          text: "User name",
          value: "user_name",
          align: "start",
          col: "col-1 fit",
        },
        { text: "Website", value: "app_name", col: "col-1 fit" },
        { text: "Account Note", value: "account_info", col: "col-1 fit" },
        { text: "Created Date", value: "createdAt", col: "col-1 fit" },
        { text: "Balance", value: "balance", col: "col-1 fit" },
        { text: "Role", value: "role_name", col: "col-1 fit" },
        { text: "Ip Address", value: "public_ip", col: "col-1 fit" },
        { text: "Contact", value: "contact", col: "col-1 fit" },
        {
          text: "Cash-in / Cash-out",
          value: "transaction",
          sortable: false,
          col: "col-2 fit",
        },
        {
          text: "Setting",
          value: "actions",
          sortable: false,
          col: "col-2 fit",
        },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
    ...mapGetters("$_user", [
      "getUsers",
      "getPopUp",
      "getPopUpBankUser",
      "getCurrentUser",
      "getDeleteItems",
      "getLoading",
      "getListUserCompany",
      "getListUserSenior",
      "getListApp",
      "getCurrentBankUser",
      "getListMenuByApp",
      "getTreeMenuByApp",
      "getTreeMenuSelection"
    ]),
    ...mapGetters("$_role", { optionRoles: "getAllRolesDependOnUser" }),
    ...mapGetters("$_bankUser", [
      "getParentBankUser",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.fetchAllRolesDependOnUser();
    await this.getListCompany();
    await this.getListSenior();
    await this.fetchListApp();
    await this.fetchParentBankUser();
  },
  watch: {
    userp: function () {},
    optionRoles: function () {},
  },
  methods: {
    _openDialogListIpUser(item) {
      this.getListIpUserData(item._id);
      this.ipDialog = true;
    },
    async getListIpUserData(_id) {
      let data = {
        user_id: _id,
      };
      await this.$request
        .post({
          url: "/users/getLoginIpByUser",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.listIpUserData = res.data.data;
            // this.waiting = false;
          }
        });
    },
    async handleSubmit(payload) {
      this.loading = true;
      this.getCurrentUser.selection = payload;
      if (this.getCurrentUser.app_id == null) {
        this.getCurrentUser.app_id = "62e397ceae71cfa8782aa325";
      }
      if (!this.getCurrentUser._id) {
        if (this.getCurrentUser.account_info == null)
          this.getCurrentUser.account_info = "";
        if (this.getCurrentUser.role_id.child_role_name)
          this.getCurrentUser.role_id =
            this.getCurrentUser.role_id.child_role_id;
        if (this.userp && this.userp.role_id == "61829dbbe64b1b17688326cb") {
          this.getCurrentUser.role_id == "61829dd1e64b1b17688326df"
            ? (this.getCurrentUser.currency_type = 1)
            : this.getCurrentUser.role_id == "61829dc7e64b1b17688326d5"
            ? (this.getCurrentUser.currency_type = 2)
            : this.getCurrentUser.role_id == "6424071f50b77c6cf890ab0d"
            ? (this.getCurrentUser.currency_type = 4)
            : this.getCurrentUser.role_id == "6472bf9b1ba4b8a1604940c1"
            ? (this.getCurrentUser.currency_type = 5)
            : "";
        }
        this.getCurrentUser.user_name = this.getCurrentUser.user_name.toLowerCase();
          // console.log("this.getCurrentUser",this.getCurrentUser);
        await this.createUser({ vm: this, data: this.getCurrentUser });
      } else {
       await this.updateUser({ vm: this, data: this.getCurrentUser });
      }
      await this.getData();
      this.loading = false;
    },
    async handleSubmitBankUser() {
      this.getCurrentBankUser.user_id  = this.getCurrentUser._id;
      this.loading = true;
      if (!this.getCurrentBankUser._id) {
        await this.createBankUser({ vm: this, data: this.getCurrentBankUser });
      } else {
        await this.updateBankUser({ vm: this, data: this.getCurrentBankUser });
      }
      this.fetchPopUpBankUser(false);
      await this.getData();
      this.loading = false;
    },

    checkboxValue: function(params) {
      this.data.app_id = params.app_id;
      this.data.role_id = params.role_id;
    },

    updateParent(payload){
      this.data.app_id = payload.app_id;
      this.data.role_id = payload.role_id;
    },
    _openDialogContact(item) {
      this.selectedUser = item;
      this.contactDialog = true;
    },
    _closeDialogContact() {
      this.selectedUser = [];
      this.contactDialog = false;
    },
    async _approveContact() {
      this.waiting = true;
      let data = {
        status: 'SUCCESS',
        user_id: this.selectedUser._id, 
      };
      await this.$request
        .post({
          url: "/users/updateContact",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData();
            this._closeDialogContact();
            this.waiting = false;
          }
        });
    },
    async _rejectContact() {
      this.waiting = true;
      let data = {
        status: 'REJECT',
        user_id: this.selectedUser._id, 
      };
      await this.$request
        .post({
          url: "/users/updateContact",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData();
            this._closeDialogContact();
            this.waiting = false;
          }
        });
    },

    async transactionRefresh() {
      this.loading = true;
      await this.fetchListUsers(this.data);
      this.loading = false;
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListUsers(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    async closeDialogCreate() {
      this.fetchPopUp(false);
      await this.fetchAllRolesDependOnUser();
    },
    async getListCompany() {
      await this.fetchListCompany();
    },
    async getListSenior() {
      await this.fetchListSenior();
    },

    _openDialogEnable(item) {
      this.selectedUser = item;
      this.enableDialog = true;
    },
    _openDialogSuspend(item) {
      this.selectedUser = item;
      this.suspendDialog = true;
    },
    _closeDialogSuspend() {
      this.selectedUser = [];
      this.suspendDialog = false;
    },
    _closeDialogEnable() {
      this.selectedUser = [];
      this.enableDialog = false;
    },
    closeDialogBankUser() {
      this.fetchPopUpBankUser(false);
    },

    async _updateUserSuspendStatus() {
      this.waiting = true;
      let data = {
        is_suspend: !this.selectedUser.is_suspend,
        userId: this.selectedUser._id, 
        // channel_type : this.channel_type
      };
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData();
            this._closeDialogSuspend();
            this.waiting = false;
          } else {
            this.getData();
            this._closeDialogSuspend();
            this.waiting = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      // this.targetChannel = [];
      // this.channel_type = null
      // this.confirmDialog = false;
    },

    async _updateUserEnableStatus() {
      this.waiting = true;
      let data = {
        enable: !this.selectedUser.enable,
        userId: this.selectedUser._id, 
        // channel_type : this.channel_type
      };
      await this.$request
        .post({
          url: "/users/enableUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData();
            this._closeDialogEnable();
            this.waiting = false;
          } else {
            this.getData();
            this._closeDialogEnable();
            this.waiting = false;
          }
        });
    },

    ...mapMutations("$_user", [
      "UPDATE_CURRENT_USER",
      "DELETE_ITEMS",
      "UPDATE_USERS",
      "UPDATE_USER",
    ]),
    ...mapMutations("$_role", ["UPDATE_ROLES_DEPEND_ON_USER"]),
    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", [
      "fetchPopUp",
      "createUser",
      "fetchCurrentUser",
      "updateUser",
      "fetchListUsers",
      "fetchListCompany",
      "fetchListSenior",
      "fetchListApp",
      "fetchListMenuByApp",
      "fetchPopUpBankUser",
    ]),
    ...mapActions("$_bankUser", [
      "fetchParentBankUser",
      "createBankUser",
      "updateBankUser",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
.table:not(.table-sm):not(.table-md):not(.dataTable) td,
.table:not(.table-sm):not(.table-md):not(.dataTable) th {
  height: 45px !important;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
  vertical-align: middle !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
}
.text-green{
  color: green  !important;
}

.text-red{
  color: red  !important;
}
</style>

import historyByFightHan3 from '@/modules/historyByFightHan3';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightHan3',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightHan3,
        name: 'historyByFightHan3'
    }]
}];

export default routes;
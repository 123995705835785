import historyByFightYuki from '@/modules/historyByFightYuki';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightYuki',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightYuki,
        name: 'historyByFightYuki'
    }]
}];

export default routes;
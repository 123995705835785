<template>
  <div>
    <div class="section-header" hidden>
      <h1>Activity Log</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Activity Log</div>
      </div>
    </div>
    <div class="section-body">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive" style="text-align: center">
            <v-data-table
              style="font-family: 'khmer mef1'"
              item-key="_id"
              class=" table table-striped"
              id="table-1"
              :headers="headers"
              :items="getLogs && getLogs.object ? getLogs.object : []"
              :footer-props="footerProps"
              :server-items-length="getLogs ? getLogs.objectCount : null"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              hide-default-header
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              @input="itemDelete"
              :loading="getLoading"
              show-select
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :user-permission="userAccessPermission"
                  @child-checkbox="checkboxValue"
                />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <ActionComponents
                  :darkmode="darkmode"
                  :item="item"
                  :params="data"
                  :user-permission="userAccessPermission"
                />
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/Header.vue"),
  },
  data: () => ({
    valid: true,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "desc", "type", "created_name","channel_type"],
      search: "",
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
    },
    loading: false,
  }),
  computed: {
    headers() {
      return [
      { text: "Type", align: "start", value: "type" },
        { text: "Name", align: "start", value: "name" },
        {
          text: this.$t("header.description"),
          value: "desc",
          sortable: false,
        },
        { text: "Channel Type", align: "start", value: "channel_type" },
        { text: "Create Name", align: "start", value: "created_name" },
        {
          text: this.$t("header.created_at"),
          value: "createdAt",
          sortable: false,
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_menu", ["getTreeMenu"]),
    ...mapGetters("$_activityLog", [
      "getLogs",
      "getPopUp",
      "getLoading",
    ]),
  },
  
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
     
      await this.getData();
      this.loading = false;
    },
    checkboxValue: function (params) {
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
      this.data.search = params.search;
    },
    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListLogs(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },
    ...mapMutations("$_activityLog", ["DELETE_ITEMS", "UPDATE_LOGS"]),
    ...mapActions("$_activityLog", [
      "fetchListLogs",
      "fetchPopUp"
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_LOGS([]);
    next();
  },
};
</script>
<style>
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

import reverseYukiCh11 from '@/modules/reverseResult/yukiCh11';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseYukiCh11',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseYukiCh11,
        name: 'reverseYukiCh11'
    }]
}];

export default routes;
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    yukiSettingData: {
        _id: null,
        name: null,
        is_enable: null,
        is_start: null,
        user_id: null,
        channel_type: null,
        max_lose: 0,
        max_win: 0,
        percentage: 0,
        updatedAt: null
    },
    currentYukiSettingData:{},
    loading: false,
    popUp: false,
    popUpPercent: false,
    deleteItems: [],
    userConfigData:[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
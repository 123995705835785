/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
let request = new Request;
const fetchGetAllVipPercentage = async ({ commit }, data) => {
    const response = await request.post({
        url: 'vip-percentage/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_PAYOUT_DATA', response.data.data);
    }
};
const updatePayout = async ({ commit }, { vm, data, _id }) => {
    commit('LOADINGPAYOUT', true , { root: true });
    // console.log(data);
    const response = await request.put({
        url: 'vip-percentage/update/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_PAYOUT_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentVipPercentageData = async ({commit}, payload) => {
    // console.log("hihi");
    commit('UPDATE_CURRENT_DATA', payload);
};

export default {
    fetchGetAllVipPercentage,
    updatePayout,
    fetchPopUp,
    fetchCurrentVipPercentageData,


};
const UPDATE_LOGS = (state, data) => {
    state.logs = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentRole = {
            role_name: null,
            desrc: null,
            menu: []
        }
        state.permissionSelection = []
    }
    state.popUp = data;
};
const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

export default {
    UPDATE_LOADING,
    UPDATE_LOGS,
    UPDATE_POPUP
};

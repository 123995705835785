const getLot3SettingData = (state) => state.lot3SettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentLot3SettingData = (state) => state.currentlot3SettingData;


export default {
    getLot3SettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentLot3SettingData,
};
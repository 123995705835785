const getStatement = (state) => {
    return state.statement;
};

const getCountStatement = (state) => {
    return state.countStatement;
};
const getChannelType = (state) => {
    return state.channelType;
};
const getLoading = (state) => {
    console.log('loading...');
    return state.loading;
}

const getParentLimitData = (state) => {
    return state.parentLimitData;
};
const getListAppMenu = (state) => state.listAppMenu;
const getListMenuByApp = (state) => state.listMenuByApp;


export default {
    getLoading,
    getStatement,
    getCountStatement,
    getChannelType,
    getParentLimitData,
    getListAppMenu,
    getListMenuByApp
};
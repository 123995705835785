import historyByFightBaccarat from '@/modules/historyByFightBaccarat';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightBaccarat',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightBaccarat,
        name: 'historyByFightBaccarat'
    }]
}];

export default routes;
<template>
  <div>
    <v-layout class="table-result">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
        <tr v-for="r in 6" :key="r">
          <td v-for="c in channel.column" :key="c" style="padding:0px; margin:0px">
            <div v-if="c > channel.column-channel.fix_column" class="td-table-style">
              <button :class="matchResult(r, c).color +' text-center'"
                      :disabled="!channel.is_open == null || !matchResult(r, c).fight_no "
                      @click="_select(r, calculateColumn(c), matchResult(r, c).ch_id,matchResult(r, c).random)"><div class="allow justify-center text-center fight-no-style">{{matchResult(r, c).fight_no}}</div></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </v-layout>
    <v-layout class="py-5" justify-center>
      <v-avatar
          class="mx-5"
          color="red"
          size="48"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['L'] }}
        </span>
      </v-avatar>

      <v-avatar
          class="mx-5"
          color="blue"
          size="48"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['S'] }}
        </span>
      </v-avatar>

      <v-avatar class="mx-5" color="grey" size="48">
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['CANCEL'] }}
        </span>
      </v-avatar>
    </v-layout>

    <!--  dialog result  -->
    <v-dialog v-model="dialogResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-5" v-if="objCoTypeOfBetting">
          <!-- <v-btn-toggle mandatory v-model="defineResult.result_code" v-for="v in this.defineResult.random" :key="v"> -->
            <div class="row">
              <div class="col-3" v-for="(v, index) in this.defineResult.random" :key="index">
                <v-btn class="py-10 px-5" @click="_selectNum(v,index)" :value="numberConformed">
                  <v-avatar
                    class="mx-5"
                    color="blue"
                    size="56"
                  >
                    <span class="white--text text-h5 font-weight-bold">
                      {{ v }}
                    </span>
                  </v-avatar>
                </v-btn>
              </div>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="cancelResult">Cancel Result</v-btn>
          <v-btn color="warning" @click="closeDialogResult">close</v-btn>
          <v-btn color="primary" @click="saveResult">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  dialog Input Number  -->
    <v-dialog v-model="dialogInputNumber" width="300" persistent>
      <v-sheet width="300" class="mx-auto">
        <v-form @submit.prevent>
          <v-text-field
            v-model="inputNumber"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            :rules="rule_number_enter"
            type="number"
            label="Number"
          ></v-text-field>
          <v-btn block class="mt-2" @click="confirmNumber">Submit</v-btn>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ['objCoResult', 'channel', 'objSummaryCoResult' , 'objCoTypeOfBetting'],
  data() {
    return {
      numberConformed:null,
      inputNumber:null,
      index: null,
      dialogResult: false,
      dialogInputNumber:false,
      defineResult: {
        random:[],
        chanel_id: null,
        resultTypeId: null
      },
      rule_number_enter: [(v) => {
        if (
          !isNaN(parseFloat(v)) &&
          v >= 1 &&
          v <= 80 &&
          !!v
        )
          return true;
          return "Number has to be smaller than 80"
      }],
    }
  },
  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting:function(){
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    }
  },
  mounted(){
    
  },
 
  methods: {
    _select(r, c, ch_id,random) {
      this.defineResult.rid = r
      this.defineResult.cid = c
      this.defineResult.chanel_id = ch_id
      this.defineResult.random = random
      this.dialogResult = true
    },

    _selectNum(v,index){
      this.inputNumber = v;
      this.index = index;
      this.dialogInputNumber = true
    },
    confirmNumber(){
      this.numberConformed = parseInt(this.inputNumber);
      if(this.defineResult.random.includes(this.numberConformed)){
        alert("This number already exist")
      }else{
        this.defineResult.random[this.index] = this.numberConformed;
        this.dialogInputNumber = false
      }
      
    },
    //Match result column and row
    matchResult(r, c) {
      let index = 1
      let flag, code, f_no, disabled, c_id,random;
      this.objCoResult.forEach(item => {
        if (item.rid === r && item.cid === c) {
          flag = true
          code =item.color
          f_no= item.fight_no
          random= item.random
          c_id = item.channel_id
          if (index > this.objCoResult.length - 15 ){
             disabled = true
          }
          return false;
        }
         index= index + 1
      })
      if (flag) {
        return {
          random: random,
          color: code,
          fight_no: f_no,
          disable: disabled,
          ch_id : c_id
        }
      } else {
        return {
          color: "no-circle",
          disable: false,
          fight_no: "",
          ch_id:''
        }
      }
    },
    saveResult() {
          // this.defineResult.resultTypeId = '635a3646a9b6a9cbc6206d7c';
          console.log("this.defineResult",this.defineResult);
          this.dialogResult = false;
          this.$emit("submitResult", this.defineResult);
    },
    cancelResult() {
      this.defineResult.resultTypeId = '635a3646a9b6a9cbc6206d7c';
      console.log("this.defineResult",this.defineResult);
      this.dialogResult = false;
      this.$emit("submitResult", this.defineResult);
    },
  
    closeDialogResult() {
      this.defineResult.rid = null
      this.defineResult.cid = null
      this.defineResult.result_code = null
      this.dialogResult = false
    },
    calculateColumn(c) {
      if (this.channel.column - this.channel.fix_column > 0) {
        return c + (this.channel.column - this.channel.fix_column)
      } else {
        return c;
      }
    }
  }
}
</script>

<style scoped>
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
.allow:hover {
  color: gold;
  font-weight: bolder;
    font-size: 29px;
    margin-top: -21px;

}
</style>

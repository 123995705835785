<template>
  <div>
    <div class="section-header" hidden>
      <!-- <h1>User Page</h1> -->
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="/">Dashboard</a>
        </div>
        <div class="breadcrumb-item">Agent Statement</div>
      </div>
    </div>
    <!-- <div class="section-body"> -->
      <div class="card">
        <div class="card-body">
          <div class="section-header-breadcrumb pl-5">
            <div class="breadcrumb-item">
              <b style="color: #1976d2;">Player Statement</b>
            </div>
          </div>
          <!-- <v-toolbar-title class=" text-h5 uppercase pl-5 ">Player Statement USD</v-toolbar-title> -->
          <div class="table-responsive" style="text-align: center">
            <v-data-table
              :headers="headers"
              :items="getStatement ? getStatement.object : []"
              :server-items-length="
                getCountStatement ? parseInt(getCountStatement.count) : null
              "
              item-key="_id._id"
              class="table table-striped"
              id="table-1"
              style="font-family: 'khmer mef1'"
              :footer-props="footerProps"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              hide-default-header
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              :loading="getLoading"
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :user="user"
                  :time="time"
                  :startTime="startTime"
                  :endTime="endTime"
                  :getAllAppMenu="getListAppMenu"
                  :statusTimeOrigin="statusTimeOrigin"
                  @child-checkbox="checkboxValue"
                />
                <!--button date filter-->
                <div class="row">
                  <div
                    class="col-12 col-md-12 col-sm-12"
                    style="width: 100%; text-align: right; margin-bottom: 15px"
                  >
                    <v-btn
                      style="display: inline-block"
                      v-for="(item, i) in defaultFilterDates"
                      small
                      color="primary"
                      class="mx-4 mb-4 btn btn-primary"
                      :key="i"
                      @click="selectDate(item)"
                    >
                      <span style="color: white">{{ item.name }}</span>
                    </v-btn>
                  </div>
                </div>
              </template>

              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td>
                    {{
                      getStatement.object.indexOf(item) +
                      (data.page - 1) * data.rowsPerPage +
                      1
                    }}
                  </td>
                  <td>{{ item._id.app_id }}</td>
                  <td>
                    {{
                      user.role_name == "DEVELOPER" ||
                      user.role_name == "MANAGER" ||
                      user.role_name == "CONTROLLER" ||
                      user.role_name == "COMPANY" ||
                      user.role_name == "SPECIAL-MANAGER"
                        ? item._id.com
                        : "---"
                    }}
                  </td>
                  <td>
                    {{
                      user.role_name == "DEVELOPER" ||
                      user.role_name == "MANAGER" ||
                      user.role_name == "CONTROLLER" ||
                      user.role_name == "COMPANY" ||
                      user.role_name == "SPECIAL-MANAGER" ||
                      user.role_name == "SENIOR-B" ||
                      user.role_name == "SENIOR-D" ||
                      user.role_name == "SENIOR-R"
                        ? item._id.admin
                        : "---"
                    }}
                  </td>
                  <td>
                    {{
                      user.role_name == "DEVELOPER" ||
                      user.role_name == "MANAGER" ||
                      user.role_name == "CONTROLLER" ||
                      user.role_name == "COMPANY" ||
                      user.role_name == "SPECIAL-MANAGER" ||
                      user.role_name == "SENIOR-B" ||
                      user.role_name == "SENIOR-D" ||
                      user.role_name == "SENIOR-R" ||
                      user.role_name == "MASTER-B" ||
                      user.role_name == "MASTER-D" ||
                      user.role_name == "MASTER-R"
                        ? item._id.master
                        : "---"
                    }}
                  </td>
                  <td>{{ item._id.agent }}</td>
                  <td>{{ item._id.player }}</td>
                  <td>{{ currencyFormat(item._id.player_balance) }}</td>
                  <td
                    class="amount-bet-color to-detail"
                    @click="_goToDetail(item._id.player_id)"
                  >
                    {{ currencyFormat(item.amount) }}
                  </td>
                  <!-- <td class="win-color">{{ currencyFormat(item.amount_win) }}</td> -->
                  <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">
                    {{ currencyFormat(item.amount_win) }}
                  </td>
                </tr>
              </template>
              <template slot="body.append">
                <tr style="background-color: #faffe5; font-weight: bold;">
                  <td :colspan="7" class="text-xs-center pl-4 py-2">
                    {{ $t("title.total") }}
                  </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                  {{
                    getCountStatement.balance
                      ? currencyFormat(getCountStatement.balance)
                      : 0
                  }}
                </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getCountStatement.amount
                        ? currencyFormat(getCountStatement.amount)
                        : 0
                    }}
                  </td>
                  <td
                    class="
                      text-xs-center
                      pl-4
                      total-style
                      font-weight-bold
                      py-2
                    "
                    :style="
                      getCountStatement.amount_win < 0
                        ? 'color:#BA2D4A'
                        : 'color:#39afd6'
                    "
                  >
                    {{
                      getCountStatement.amount_win
                        ? currencyFormat(getCountStatement.amount_win)
                        : 0
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-dialog
              :value="dialog"
              max-width="80%"
              persistent
              transition="dialog-bottom-transition"
              style="z-index: 9999 !important"
            >
              <v-card>
                <!-- <v-toolbar>
                  <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar> -->

                <div class="modal-header">
                  <h5 class="modal-title" id="formModal">
                    Detail Player Statement
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="$emit('onCloseDialog')"
                  >
                    <span @click="dialog = false" aria-hidden="true">×</span>
                  </button>
                </div>
                <v-card-text>
                  <br />
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <div class="input-group">
                        <!-- <div class="col-md-3 col-sm-12">
                          
                          <div class="form-group">
                            <select class="form-control form-control-sm" v-model="defaultSelected">
                              <option :value="null" disabled selected>Choose Channel</option>
                              <option
                                v-for="option in selectChannel"
                                v-bind:key="option.id"
                                v-bind:value="option.id"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                          </div>
                        </div> -->
                        <div class="col-md-12 col-sm-12">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="fas fa-search"></i>
                              </div>
                            </div>
                            <input
                              v-model="v_search"
                              type="text"
                              class="form-control mr-1"
                            />
                            <button class="btn btn-primary" @click="searchq">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-data-table
                    style="font-family: 'khmer mef1'"
                    class="table table-striped"
                    item-key="date"
                    :headers="headersDetail"
                    :footer-props="footerProps"
                    :items="detailData ? detailData : []"
                    :server-items-length="
                      detailDataCount ? parseInt(detailDataCount.count) : null
                    "
                    :sort-desc.sync="dataRequest.descendingFormat"
                    :sort-by.sync="dataRequest.sortByFormat"
                    :page.sync="dataRequest.page"
                    :items-per-page.sync="dataRequest.rowsPerPage"
                    @update:page="updatePage"
                    @update:items-per-page="updatePage"
                    @update:sort-desc="updatePage"
                    :loading="getLoadingDetail"
                    mobile-breakpoint="0"
                  >
                    <template v-slot:[`item`]="{ item }">
                      <tr>
                        <td>
                          {{
                            detailData
                              ? detailData.indexOf(item) +
                                (dataRequest.page - 1) *
                                  dataRequest.rowsPerPage +
                                1
                              : "loading ..."
                          }}
                        </td>
                        <td>{{ item.app_id }}</td>
                        <td>{{ item.public_ip }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.fight_no }}</td>
                        <td>
                          <span>{{switchChannel(item.channel_type)}}</span>
                        </td>
                        <td :class="item.type_of_betting.color">
                          <v-chip v-if="item.channel_type == 92" label :color=item.type_of_betting.color style="padding:15px;color:#ffffff; font-size:13px;height:22px">
                              <span class="white--text">{{item.type_of_betting.name}} : {{item.type_of_betting.number_label}}</span>
                          </v-chip>
                          <span v-else-if="item.channel_type == 11">
                            {{ item.type_of_betting.desc }}
                          </span>
                          <span v-else-if="item.channel_type == 5 || item.channel_type == 93 || item.channel_type == 931 || item.channel_type == 932 || item.channel_type == 933 || item.channel_type == 934 "> 
                            <v-img v-if="item.type_of_betting.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                          </span>
                          <span v-else-if="item.channel_type == 15">
                            {{changeType(15,item.type_of_betting.desc)}}
                          </span>
                          <span v-else-if="item.channel_type == 16" :class="item.type_of_betting.desc == 'WALA'?'cir-black':item.type_of_betting.color">
                            {{changeType(16,item.type_of_betting.desc)}}
                          </span>
                          <span v-else-if="item.channel_type == 89 || item.channel_type == 891 || item.channel_type == 892 || item.channel_type == 79 || item.channel_type == 91">
                            {{ item.type_of_betting.name }} ({{ item.type_of_betting.desc }})
                          </span>
                          <span v-else>
                            {{ item.type_of_betting.name }}
                          </span>
                        </td>
                        <td class="text-nowrap">
                          <span v-if="item.channel_type == 10">
                            {{ item.result1.name }}, {{ item.result2.name }}
                          </span>
                          <span
                            v-else-if="
                              item.channel_type == 6 || item.channel_type == 7
                            "
                          >
                            <span>{{ item.result1.name }}</span>
                            <span v-if="item.result3.name"
                              >, {{ item.result3.name }}
                            </span>
                          </span>
                          <span v-else-if="item.channel_type == 12">
                            {{ item.result1.name }}
                          </span>
                          <span v-else-if="item.channel_type == 5 || item.channel_type == 93 || item.channel_type == 931 || item.channel_type == 932 || item.channel_type == 933 || item.channel_type == 934">
                            <v-img v-if="item.result1.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                          </span>
                          <span v-else-if="item.channel_type == 90 || item.channel_type == 901 || item.channel_type == 902">
                            {{ item.result1.name }} | {{ item.result2.name }}
                          </span>
                          <span v-else-if="item.channel_type == 91 || item.channel_type == 911 || item.channel_type == 912">
                            {{ item.result1.name }} | {{ item.result2.name }} | {{ item.result3.name }} | {{ item.result4.name }} | {{ item.result5.name }} 
                          </span>
                          <span v-else-if="item.channel_type == 92 || item.channel_type == 921 || item.channel_type == 922">
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result1.color"
                            >
                            {{item.result1.name}}:{{item.result1.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result2.color"
                            >
                            {{item.result2.name}}:{{item.result2.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result3.color"
                            >
                            {{item.result3.name}}:{{item.result3.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result4.color"
                            >
                            {{item.result4.name}}:{{item.result4.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result5.color"
                            >
                            {{item.result5.name}}:{{item.result5.number_label}}
                            </v-btn>
                          </span>
                          <span v-else-if="item.channel_type == 20" >
                            <span :class="item.result1.color">{{ item.result1.name }} |</span>
                            <span :class="item.result2.color">{{ item.result2.name }} |</span>
                            <span :class="item.result3.color">{{ item.result3.name }} </span>
                          </span>
                          <span v-else-if="item.channel_type == 21">
                            <span :class="item.result1.color">{{ item.result1.name }} |</span>
                            <span :class="item.result2.color">{{ item.result2.name }} |</span>
                            <span :class="item.result3.color">{{ item.result3.name }} </span> 
                          </span>
                          <span v-else-if="item.channel_type == 94 || item.channel_type == 941 || item.channel_type == 942 || item.channel_type == 943 || item.channel_type == 944 || item.channel_type == 945 || item.channel_type == 946 || item.channel_type == 947 || item.channel_type == 948 || item.channel_type == 949" >
                            <span :class="item.result1.color">{{ item.result1.name }} </span>
                          </span>
                          <span v-else-if="item.channel_type == 95 || item.channel_type == 951 || item.channel_type == 952 || item.channel_type == 953 || item.channel_type == 954 || item.channel_type == 955 || item.channel_type == 956 || item.channel_type == 957 || item.channel_type == 958 || item.channel_type == 959">
                            <span :class="item.result1.color">{{ item.result1.name }}</span>
                            <span v-if="item.result2.name" :class="item.result2.color">, {{ item.result2.name }}
                            </span>
                          </span>
                          <span v-else-if="item.channel_type == 96 || item.channel_type == 961 || item.channel_type == 962 || item.channel_type == 963 || item.channel_type == 964">
                            <span :class="item.result1.color">{{ item.result1.name }}|</span>
                            <span :class="item.result2.color">{{ item.result2.name }}|</span>
                            <span :class="item.result3.color">{{ item.result3.name }} </span> 
                          </span>
                          <span v-else-if="item.channel_type == 97 || item.channel_type == 971 || item.channel_type == 972 || item.channel_type == 973 || item.channel_type == 974">
                            <span :class="item.result1.color">{{ item.result1.name }}|</span>
                            <span :class="item.result2.color">{{ item.result2.name }}|</span>
                            <span :class="item.result3.color">{{ item.result3.name }}</span>
                          </span>
                          <span v-else-if="item.channel_type == 98 || item.channel_type == 981 || item.channel_type == 982 || item.channel_type == 983 || item.channel_type == 984">
                            <span :class="item.result1.color">{{ item.result1.name }}|</span>
                            <span :class="item.result2.color">{{ item.result2.name }}</span>
                          </span>
                          <span v-else-if="item.channel_type == 99 || item.channel_type == 991 || item.channel_type == 992">
                            <span :class="item.result1.color">{{ item.result1.name }} | </span>
                            <span :class="item.result2.color">{{ item.result2.name }} | </span>
                            <span :class="item.result3.color">{{ item.result3.name }} | </span>
                            <span :class="item.result4.color">{{ item.result4.name }}</span>
                          </span>
                          <span v-else-if="item.channel_type == 89 || item.channel_type == 891 || item.channel_type == 892 || item.channel_type == 79 || item.channel_type == 791">
                            <span :class="item.result1.color">{{ item.result1.name }} {{ item.result1.desc }} <br/> </span>
                            <span :class="item.result2.color">{{ item.result2.name }} {{ item.result2.desc }} <br/></span>
                            <span :class="item.result2.color">{{ item.result3.name }} {{ item.result3.desc }}<br/> </span>
                            <span :class="item.result1.color">{{ item.result4.name }} {{ item.result4.desc }} <br/></span>
                            <span :class="item.result1.color">{{ item.result5.name }} {{ item.result5.desc }} </span>
                          </span>
                          <span v-else-if="item.channel_type == 15">
                            {{changeType(15,item.result1.name)}}
                          </span>
                          <span v-else-if="item.channel_type == 16">
                            {{changeType(16,item.result1.name)}}
                          </span>
                          <span v-else>
                            {{ item.result1.desc }}
                          </span>
                        </td>
                        <td class="amount-bet-color">
                          <!-- {{ dataRequest && dataRequest.currencyType == 2 ? currencyFormatKm(item.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 ?  currencyFormatEn(item.amount) :"Loading ..." }} -->
                          {{ currencyFormatEn(item.amount) }}
                        </td>
                        <td>{{ item.payout }}</td>
                        <td
                          :class="
                            item.amount_win < 0
                              ? 'lose-color font-weight-bold'
                              : 'win-color font-weight-bold'
                          "
                        >
                          {{
                            item.amount_win
                              ? currencyFormatEn(item.amount_win)
                              : 0
                          }}
                        </td>
                        <td>
                          <span v-if="Object.keys( item.result1 ).length > 0">
                            <span v-if="(item.result1.name == 'CANCEL' || item.result1.name == 'TIE') && (item.is_win == null || item.is_win == false)">{{ currencyFormatEn(item.amount) }}</span>
                            <span v-else>{{ currencyFormatEn(item.cast) }}</span>
                          </span>  
                          <span v-else>0</span>
                        </td>
                      </tr>
                    </template>
                    <template slot="body.append">
                      <tr style="background-color: #faffe5; font-weight: bold;">
                      <td :colspan="8" class="text-xs-center pl-4 py-2">
                        {{ $t("title.total") }}
                      </td>
                      <td
                        :class="
                          detailDataCount.amount < 0
                            ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                            : 'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                        "
                      >
                        {{
                          detailDataCount
                            ? currencyFormatEn(detailDataCount.amount)
                            : "loading ..."
                        }}
                      </td>
                      <td></td>
                      <td
                        :class="
                          detailDataCount.amount_win < 0
                            ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                            : 'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                        "
                      >
                        <!-- 123 -->
                        <!-- {{ dataRequest && dataRequest.currencyType == 2 && detailDataCount ? currencyFormatKm(detailDataCount.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 && detailDataCount ?  currencyFormatEn(detailDataCount.amount) :"Loading ..." }} -->
                        {{
                          detailDataCount
                            ? currencyFormatEn(detailDataCount.amount_win)
                            : "loading ..."
                        }}
                      </td>
                      <td></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import headersDetail from "./_api/columns_detail";
import moment from "moment";
export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    // defaultSelected: {
    //   id: 0,
    //   name: "All",
    // },
    defaultSelected: null,
    selectChannel: [
      {
        id: 0,
        name: "All",
      },
      {
        id: 1,
        name: "CO1",
      },
      {
        id: 2,
        name: "CO2",
      },
      {
        id: 3,
        name: "CO3",
      },
      {
        id: 4,
        name: "CO4",
      },
      {
        id: 15,
        name: "BA4",
      },
      {
        id: 16,
        name: "ROULLETE",
      },
      {
        id: 5,
        name: "KL1",
      },
      {
        id: 6,
        name: "BA1",
      },
      {
        id: 7,
        name: "BA2",
      },
      {
        id: 18,
        name: "BA3",
      },
      {
        id: 8,
        name: "TD1",
      },
      {
        id: 9,
        name: "TD2",
      },
      {
        id: 19,
        name: "TD3",
      },
      {
        id: 10,
        name: "LO1",
      },
      {
        id: 11,
        name: "YU1",
      },
      {
        id: 12,
        name: "AP1",
      },
      {
        id: 17,
        name: "AP2",
      },
      {
        id: 13,
        name: "FT1",
      },
      {
        id: 14,
        name: "TS1",
      },
      {
        id: 20,
        name: "HAN2",
      },
      {
        id: 21,
        name: "HAN3",
      },
      {
        id: 90,
        name: "LOTTO99(SLOT)",
      },
      {
        id: 91,
        name: "YUKI(SLOT)",
      },
      {
        id: 92,
        name: "PP78(SLOT)",
      },
      {
        id: 93,
        name: "KLAKLOUK(SLOT)",
      },
      {
        id: 94,
        name: "DRAGON(SLOT)",
      },
      {
        id: 95,
        name: "BACCARAT(SLOT)",
      },
      {
          id: 96,
          name: "DODEN(SLOT)",
        },
        {
          id: 97,
          name: "SICBO(SLOT)",
        },
        {
          id: 98,
          name: "TAIXIU(SLOT)",
        },
    ],
    v_search: null,
    valid: true,
    headers: headers,
    headersDetail: headersDetail,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "amount_win",
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "public_ip",
        "type_of_betting.name",
        "date",
        "fight_no",
        "is_win",
        "amount",
        "amount_win",
        "amount_lose",
      ],
      search: "",
      channelType: [],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["amount_win"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "_id.app_id",
        "_id.com",
        "_id.con_balance",
        "_id.admin_id",
        "_id.admin",
        "_id.admin_balance",
        "_id.master_id",
        "_id.master",
        "_id.master_balance",
        "_id.agent_id",
        "_id.agent",
        "_id.agent_balance",
        "_id.player_id",
        "_id.player",
        "_id.player_balance",
        "amount",
        "amount_win",
      ],
      search: "",
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      statusTime: false,
      channelType: ["1", "2", "3", "4","5", "6", "7", "8", "9", "10", "11", "12", "13", "14","15", "16", "17", "18", "19", "20","21", 
                 "90", "901", "902","91", "911", "912", "92", "921", "922","79","791",
                 "93", "931", "932", "933", "934",
                 "94","941", "942", "943", "944", "945", "946", "947", "948", "949",
                 "95","951", "952", "953", "954", "955", "956", "957", "958", "959",
                 "96", "961", "962", "963", "964","97","971", "972", "973", "974",
                 "98","981", "982", "983", "984", "99", "991", "992", "89", "891", "892"]
    },
    appId : 0,
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    detailData: [],
    detailDataCount: [],
    check: {
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      startTime: "00:00",
      endTime: "23:59",
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        nameKh: "ថ្ងៃនេះ",
        active: false,
      },
      {
        name: "Yesterday",
        nameKh: "ម្សិលមិញ",
        active: false,
      },
      {
        name: "This Week",
        nameKh: "សប្តាហ៍នេះ",
        active: false,
      },
      {
        name: "Last Week",
        nameKh: "សប្តាហ៍មុន",
        active: false,
      },
      {
        name: "This Month",
        nameKh: "ខែនេះ",
        active: false,
      },
      {
        name: "Last Month",
        nameKh: "ខែមុន",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
    getLoadingDetail: false,
    user_iid: null,
    channel_type: null,
    startTime: "00:00",
    endTime: "23:59",
    statusTimeOrigin: false,
  }),
  watch: {
    user: function () {
      this.getData();
    },
    data: function () {
      this.getData();
    },
    getStatement: function () {},
  },
  computed: {
    reportUrl: function () {
      return "player";
    },
    defaultCurrency: function() {
      if (this.user) {
        if(this.user.role_name == "SENIOR-D" || this.user.role_name == "SUB-SENIOR-D" || 
          this.user.role_name == "MASTER-D" || this.user.role_name == "SUB-MANAGER-D" || 
          this.user.role_name == "AGENT-D" || this.user.role_name == "SUB-AGENT-D"){
            return 1;
        }else if(this.user.role_name == "SENIOR-R" || this.user.role_name == "SUB-SENIOR-R" || 
           this.user.role_name == "MASTER-R" || this.user.role_name == "SUB-MANAGER-R" || 
           this.user.role_name == "AGENT-R" || this.user.role_name == "SUB-AGENT-R"){
            return 2;
        }else if(this.user.role_name == "SENIOR-VN" || this.user.role_name == "SUB-SENIOR-VN" || 
           this.user.role_name == "MASTER-VN" || this.user.role_name == "SUB-MANAGER-VN" || 
           this.user.role_name == "AGENT-VN" || this.user.role_name == "SUB-AGENT-VN"){
            return 4;
        }else if(this.user.role_name == "SENIOR-RP" || this.user.role_name == "SUB-SENIOR-RP" || 
           this.user.role_name == "MASTER-RP" || this.user.role_name == "SUB-MANAGER-RP" || 
           this.user.role_name == "AGENT-RP" || this.user.role_name == "SUB-AGENT-RP"){
            return 5;
        }
      }
      return 1;
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_statementPlayerReports", [
      "getStatement",
      "getCountStatement",
      "getLoading",
      "getListAppMenu"
    ]),
  },
  async created() {
    await this.getData();
    await this.fetchListAppMenu();
  },
  methods: {
    moment,
    _goToDetail(_id) {
      this.user_iid = _id;
      this.loadDetailData(_id);
      this.dialog = true;
    },
    searchq() {
      if (this.defaultSelected && this.defaultSelected._id)
        this.channel_type = this.defaultSelected._id;
      else this.channel_type = this.defaultSelected;
      this.dataRequest.search = this.v_search;
      this.loadDetailData(this.user_iid);
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.user_iid);
      this.getLoadingDetail = false;
    },
    changeType(channelType,type){
      let bet_type = '';
      if(channelType == 15){
        if(type == 'WALA'){
          bet_type = 'PLAYER'
        }else if(type == 'MERON'){
          bet_type = 'BANKER'
        }else{
          bet_type = type;
        }
      }else if(channelType == 16){
        if(type == 'WALA'){
          bet_type = 'BLACK'
        }else if(type == 'MERON'){
          bet_type = 'RED'
        }else{
          bet_type = type;
        }
      }
      return bet_type;
    },
    async loadDetailData(_id) {
      
      (this.detailData = []),
        (this.detailDataCount = []),
        (this.getLoadingDetail = true);
      let allChannels = [];
      for(let i in this.data.channelType){
        allChannels.push(parseInt(this.data.channelType[i]));
      }
      console.log(allChannels);
      this.dataRequest.channelType = allChannels;
      this.dataRequest.startDate = this.data.startDate;
      this.dataRequest.endDate = this.data.endDate;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
    
      await this.$request
        .post({
          url: "statementCurrencyNew/playerDetail/" + _id,
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            console.log("res.data.data.object", res.data.data.object);
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetail = false;
          } else {
            this.getLoadingDetail = false;
          }
        });
    },
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment().subtract(1, "days").format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment().startOf("isoweek").format(format);
          this.endDate = this.moment().endOf("isoWeek").format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate, startTime, endTime, statusTimeOrigin } = this;
      this.time = {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusTimeOrigin: statusTimeOrigin,
      };
      this.check = { startDate, endDate, startTime, endTime };
      this.data.startDate = startDate + "T00:00:00";
      this.data.endDate = endDate + "T23:59:59";
      this.data.statusTime = this.statusTime ? true : false;
      //this.getData();
    },
    // Gets the checkbox information from the child component
    checkboxValue: function (params) {
      this.check = params;
      this.appId = params.appId;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
      this.data.statusTime = params.statusTime;
      this.data.search = params.search;
      this.data.channelType = params.channelType;
      this.currency_type = params.currency_type;
    },
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    async getData() {
      try {
        this.loader = true;
        let preData = {
          appId:this.appId,
          data: this.data,
          reportUrl: this.reportUrl,
        };
        this.$cookies.set("app-id", this.appId);
        this.$cookies.set("currency-type", this.defaultCurrency);
        await this.fetchListStatement(preData);
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_statementPlayerReports", [
      "LIST_STATEMENT",
      "COUNT-STATEMENT",
    ]),
    ...mapActions("$_statementPlayerReports", ["fetchListStatement","fetchListAppMenu"]),
    switchChannel(channel_type){
      switch(parseInt(channel_type)){
        case 1: 
          return 'C01';
        case 2: 
          return 'C02';
        case 3: 
          return 'C03';
        case 4: 
          return 'C04';
        case 15: 
          return 'C05';
        case 16: 
          return 'C06';
        case 5: 
          return 'KL1';
        case 6: 
          return 'BA1';
        case 7: 
          return 'BA2';
        case 18: 
          return 'BA3';
        case 8: 
          return 'TD1';
        case 9: 
          return 'TD2';
        case 19: 
          return 'TD3';
        case 10: 
          return 'LO1';
        case 11: 
          return 'YU1';
        case 12: 
          return 'AP1';
        case 17: 
          return 'AP2';
        case 13: 
          return 'FT1';
        case 14: 
          return 'TS1';
        case 20: 
          return 'HAN2';
        case 21: 
          return 'HAN3';
        case 90: 
          return 'LOTTO1(SLOT)';
        case 901: 
          return 'LOTTO2(SLOT)';
        case 902: 
          return 'LOTTO3(SLOT)';
        case 91: 
          return 'YUKI1(SLOT)';
        case 911: 
          return 'YUKI2(SLOT)';
        case 912: 
          return 'YUKI3(SLOT)';
       
        case 92: 
          return 'LOTTERY1(SLOT)';
        case 921: 
          return 'LOTTERY2(SLOT)';
        case 922: 
          return 'LOTTERY3(SLOT)';
        case 93: 
          return 'KL1(SLOT)';
        case 931: 
          return 'KL2(SLOT)';
        case 932: 
          return 'KL3(SLOT)';
        case 933: 
          return 'KL4(SLOT)';
        case 934: 
          return 'KL5(SLOT)';
        case 94: 
          return 'TD1(SLOT)';
        case 941: 
          return 'TD2(SLOT)';
        case 942: 
          return 'TD3(SLOT)';
        case 943: 
          return 'TD4(SLOT)';
        case 944: 
          return 'TD5(SLOT)';
        case 945: 
          return 'TD6(SLOT)';
        case 946: 
          return 'TD7(SLOT)';
        case 947: 
          return 'TD8(SLOT)';
        case 948: 
          return 'TD9(SLOT)';
        case 949: 
          return 'TD10(SLOT)';
        case 95: 
          return 'BA1(SLOT)';
        case 951: 
          return 'BA2(SLOT)';
        case 952: 
          return 'BA3(SLOT)';
        case 953: 
          return 'BA4(SLOT)';
        case 954: 
          return 'BA5(SLOT)';
        case 955: 
          return 'BA6(SLOT)';
        case 956: 
          return 'BA7(SLOT)';
        case 957: 
          return 'BA8(SLOT)';
        case 958: 
          return 'BA9(SLOT)';
        case 959: 
          return 'BA10(SLOT)';
        case 96: 
          return 'DODEN1(SLOT)';
        case 961: 
          return 'DODEN2(SLOT)';
        case 962: 
          return 'DODEN3(SLOT)';
        case 963: 
          return 'DODEN4(SLOT)';
        case 964: 
          return 'DODEN5(SLOT)';
        case 97: 
          return 'SICBO1(SLOT)';
        case 971: 
          return 'SICBO2(SLOT)';
        case 972: 
          return 'SICBO3(SLOT)';
        case 973: 
          return 'SICBO4(SLOT)';
        case 974: 
          return 'SICBO5(SLOT)';
        case 98: 
          return 'TAIXIU1(SLOT)';
        case 981: 
          return 'TAIXIU2(SLOT)';
        case 982: 
          return 'TAIXIU3(SLOT)';
        case 983: 
          return 'TAIXIU4(SLOT)';
        case 984: 
          return 'TAIXIU5(SLOT)';
        case 99: 
          return 'KENO1(SLOT)';
        case 991: 
          return 'KENO2(SLOT)';
        case 992: 
          return 'KENO3(SLOT)';
        case 89: 
          return 'LOTTERY3D1(SLOT)';
        case 891: 
          return 'LOTTERY3D2(SLOT)';
        case 892: 
          return 'LOTTERY3D3(SLOT)';
        case 79: 
          return 'LOTTERYVN(SLOT)';
        case 791: 
          return 'LOTTERYVN2(SLOT)';
        default:  
          return '--'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: "khmer mef1" !important;
}
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.to-detail {
  cursor: pointer;
  text-decoration: underline;
}
.MERON {
  color: #ba2d4a;
  font-weight: bold;
}
.WALA {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.cir-red {
  color: #ba2d4a;
  font-weight: bold;
}
.cir-blue {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.cir-green {
  color: green;
}
.cir-cancel {
  color: grey;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

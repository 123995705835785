<template>
  <div>
    <v-layout class="table-result" style="width:100%!important">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          <tr v-if="orderResult.length > 0">
            <td class="selectResult" v-for="(result, i) in orderResult" :key="i" style="height:52px !important; width:52px !important" @click="selectResult(result)">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span v-if="result.fight_no" height="50px"> {{result.fight_no }} </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                  <v-img v-if="result.result1_name" height="58px"  class="pair-card" :src="'images/taisiev/' + switchResult(result.result1_name)"> </v-img>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                  <v-img v-if="result.result2_name" height="58px"  class="pair-card" :src="'images/taisiev/' + switchResult(result.result2_name)"> </v-img>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                  <v-img v-if="result.result3_name" height="58px"  class="pair-card" :src="'images/taisiev/' + switchResult(result.result3_name)"> </v-img>
              </div>  
            </td>                                 
        </tr>
        <tr v-else>
          <td  style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  &nbsp;
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>  
            </td>  
        </tr>
        </tbody>
      </table>
    </v-layout>
    <v-layout class="py-5" justify-center>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['Number1'] ?objSummaryCoResult['Number1']: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['Number2'] ?objSummaryCoResult['Number2']: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['Number3'] ?objSummaryCoResult['Number3']: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['Number4'] ?objSummaryCoResult['Number4']: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['Number5'] ?objSummaryCoResult['Number5']: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['Number6'] ?objSummaryCoResult['Number6']: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 35px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['CANCEL'] }}</span>
        </div> 
      </v-card>
      
    </v-layout>
    <!--  dialog result  -->
    <v-dialog v-model="dialogSelectResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-row>
            <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-btn-toggle>
                  <div  @click="selectResult1">
                    <v-btn class="py-10 px-5 btn-height" v-if="type_betting_id1 == '655c176a5b3ef131f63e3237'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id1 == '655c17885b3ef131f63e3238'">
                      <v-img  height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id1 == '655c17955b3ef131f63e3239'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id1 == '655c17b35b3ef131f63e323b'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id1 == '655c17c35b3ef131f63e323c'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id1 == '655c17d25b3ef131f63e323d'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id1 == '635a3646a9b6a9cbc6206d7c'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else></v-btn>
                  </div>
                </v-btn-toggle>
              </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row>
            <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-btn-toggle>
                  <div  @click="selectResult2">
                    <v-btn class="py-10 px-5 btn-height" v-if="type_betting_id2 == '655c176a5b3ef131f63e3237'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id2 == '655c17885b3ef131f63e3238'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id2 == '655c17955b3ef131f63e3239'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id2 == '655c17b35b3ef131f63e323b'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id2 == '655c17c35b3ef131f63e323c'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id2 == '655c17d25b3ef131f63e323d'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id2 == '635a3646a9b6a9cbc6206d7c'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else></v-btn>
                  </div>
                </v-btn-toggle>
              </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row>
            <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-btn-toggle>
                  <div  @click="selectResult3">
                    <v-btn class="py-10 px-5 btn-height" v-if="type_betting_id3 == '655c176a5b3ef131f63e3237'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id3 == '655c17885b3ef131f63e3238'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id3 == '655c17955b3ef131f63e3239'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id3 == '655c17b35b3ef131f63e323b'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id3 == '655c17c35b3ef131f63e323c'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id3 == '655c17d25b3ef131f63e323d'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else-if="type_betting_id3 == '635a3646a9b6a9cbc6206d7c'">
                      <v-img height="100px" width="100px"  class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>    
                    </v-btn>
                    <v-btn class="py-10 px-5 btn-height" v-else></v-btn>
                  </div>
                </v-btn-toggle>
              </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn>
          <v-btn color="primary" @click="saveResult" :disabled="loadingResult">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  dialog result1  -->
    <v-dialog v-model="dialogSelectResult1" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="type_betting_id1">
            <v-btn class="py-10 px-5" :value="'655c176a5b3ef131f63e3237'">
              <v-img height="55px" width="55px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>    
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17885b3ef131f63e3238'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17955b3ef131f63e3239'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17b35b3ef131f63e323b'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17c35b3ef131f63e323c'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17d25b3ef131f63e323d'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'635a3646a9b6a9cbc6206d7c'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn> -->
          <v-btn color="primary" @click="confirm1">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  dialog result2  -->
    <v-dialog v-model="dialogSelectResult2" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="type_betting_id2">
            <v-btn class="py-10 px-5" :value="'655c176a5b3ef131f63e3237'">
              <v-img height="55px" width="55px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>    
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17885b3ef131f63e3238'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17955b3ef131f63e3239'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17b35b3ef131f63e323b'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17c35b3ef131f63e323c'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17d25b3ef131f63e323d'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'635a3646a9b6a9cbc6206d7c'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn> -->
          <v-btn color="primary" @click="confirm2">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  dialog result3  -->
    <v-dialog v-model="dialogSelectResult3" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="type_betting_id3">
            <v-btn class="py-10 px-5" :value="'655c176a5b3ef131f63e3237'">
              <v-img height="55px" width="55px"  class="pair-card" :src="'images/taisiev/0001.png'"> </v-img>    
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17885b3ef131f63e3238'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0002.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17955b3ef131f63e3239'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0003.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17b35b3ef131f63e323b'">
              <v-img height="55px" width="55px" class="pair-card" :src="'images/taisiev/0004.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17c35b3ef131f63e323c'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0005.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'655c17d25b3ef131f63e323d'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0006.png'"> </v-img>
            </v-btn>
            <v-btn class="py-10 px-5" :value="'635a3646a9b6a9cbc6206d7c'">
              <v-img  height="55px" width="55px" class="pair-card" :src="'images/taisiev/0000.png'"> </v-img>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn> -->
          <v-btn color="primary" @click="confirm3">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import BtnResult from "../../../../components/shared/button/btn-result";
import { mapGetters, mapMutations } from "vuex";
export default {
  // components: {BtnResult},
  props: ['objCoResult', 'channel', 'objSummaryCoResult' , 'objCoTypeOfBetting'],
  data() {
    return {
      dialogSelectResult: false,
      dialogSelectResult1: false,
      dialogSelectResult2: false,
      dialogSelectResult3: false,
      dialogConfirm:false,
      result_id:null,
      type_betting_id1:null,
      type_betting_id2:null,
      type_betting_id3:null,
      defineResult: {
        result_id: null,
        type_betting_id1:null,
        type_betting_id2:null,
        type_betting_id3:null,
      },
    }
  },
  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting:function(){
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    },
    loadingResult: function(newV){
      if(newV == false) this.cancelSelectResult();
    }
  },
  mounted(){
    
  },
  computed: {
    orderResult() {
      return this.getTaixiuResults();
    },

...mapGetters("$_reverseResultTaixiu", {
  loadingResult: "getLoadingResult",
}),
},
 
  methods: {
    confirm1(){
      this.dialogSelectResult1 = false;
    },
    confirm2(){
      this.dialogSelectResult2 = false;
    },
    confirm3(){
      this.dialogSelectResult3 = false;
    },
    calculateColumn(c) {
      if (this.channel.column - this.channel.fix_column > 0) {
        return c + (this.channel.column - this.channel.fix_column)
      } else {
        return c;
      }
    },
    selectResult(result){
      this.result_id = result._id;
      this.dialogSelectResult = true;
    },
    selectResult1(){
      this.dialogSelectResult1 = true;
    },
    selectResult2(){
      this.dialogSelectResult2 = true;
    },
    selectResult3(){
      this.dialogSelectResult3 = true;
    },
    saveResult(){
      if(this.type_betting_id1 && this.type_betting_id2 && this.type_betting_id3){
        this.defineResult.result_id = this.result_id;
        this.defineResult.type_betting_id1 = this.type_betting_id1;
        this.defineResult.type_betting_id2 = this.type_betting_id2;
        this.defineResult.type_betting_id3 = this.type_betting_id3;
        this.$emit("submitResult", this.defineResult);
        if(this.loadingResult == false) {
            
            this.dialogSelectResult = false;
            this.type_betting_id1 = null;
            this.type_betting_id2 = null;
            this.type_betting_id3 = null;
            this.defineResult= {
              result_id: null,
              type_betting_id1: null,
              type_betting_id2: null,
              type_betting_id3: null,
            };
          }
      }else{
        this.$toastr.e('Please select result !');
      }
    },
    cancelSelectResult(){
      this.dialogSelectResult = false;
    },
    getTaixiuResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.objCoResult !== null){
          let resultList = this.objCoResult;
         
          for (var index = resultList.length - 1; index >= 0; index--) {
            const item = resultList[index];
            if(row <= this.rows){
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }else{
              row = 1
              col += 1
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }
          }
        }
        return newResult;
    },
    switchResult(name) {
      let data;
      switch (name) {
        case '1':
          data = "0001.png";
          return data;
        case '2':
          data = "0002.png";
          return data;
        case '3':
          data ="0003.png";
          return data;
        case '4':
          data = "0004.png";
          return data;
        case '5':
          data = "0005.png";
          return data;
        case '6':
          data = "0006.png";
          return data;
          case 'CANCEL':
          data = "0000.png";
          return data;
      }
    },
    ...mapMutations("$_reverseResultTaixiu", ["LOADING", "LOADINGRESULT"]),
  }
}
</script>

<style scoped>
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
.allow:hover {
  color: gold;
  font-weight: bolder;
    font-size: 29px;
    margin-top: -21px;

}
.selectp1:hover {
  cursor: pointer;
}
.selectp2:hover {
  cursor: pointer;
}
.selectp3:hover {
  cursor: pointer;
}
.cir-shadow{
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.btn-height{
  height: 100px!important;
  width: 100px!important;
}
.selectResult:hover {
  cursor: pointer;
}
</style>

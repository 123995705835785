const getKlakloukSettingData = (state) => state.klakloukSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentKlakloukSettingData = (state) => state.currentKlakloukSettingData;


export default {
    getKlakloukSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentKlakloukSettingData,
};
const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_CURRENT_BANK_STATEMENT = (state, data) => {
   state.currentBankStatement = data
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentBankStatement = {
            "_id": null,
            "type": null,
            "sender_note": "",
            "sender_username": "",
            "reciever_username": "",
            "reciever_note": null,
            "currency_type": "",
            "bank_sender": null,
            "bank_reciever": null,
            "status": "",
            "is_reciever_read": null,
            "is_sender_read": null,
            "amount": 0,
            "promotion_percentage": 0,
            "send_date": null,
            "response_date": null
        }
    }
    state.popUp = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};
const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};
const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_BANK_STATEMENT,
    DELETE_ITEMS
};
import withdrawPending from '@/modules/withdrawPending';
import Layout from '@/layouts/default';

const routes = [{
    path: '/withdrawPending',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: withdrawPending,
        name: 'withdrawPending'
    }]
}];

export default routes;
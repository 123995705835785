import retryCock from '@/modules/retryCock';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/retryCock',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: retryCock,
				name: 'retryCock'
			}
		]
	}
];

export default routes;

<template>
  <div>
    <v-layout class="table-result">
      <v-simple-table
        class="history-result result-table"
        width="100%"
        border="1"
        cellpadding="0"
        cellspacing="0"
      >
        <tbody style="background-color: #000; border-radius: 5px;">
        <tr v-for="row in 6" :key="row" class="center-padding-0" >
          <td v-for="col in 20" :key="col" class="center-padding-0">
              <div v-for="(result, i) in orderResult" :key="i" class="center-padding-0">
                <span v-if="col == result.col && row == result.row" @mouseover="is_hover = !is_hover" @mouseout="is_hover = !is_hover"  class="mx-0 center-padding-0">
                  <v-avatar :class="result.color" id="pointer" size="45" class="mx-0 center-padding-0" @click="_select(result._id)">
                    <span v-if="is_hover" class="white--text text-h6">
                      {{result.name}}
                    </span>
                    <span v-else class="red--text text-h6">
                      {{result.fight_no}}
                    </span>
                  </v-avatar>
                </span>
              </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-layout>
    <v-layout class="py-5" justify-center>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0001.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult.one ?objSummaryCoResult.one: 0 }}</span>
        </div> 
      </v-card>

      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0002.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult.two ?objSummaryCoResult.two: 0 }}</span>
        </div> 
      </v-card>
      
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0003.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult.three ?objSummaryCoResult.three: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0004.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult.four ?objSummaryCoResult.four: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0005.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult.five ?objSummaryCoResult.five: 0 }}</span>
        </div> 
      </v-card>
      <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0006.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 56px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult.six ?objSummaryCoResult.six: 0 }}</span>
        </div> 
      </v-card>
      <!-- <v-card
        class="mx-auto"
        max-width="56"
      >
        <v-avatar
          color="grey"
          size="56"
        >
        <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0000.png'"> </v-img>
        </v-avatar>
        <div style="border:1px #696767; width: 56px; height: 35px; text-align: center;">
          <span style="font-weight: bold; color: #000000; font-size: 25px;  text-align: center;vertical-align: middle;line-height: 60px; ">{{ objSummaryCoResult['CANCEL'] }}</span>
        </div> 
      </v-card> -->
    </v-layout>

    <!--  dialog result  -->
    <v-dialog v-model="dialogResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <!-- <v-card-text class="text-center py-5" v-if="objCoTypeOfBetting"> -->
        <v-card-text class="text-center py-5">
          <v-btn-toggle mandatory v-model="defineResult.result_code">
            <div class="row">
              <div class="col-2">
                <v-btn class="py-10 px-5" :value="1">
                  <v-img height="56px" width="56px" :src="'images/abong/0001.png'"> </v-img>    
                </v-btn>
              </div>
              <div class="col-2">
                <v-btn class="py-10 px-5" :value="2">
                  <v-img height="56px" width="56px" :src="'images/abong/0002.png'"> </v-img>    
                </v-btn>
              </div>
              <div class="col-2">
                <v-btn class="py-10 px-5" :value="3">
                  <v-img height="56px" width="56px" :src="'images/abong/0003.png'"> </v-img>    
                </v-btn> 
              </div>
              <div class="col-2">
                <v-btn class="py-10 px-5" :value="4">
                  <v-img height="56px" width="56px" :src="'images/abong/0004.png'"> </v-img>    
                </v-btn>  
              </div>
              <div class="col-2">
                <v-btn class="py-10 px-5" :value="5">
                  <v-img height="56px" width="56px" :src="'images/abong/0005.png'"> </v-img>    
                </v-btn>  
              </div>
              <div class="col-2">
                <v-btn class="py-10 px-5" :value="6">
                  <v-img height="56px" width="56px" :src="'images/abong/0006.png'"> </v-img>    
                </v-btn>  
              </div>
              <!-- <div class="col-2">
                <v-btn class="py-10 px-5" :value="13">
                  <v-img height="56px" width="56px" :src="'images/abong/0000.png'"> </v-img>    
                </v-btn>  
              </div> -->
            </div>
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirm=true">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogConfirm"
        persistent
        max-width="580"
        max-height="580"
      >
        <v-card class="text-center">
          <v-card-title class="text-h6 justify-center my-5">
            <div class="w-100" style="width:100%">
              Do you want to submit 
              <span v-if="defineResult.result_code ==1" class="wala-text bold">One</span> 
              <span v-if="defineResult.result_code ==2" class="meron-text bold">Two</span> 
              <span v-if="defineResult.result_code ==3" class="tie-text bold">Three</span> 
              <span v-if="defineResult.result_code ==4" class="tie-text bold">Four</span> 
              <span v-if="defineResult.result_code ==5" class="tie-text bold">Five</span> 
              <span v-if="defineResult.result_code ==6" class="tie-text bold">Six</span> 
              <!-- <span v-if="defineResult.result_code ==13" class="cancel-text bold">Cancel</span>  -->
            <br>
            </div>
            <div>
            <v-card v-if="defineResult.result_code ==1"
              class="mx-auto"
              max-width="100"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0001.png'"> </v-img>
              </v-avatar>
            </v-card>

            <v-card v-if="defineResult.result_code ==2"
              class="mx-auto"
              max-width="56"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0002.png'"> </v-img>
              </v-avatar> 
            </v-card>
            
            <v-card v-if="defineResult.result_code ==3"
              class="mx-auto"
              max-width="56"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0003.png'"> </v-img>
              </v-avatar> 
            </v-card>
            <v-card v-if="defineResult.result_code ==4"
              class="mx-auto"
              max-width="56"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0004.png'"> </v-img>
              </v-avatar> 
            </v-card>
            <v-card v-if="defineResult.result_code ==5"
              class="mx-auto"
              max-width="56"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0005.png'"> </v-img>
              </v-avatar> 
            </v-card>
            <v-card v-if="defineResult.result_code ==6"
              class="mx-auto"
              max-width="56"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0006.png'"> </v-img>
              </v-avatar>
            </v-card>
            <!-- <v-card v-if="defineResult.result_code ==13"
              class="mx-auto"
              max-width="56"
            >
              <v-avatar
                color="grey"
                size="56"
              >
              <v-img height="56px" width="56px"  class="pair-card" :src="'images/abong/0000.png'"> </v-img>
              </v-avatar>
            </v-card> -->
          </div>
            <!-- <div :class=" defineResult.result_code ==1 ? ' confirm-circle wala' :defineResult.result_code==0?' confirm-circle meron':defineResult.result_code==2?' confirm-circle tie':defineResult.result_code==3?' confirm-circle cancel':''">
           </div> -->

          </v-card-title>
          <v-card-actions>

            <v-btn
              color="grey darken-1"
              class="cancel-btn"
              @click="dialogConfirm = false"
            >
              No, Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="agree-btn"
              @click="saveResult"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
// import BtnResult from "../../../../components/shared/button/btn-result";

export default {
  // components: {BtnResult},
  props: [ 'channel', 'objSummaryCoResult','objSnResult',],
  data() {
    return {
      dialogResult: false,
      dialogConfirm:false,
      defineResult: {
        result_id: null,
        resultTypeId: null
      },
      is_hover:true,
    }
  },
  watch: {
    objSnResult: function () {
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    }
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
  },
  mounted(){
    
  },
 
  methods: {
    _select(_id) {
      this.defineResult.result_id = _id
      this.dialogResult = true
    },
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if(this.objSnResult !== null){
      for (var index = this.objSnResult.length - 1; index >= 0; index--) {
        const item = this.objSnResult[index];
        if(index == this.objSnResult.length - 1){
          newResult.push({
            ...item,
            row,
            col,
          });
        }else{
          const pre = newResult[newResult.length - 1];
          if(pre.name !== item.name){
            newResult.push({ 
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          }else{
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      }
      return newResult;
    },
    saveResult() {
         // this.defineResult.resultTypeId = this.objCoTypeOfBetting[this.defineResult.result_code]._id
         if(this.defineResult.result_code == 1){
            this.defineResult.resultTypeId = '6134272c2940766856edf2c3';
          }else if(this.defineResult.result_code == 2){
            this.defineResult.resultTypeId = '6134273b2940766856edf2c4';
          }else if(this.defineResult.result_code == 3){
            this.defineResult.resultTypeId = '613428392940766856edf2c9';
          }else if(this.defineResult.result_code == 4){
            this.defineResult.resultTypeId = '6134283f2940766856edf2ca';
          }else if(this.defineResult.result_code == 5){
            this.defineResult.resultTypeId = '613428452940766856edf2cb';
          }else if(this.defineResult.result_code == 6){
            this.defineResult.resultTypeId = '613428492940766856edf2cc';
          }
          // else{
          //   this.defineResult.resultTypeId = '635a3646a9b6a9cbc6206d7c';
          // }
          console.log("this.defineResult",this.defineResult);
          this.dialogResult = false
          this.dialogConfirm = false
          this.$emit("submitResult", this.defineResult);
    },
  
    cancelResult() {
      this.defineResult.rid = null
      this.defineResult.cid = null
      this.defineResult.result_code = null
      this.dialogResult = false
    },
    calculateColumn(c) {
      if (this.channel.column - this.channel.fix_column > 0) {
        return c + (this.channel.column - this.channel.fix_column)
      } else {
        return c;
      }
    }
  }
}
</script>

<style scoped>
.history-result td {
  height: 30px;
  width: 30px;
  background: white;
  border: 1px solid #c9c7c1;
  vertical-align: middle;
  box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
  -moz-box-shadow: 0 0 2px 1px #c9c7c1;
  -webkit-box-shadow: 0 0 2px 1px #c9c7c1;
  -ms-box-shadow: 0 0 2px 1px #c9c7c1;
  -o-box-shadow: 0 0 2px 1px #c9c7c1;
}
.result-block {
  position: relative;
}
.result-block .show-fightNo {
  visibility: hidden;
}

.result-block:hover .show-fightNo {
  visibility: visible;
  width: 55px;
  height: 55px;
  color: black;
  position: absolute;
  top: -28px;
  left: -27px;
  font-size: 17px;
  /* font-style: italic; */
  font-weight: bold;

}
.r-cir-black {
  background-color: #28a745;
}
.r-cir-red{
  background-color: #28a745;
}
.result-table{
  max-width:100%; max-height:100%;min-width:100%; min-height:100%; margin:0; margin-top:20px
}
.center-padding-0 {
  text-align:center;
  padding: 0px!important;
}
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.cir-black {
  background-color: #0f0f0f !important;
}

.cir-blue {
  border: 25px solid #0b4592;
  /* box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px; */
}
.cir-gold {
  border: 25px solid #FFD700;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  background-color: #b31013 !important;
  /* border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px; */
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
.allow:hover {
  color: gold;
  font-weight: bolder;
    font-size: 29px;
    margin-top: -21px;

}
.r-cir-blue {
  background-color: #0b4592;
}

.r-cir-red {
  background-color: #b31013;
}

.r-cir-green {
  background-color: #28a745;
}

.r-cir-cancel {
  background-color: grey;
}
#pointer {cursor: pointer;}
</style>

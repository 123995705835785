import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    sicboSettingData: {
        _id: null,
        name: null,
        is_enable: null,
        is_start: null,
        user_id: null,
        percentage: 0,
        channel_type: 0,
        win_lose_type: "1",
        updatedAt: null
    },
    currentSicboSettingData:{},
    loading: false,
    popUp: false,
    popUpPercent: false,
    deleteItems: [],
    userConfigData:[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    users: {
        object: [],
        objectCount: null
    },
    popUp: false,
    popUpBankUser: false,
    currentUser: {
        user_name: null,
        password: 123456,
        account_info: null,
        enable:true,
        role_id: null,
        channel_post:null,
        selectedChannel:[1,2,3,4],
        app_id: null,
        full_permission: false,
        currencies: []
    },
    deleteItems: [],
    loading: true,
    viewTransaction: {},
    depositTransaction: {},
    listCompany:[],
    listSenior:[],
    listApp:[],
    listMenuByApp:[],
    listTreeMenuByApp:[],
    treeMenuSelection: [],
    currentBankUser: {
		user_id: null,
		bank_id: null,
		account_name: null,
		account_number: null,
		account_info: null,
		is_active: true,
		is_enable: true,
		currency_type: null,
		order: null,
	},
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchGetAllYukiSetting = async ({ commit }, data) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'yuki-setting/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_YUKI_SETTIN_DATA', response.data.data);
        commit('LOADING', false);
    }
};

const updateYukiSetting = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'yuki-setting/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_YUKI_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const updateYukiSetting2 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'yuki-setting-2/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_YUKI_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateYukiSetting3 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'yuki-setting-3/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_YUKI_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchPopUpPercent = async ({commit}, payload) => {
    commit('UPDATE_POPUP_PERCENT', payload);
};
const fetchCurrentYukiSettingData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'yuki-setting/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchGetAllYukiSetting,
    updateYukiSetting,
    updateYukiSetting2,
    updateYukiSetting3,
    fetchPopUp,
    fetchPopUpPercent,
    fetchCurrentYukiSettingData,
    deleteData
};
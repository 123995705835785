<template>
  <div style="padding: 10px 30px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="channel.group" label="Group Number" readonly class="right-input" />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="channel.no" label="Fight Number" readonly class="right-input" />
          </v-col>
          <v-col cols="1" style="padding-top: 30px;">
            <v-btn @click="alertAddTextDialog"  color="primary" :disabled="channel.is_open != false || loading == true ? true : false">
              ADD Text
            </v-btn>
          </v-col>
          <v-col cols="2" style="padding-top: 30px;">
            <v-btn @click="alertCancelDialog"  color="error" :disabled="channel.is_open != false || loading == true || channel.is_result == true ? true : false">
              Cancel Result
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-simple-table class="align-center" style="margin-left: 15px;">
            <template v-slot:default>
              <thead>
              <tr style="border: solid #0c1830">
                <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(244, 201, 5);">A</th>
                <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(29, 203, 38);">B</th>
                <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(199, 5, 210);">C</th>
                <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(213, 2, 1);">D</th>
                <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(2, 195, 183);">E</th>
              </tr>
            </thead>
            <tbody style="border: solid #0c1830">
              <tr>
                <td>
                  <v-text-field v-model="result.a.a1" :rules="rules.a1" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.b.b1" :rules="rules.b1" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.c.c1" :rules="rules.c1" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.d.d1" :rules="rules.d1" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.e.e1" :rules="rules.e1" required solo></v-text-field>
                </td>
              </tr>
              <tr>
                <td>
                  <v-text-field v-model="result.a.a2" :rules="rules.a2" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.b.b2" :rules="rules.b2" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.c.c2" :rules="rules.c2" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.d.d2" :rules="rules.d2" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.e.e2" :rules="rules.e2" required solo></v-text-field>
                </td>
              </tr>
              <tr>
                <td>
                  <v-text-field v-model="result.a.a3" :rules="rules.a3" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.b.b3" :rules="rules.b3" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.c.c3" :rules="rules.c3" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.d.d3" :rules="rules.d3" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.e.e3" :rules="rules.e3" required solo></v-text-field>
                </td>
              </tr>
              <tr>
                <td>
                  <v-text-field v-model="result.a.a4" :rules="rules.a4" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.b.b4" :rules="rules.b4" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.c.c4" :rules="rules.c4" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.d.d4" :rules="rules.d4" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.e.e4" :rules="rules.e4" required solo></v-text-field>
                </td>
              </tr>
              <tr>
                <td>
                  <v-text-field v-model="result.a.a5" :rules="rules.a5" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.b.b5" :rules="rules.b5" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.c.c5" :rules="rules.c5" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.d.d5" :rules="rules.d5" required solo></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="result.e.e5" :rules="rules.e5" required solo></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
        </v-row>
        <v-row>
          <v-col cols="2" class="text-left update-chanel">
            <v-btn color="error" @click="alertResetResultDialog" :disabled="
              channel.is_open == false ||
                loading == true ||
                channel.is_open == true
                ? true
                : false
            ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Result
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-left update-chanel">
            <v-btn v-if="channel.is_result" color="primary" @click="alertSetResultDialog" disabled="true">
              <v-icon class="mr-2" v-if="channel.is_result">fas fa-circle-notch fa-spin</v-icon>
              <span class="lds-roller my-3"> Waiting result finished...</span>
            </v-btn>
            <v-btn v-else color="primary" @click="alertSetResultDialog" :disabled="
              channel.is_open != false ||
                loading == true
                ? true
                : false
            ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              submit
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-left text-h5">
            <span class="uppercase"> </span>
          </v-col>
          <v-col cols="3" class="text-center text-h5 channel-status-col">
            <span :class="[
              channelStatus(channel.is_open).color,
              'uppercase pl-4 channel-status',
            ]">
              {{ channelStatus(channel.is_open).status }}</span>
          </v-col>
          <v-col cols="4" class="text-right">
            <span class="uppercase update-channel-status mr-5">
              {{ channelUpdateStatus(channel.is_open).desc }}
            </span>
            <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
              @click="_updateChanelStatus" :disabled="channel.is_open == false || !canUpdateStatus">
              {{ channelUpdateStatus(channel.is_open).status }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialogResetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Result
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Set Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogSetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_setResult">
            Yes, Set Result
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Cancel Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogCancelResult = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_cancelResult">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddText" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        
        <v-card-body>
          <v-textarea label="Result" v-model="result_text" auto-grow></v-textarea>
        </v-card-body>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogAddText = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_addResult">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: [
    "channel",
    "objCoResult"
  ],

  data() {
    return {
      valid: true,
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      n1: null,
      updateChanel: [],
      canUpdateStatus: true,
      dialogResetResult: false,
      dialogSetResult: false,
      dialogAddText: false,
      dialogCancelResult: false,
      channel_type: null,
      result_text: '',
      result: {
        a: {
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: ""
        },
        b: {
          b1: "",
          b2: "",
          b3: "",
          b4: "",
          b5: ""
        },
        c: {
          c1: "",
          c2: "",
          c3: "",
          c4: "",
          c5: ""
        },
        d: {
          d1: "",
          d2: "",
          d3: "",
          d4: "",
          d5: ""
        },
        e: {
          e1: "",
          e2: "",
          e3: "",
          e4: "",
          e5: ""
        }
      },
      rules: {
        a1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ]
      }
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_lotvnChannel", {
      loading: "getLoading",
      loadingResult: "getLoadingResult",
    }),
  },
  mounted(){
    this.channel_type = parseInt(this.$cookies.get("channel-type"));
  },
  watch: {
    loading: function () { },
    loadingResult: function () { }
  },
  methods: {
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    alertSetResultDialog() {
      this.dialogSetResult = true;
    },
    alertCancelDialog() {
      this.dialogCancelResult = true;
    },
    alertAddTextDialog() {
      this.dialogAddText = true;
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    _setResult() {
      
      if (this.$refs.form.validate()) {
        let dataResult = {
          result_a: [this.result.a.a1,this.result.a.a2,this.result.a.a3,this.result.a.a4,this.result.a.a5],
          result_b: [this.result.b.b1,this.result.b.b2,this.result.b.b3,this.result.b.b4,this.result.b.b5],
          result_c: [this.result.c.c1,this.result.c.c2,this.result.c.c3,this.result.c.c4,this.result.c.c5],
          result_d: [this.result.d.d1,this.result.d.d2,this.result.d.d3,this.result.d.d4,this.result.d.d5],
          result_e: [this.result.e.e1,this.result.e.e2,this.result.e.e3,this.result.e.e4,this.result.e.e5],
          result_lo:[this.result.a.a1,this.result.b.b1,this.result.c.c1,this.result.d.d1,this.result.e.e1,
                     this.result.a.a2,this.result.b.b2,this.result.c.c2,this.result.d.d2,this.result.e.e2,
                     this.result.a.a3,this.result.b.b3,this.result.c.c3,this.result.d.d3,this.result.e.e3,
                     this.result.a.a4,this.result.b.b4,this.result.c.c4,this.result.d.d4,this.result.e.e4,
                     this.result.a.a5,this.result.b.b5,this.result.c.c5,this.result.d.d5,this.result.e.e5]
        }
        this.$emit("submitResult",dataResult);
        this.dialogSetResult = false;
      }
    },
    _cancelResult(){
      this.$emit("cancelResult");
        this.dialogCancelResult = false;
    },
    _addResult(){
      let arr_text = this.result_text.split("\n");
      if(arr_text.length >0){
        this.result.a.a1 = arr_text[0] ?? arr_text[0];
        this.result.b.b1 = arr_text[1] ?? arr_text[1];
        this.result.c.c1 = arr_text[2] ?? arr_text[2];
        this.result.d.d1 = arr_text[3] ?? arr_text[3];
        this.result.e.e1 = arr_text[4] ?? arr_text[4];
        this.result.a.a2 = arr_text[5] ?? arr_text[5];
        this.result.b.b2 = arr_text[6] ?? arr_text[6];
        this.result.c.c2 = arr_text[7] ?? arr_text[7];
        this.result.d.d2 = arr_text[8] ?? arr_text[8];
        this.result.e.e2 = arr_text[9] ?? arr_text[9];
        this.result.a.a3 = arr_text[10] ?? arr_text[10];
        this.result.b.b3 = arr_text[11] ?? arr_text[11];
        this.result.c.c3 = arr_text[12] ?? arr_text[12];
        this.result.d.d3 = arr_text[13] ?? arr_text[13];
        this.result.e.e3 = arr_text[14] ?? arr_text[14];
        this.result.a.a4 = arr_text[15] ?? arr_text[15];
        this.result.b.b4 = arr_text[16] ?? arr_text[16];
        this.result.c.c4 = arr_text[17] ?? arr_text[17];
        this.result.d.d4 = arr_text[18] ?? arr_text[18];
        this.result.e.e4 = arr_text[19] ?? arr_text[19];
        this.result.a.a5 = arr_text[20] ?? arr_text[20];
        this.result.b.b5 = arr_text[21] ?? arr_text[21];
        this.result.c.c5 = arr_text[22] ?? arr_text[22];
        this.result.d.d5 = arr_text[23] ?? arr_text[23];
        this.result.e.e5 = arr_text[24] ?? arr_text[24];
      }
      this.dialogAddText = false;
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
        this.canUpdateStatus = false;
        setTimeout(this.letUpdateChanelStatus, 3000);
      }
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text",
        };
      } else {
        return {
          status: "LIVE",
          color: "warning--text",
        };
      }
    },
    channelUpdateStatus(status) {
      if (status === true) {
        return {
          status: "CLOSE",
          desc: "SET Status TO",
          background: "red",
        };
      } else if (status === false) {
        return {
          status: "Please set RESULT",
          desc: "",
          background: "white",
        };
      } else {
        return {
          status: "OPEN",
          desc: "SET Status TO",
          background: "success",
        };
      }
    },
    _select() {
      this.dialogResult = true;
    },
    _submitResult() {
      // this.confirmDialog = true;
    },
    async _updateChanelStatus() {
      this.$emit("submitUpdateStatus", this.resultData)
      this.canUpdateStatus = false;
      setTimeout(this.letUpdateChanelStatus, 3000);
    },
    letUpdateChanelStatus() {
      this.canUpdateStatus = true;
    },
    ...mapMutations("$_lotvnChannel", ["LOADING"]),
  },
};
</script>

<style scoped>
.channel-status {
  font-weight: 900;
  font-size: 55px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
}

.result-image {
  max-width: fit-content;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}

.confirm-btn {
  color: white !important;
  font-weight: 700;
}

.right-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #fb8c00 !important;
}

.sample>>>input {
  margin-top: 5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}

.update-chanel-btn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: -7px !important;
}

.update-chanel {
  margin-top: -2px !important;
}

.cancel-btn {
  color: white !important;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  background-color: #3f9542 !important;
}
</style>

<template>
  <v-card >
    <form-channel
      :channel="currentChannel.currentChannel"
      v-on:submitUpdateStatus="handleUpdateChanelStatus"
      v-on:submitResetResult="handleResetResult"
      v-on:submitResult="handleSubmitResult"
      v-on:cancelResult="handleCancelResult"
    />
    <table-result
      :channel="currentChannel.currentChannel"
      :objCoResult="objCoResult"
      :loadingResult="loadingResult"
    />
  </v-card>

</template>

<script>
import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    formChannel,
    tableResult,
  },
  data: () => {
    return {
      loading: false,
      
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_lotvnChannel", {
      currentChannel: "getCurrentChannel",
      objCoResult: "getResultTable",
      loadingResult: "getLoadingResult",
    }),
  },

  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
    }
  },
  watch: {
    objCoResult: function() {},
    currentChannel: function() {},
    loadingResult: function() {},
  },
  async mounted() {
    this.$socketLotVn1.on("server1-update-channel-info", data => {
      console.log("data1",data);
      if (data) {
        this.fetchCurrentChannel();
      }
    });
    this.$socketLotVn2.on("server2-update-channel-info", data => {
      console.log("data2",data);
      if (data) {
        this.fetchCurrentChannel();
      }
    });
  },
  methods: {
    async handleResetResult() {
      await this.resetResult();
    },
    async handleUpdateChanelStatus() {
      let submitData = {
          _id: this.currentChannel.currentChannel._id,
        }
      await this.UpdateChanelStatus({ vm: this, data: submitData });
    },
    async handleSubmitResult(data) {
      console.log("data",data);
      let submitData = {
        channel: this.currentChannel,
        result: data,
      };
      await this.createResult({ vm: this, data: submitData });
      this.fetchCurrentChannel();
    },
    async handleCancelResult() {
      let submitData = this.currentChannel;
      await this.cancelResult({ vm: this, data: submitData });
      this.fetchCurrentChannel();
    },
    async handleSubmitUpdateChanel(dataUpdateChanel) {
      delete dataUpdateChanel.enable;
      await this.updateChannel({ vm: this, data: dataUpdateChanel });
    },
    ...mapMutations("$_lotvnChannel", [
      "UPDATE_CHANNEL",
      "RESULT_TABLE",
      "LOADINGRESULT",
    ]),
    ...mapActions("$_lotvnChannel", [
      "fetchCurrentChannel",
      "createResult",
      "updateChannel",
      "UpdateChanelStatus",
      "resetResult",
      "cancelResult"
    ]),
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div class="section-header" hidden>
      <h1>Bank User</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Bank User</div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            class=" table table-striped"
            id="table-1"
            item-key="_id"
            :headers="headers"
            :items="getBankUser && getBankUser.object ? getBankUser.object : []"
            :footer-props="footerProps"
            
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            show-select
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <Header
                :darkmode="darkmode"
                :params="data"
                :data-delete="getDeleteItems"
                :user-permission="userAccessPermission"
              />
            </template>

            <!-- <template v-slot:[`item.is_enable`]="{ item }">
              <v-chip
                label
                text-color="white"
                :color="item.is_enable ? 'green' : 'red'"
              >
                {{ item.is_enable ? "Active" : "Inactive" }}
              </v-chip>
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
              <ActionComponents
                :darkmode="darkmode"
                :item="item"
                :dataDelete="getDeleteItems"
                :params="data"
                :user-permission="userAccessPermission"
              />
            </template>
          </v-data-table>
        </div>
        <Dialog
          :darkmode="darkmode"
          :dialog="getPopUp"
          :bankUser="getCurrentBankUser"
          :optionBankUser="getParentBankUser"
          :is-validate.sync="valid"
          :loading="loading"
          v-on:onCloseDialog="fetchPopUpBankUser(false)"
          v-on:submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/tableHeader.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    loading: false,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [false],
      sortByFormat: ["_id"],
      descending: false,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["_id","account_name","account_number","account_info"],
      search: "",
    },
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("header.bank_name"), align: "start", value: "bank_id.name" },
        { text: this.$t("header.account_name"), value: "account_name"},
        { text: this.$t("header.account_number"), value: "account_number"},
        { text: this.$t("header.account_info"), value: "account_info"},
        { text: this.$t("header.currency_type"), value: "currency_type"},
        {
          text: this.$t("header.created_at"),
          value: "createdAt",
          sortable: false,
        },
        // {
        //   text: this.$t("header.is_enable"),
        //   value: "is_enable",
        //   sortable: false,
        // },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_permission", ["getPermission"]),
    ...mapGetters("$_bankUser", [
      "getBankUser",
      "getPopUp",
      "getCurrentBankUser",
      "getDeleteItems",
      "getParentBankUser",
      "getLoading",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentBankUser._id) {
        await this.createBankUser({ vm: this, data: this.getCurrentBankUser });
      } else {
        await this.updateBankUser({ vm: this, data: this.getCurrentBankUser });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListBankUser(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    ...mapMutations("$_bankUser", ["DELETE_ITEMS", "UPDATE_BANK_USER"]),
    ...mapActions("$_bankUser", [
      "fetchListBankUser",
      "fetchPopUpBankUser",
      "createBankUser",
      "updateBankUser",
      "fetchCurrentBankUser",
      "fetchParentBankUser",
    ]),
    ...mapActions("$_permission", ["fetchListPermission"]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_BANK_USER([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

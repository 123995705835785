/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchAllKenoSetting = async ({ commit }, data) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'slot-keno-setting/getAll',
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_KENO_SETTING_DATA', response.data.data);
        commit('LOADING', false);
    }
};


const updateKenoSetting = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-keno-setting/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_KENO_SETTING_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateKenoSetting2 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-keno-setting-2/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_KENO_SETTING_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateKenoSetting3 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-keno-setting-3/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_KENO_SETTING_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchPopUpPercent = async ({commit}, payload) => {
    commit('UPDATE_POPUP_PERCENT', payload);
};
const fetchCurrentKenoSettingData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'limitBetting/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchAllKenoSetting,
    updateKenoSetting,
    updateKenoSetting2,
    updateKenoSetting3,
    fetchPopUp,
    fetchPopUpPercent,
    fetchCurrentKenoSettingData,
    deleteData
};
const getDragonSettingData = (state) => state.dragonSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentDragonSettingData = (state) => state.currentDragonSettingData;


export default {
    getDragonSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentDragonSettingData,
};
<template>
  <div class="container-fluid" style="margin-top: 20px;">
    <!-- play card -->
    <v-row v-if="
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' ||
        userp.role_id == '6472bf9b1ba4b8a1604940c1'
      ">
      <v-col cols="6" md="6">
        <v-card
         class="justify-center"
          outlined
          style="padding: 50px; background-color: green; font-weight: bold;"
          @click="withdraw()"
        >
          <v-card-title primary-title class="justify-center">
            <div>
              <h4 class="white--text">Pending Withdraw</h4>
            </div>
          </v-card-title>
          <v-card-text primary-title class="text-center">
            <div style="font-size: 55px; font-weight: bold;" class="black--text"> {{ dashboardData.withdraw }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="6">
        <v-card
         class="justify-center"
          outlined
          style="padding: 50px; background-color: brown; font-weight: bold;"
          @click="deposit()"
        >
          <v-card-title primary-title class="justify-center">
            <div>
              <h4 class="white--text">Pending Deposit</h4>
            </div>
          </v-card-title>
          <v-card-text primary-title class="text-center">
            <div style="font-size: 55px; font-weight: bold;" class="black--text"> {{ dashboardData.deposit }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="6">
        <v-card
         class="justify-center"
          outlined
          style="padding: 50px; background-color: orange; font-weight: bold;"
          @click="register()"
        >
          <v-card-title primary-title class="justify-center">
            <div>
              <h4 class="white--text">New Register Member</h4>
            </div>
          </v-card-title>
          <v-card-text primary-title class="text-center">
            <div style="font-size: 55px; font-weight: bold;" class="black--text"> {{ dashboardData.register }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="6">
        <v-card
         class="justify-center"
          outlined
          style="padding: 50px; background-color: red; font-weight: bold;"
          @click="reject()"
        >
          <v-card-title primary-title class="justify-center">
            <div>
              <h4 class="white--text"> New Register Member (Can't Contact)</h4>
            </div>
          </v-card-title>
          <v-card-text primary-title class="text-center">
            <div style="font-size: 55px; font-weight: bold;" class="black--text"> {{ dashboardData.reject }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TableBasic",
  data() {
    return {
      singleExpand: null,
      dashboardData:{},
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
      userp: "getUser"
    }),
  
  },
  async created() {
    await this.getDashboard();
  },
  watch: {
    userp: function () {},
  },
  methods:{
    async getDashboard() {
      await this.$request
        .get({
          url: "dashboard/getAll",
        })
        .then((res) => {
          if (res.data.code) {
            this.dashboardData = res.data.data;
          }
        });
    },
    withdraw(){
      this.$router.push("/withdrawPending");
    },
    deposit(){
      this.$router.push("/depositPending");
    },
    register(){
      this.$router.push("/newRegisterMember");
    },
    reject(){
      this.$router.push("/newRegisterMemberNotContact");
    }

  }
};
</script>

<style lang="scss" scoped>
.h5set {
  h5 {
    line-height: 1.6;
    font-family: "khmer mef1";
  }
}
.h3set {
  h3 {
    font-family: "khmer mef1";
    font-size: 14px;
  }
}
.h1set {
  h1 {
    font-family: "khmer mef2";
    font-size: 18px;
  }
}
</style>
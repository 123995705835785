/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListApp = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true

    const response = await request.post({
        url: 'apps/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_APP', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createApp = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'apps',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created app.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created app.`)
};

const updateApp = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'apps/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated app.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated app.`)
};

const deleteApp = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'apps/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted role.`)
    } else vm.$toastr.e(`'failed delete role.`)
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentApp = async({ commit }, payload) => {
    commit('UPDATE_CURRENT_APP', payload);
};
const fetchListCompany = async ({commit}) => {
    const response = await request.get({
        url: 'users/getListUserCompany'
    });
    if (response.data.code) {
        commit('LIST_COMPANY', response.data.data);
    }
};

const fetchListSenior = async(data) => {
    const response = await request.post({
        url: 'users/getListUserSenior',
        data
    });
    console.log("response", response.data.data);
    if (response.data.code) {
       // commit('LIST_SENIOR', response.data.data);
       return response.data.data;
    }
};
// const fetchListTreeMenuByApp = async ({commit}) => {
//     const response = await request.get({
//         url: 'app-menu/getTreeMenuByApp'
//     });
//     if (response.data.code) {
//         commit('LIST_TREE_MENU_BY_APP', response.data.data);
//     }
// };
const fetchListTreeMenuByApp = async ({commit}) => {
    const response = await request.get({
        url: 'app-menu/getTreeMenuByApp'
    });
    if (response.data.code) {
        commit('LIST_TREE_MENU_BY_APP', response.data.data);
    }
};
const fetchListTreeMenuSelections = async ({commit},data) => {
    let appId = {
        app_id:data._id
    };
    const response = await request.post({
        url: 'app-menu/getTreeMenuSelected',
        data:appId
    });
    if (response.data.code) {
        commit('UPDATE_SELECT_TREE_MENU', response.data.data);
    }
};

export default {
    fetchListApp,
    fetchPopUp,
    fetchCurrentApp,
    createApp,
    updateApp,
    deleteApp,
    fetchListCompany,
    fetchListSenior,
    fetchListTreeMenuByApp,
    fetchListTreeMenuSelections
};

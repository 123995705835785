import reverseLottoCh10 from '@/modules/reverseResult/lottoCh10';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseLottoCh10',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseLottoCh10,
        name: 'reverseLottoCh10'
    }]
}];

export default routes;
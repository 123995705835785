import historyByFightApong from '@/modules/historyByFightApong';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightApong',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightApong,
        name: 'historyByFightApong'
    }]
}];

export default routes;
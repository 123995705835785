<template>
    <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="codeName"
    class="elevation-1"
    
    show-select
    item-key="codeName"
    :single-select="singleSelect"
    style="font-family:'khmer mef1'"
  >   

    <template v-slot:top>
      <v-toolbar
        flat
      >
      <v-toolbar-title>NOTIFICATION LIST</v-toolbar-title>        
      <v-spacer></v-spacer>  
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      
      <v-spacer></v-spacer>
      <v-btn>DELETE ALL</v-btn>
      <!-- dialog -->      
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template  v-slot:activator="{ on, attrs }">
            <v-btn
              
              v-bind="attrs"
              v-on="on"
            >
              CREATE
            </v-btn>
          </template>
          <v-card >
            <v-toolbar              
              
            >
              <v-btn
                icon
                
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  
                  text
                  @click="dialog = false"
                >
                  Save
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>           

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-combobox
                        v-model="select"
                        :items="user_name"
                        label="USER NAME"
                        multiple
                        chips
                    >

                     <template v-slot:selection="data">
                        <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                        >
                        <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                        </v-chip>
                    </template>

                    </v-combobox>
                 </v-col>

                  <v-col
                    cols="12"                    
                  >
                    <v-text-field
                      v-model="editedItem.dataStructure"
                      label="DESCRIPTION"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"                   
                  >   
                  <v-switch
                      v-model="editedItem.active"
                      label="Active"
                    ></v-switch>    

                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>            
          </v-card>
        </v-dialog>
      </v-row>
      <!-- end dialog create user -->

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card >
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  text @click="closeDelete">Cancel</v-btn>
              <v-btn  text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.active="{ item }">
      <v-chip
        
      >
        {{ item.active }}
      </v-chip>
    </template>
  
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>

     

    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
   
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      active: true,
      singleSelect: false,
      selected: [],
      user_name:['Senior','Master','Agent','Player'],
      headers: [
        { text: 'សកម្មភាព', value: 'actions', sortable: false },
        {
          text: 'ឈ្មោះអ្នកទទួល',
          align: 'start',
          sortable: false,
          value: 'user_account',
        },
        { text: 'បរិយាយ', value: 'description' },
        { text: 'កាលបរិច្ឆេទ', value: 'date' },
        { text: 'ស្ថានភាព', value: 'active' },
        
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        user_account: '',
        description:'',
        date: '',
        active:'',
        
      },
      defaultItem: {
        user_account: '',
        description:'',
        date: '',
        active:'',
      },
    }),
    computed: {
      ...mapGetters("$_modules", {
        darkmode: "getDarkMode",
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'CREATE NEW' : 'EDIT ITEM'
      },
    },

   watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.desserts = [
          {
            user_account: 'Agent',
            description:'DESC',
            date: '12/Jun/2021',
            active:'Active',
          },
         
        ]
      },

       editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },

    },
}
</script>

<style lang="scss" scoped>

</style>
/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchGetAllLottoSetting = async ({ commit }, data) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'slot-lot-setting/getAll',
        data
    });
    console.log(response.data.data);
    if (response.data.code) {
        commit('UPDATE_LOTTO_SETTIN_DATA', response.data.data);
        commit('LOADING', false);
    }
};

const updateLottoSetting = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-lot-setting/'+_id,
        data
    });
    console.log("response.data",response.data);
    if (response.data.code) {
        commit('UPDATE_LOTTO_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
       // vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const updateLottoSetting2 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-lot-setting-2/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_LOTTO_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};
const updateLottoSetting3 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-lot-setting-3/'+_id,
        data
    });
    console.log("response.data",response.data);
    if (response.data.code) {
        commit('UPDATE_LOTTO_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchPopUpPercent = async ({commit}, payload) => {
    commit('UPDATE_POPUP_PERCENT', payload);
};
const fetchCurrentLottoSettingData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'limitBetting/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchGetAllLottoSetting,
    updateLottoSetting,
    updateLottoSetting2,
    updateLottoSetting3,
    fetchPopUp,
    fetchPopUpPercent,
    fetchCurrentLottoSettingData,
    deleteData
};
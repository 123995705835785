/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchCurrentChannel = async ({ commit }, data) => {
    const response = await request.get({
        url: 'lotvn/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objChannel);
        commit('RESULT_TABLE', response.data.data.objResult);
    }
};



const createResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'lotvn/createResultAndNewChannel',
        data
    });
    if (response.data.code) {
        commit('RESULT_TABLE', response.data.data.objResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
        // window.location.reload();
    }

};
const cancelResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'lotvn/cancelResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objChannel);
        commit('RESULT_TABLE', response.data.data.objResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
        // window.location.reload();
    }

};

const UpdateChanelStatus = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.put({
        url: 'lotvn/updateStatusChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objChannel);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateChannel = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'lotvn',
        data
    });
    if (response.data) {
        commit('UPDATE_CHANNEL', response.data.data.objChannel);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const resetResult = async ({commit}) => {
   
    const response = await request.get({
        url: 'lotvn/resetResult'
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objChannel);
        commit('RESULT_TABLE', []);

    }
};

export default {
    fetchCurrentChannel,
    createResult,
    updateChannel,
    UpdateChanelStatus,
    resetResult,
    cancelResult
};
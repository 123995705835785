const UPDATE_DRAWER = (state, data) => {
    state.drawer = data;
};
const UPDATE_MENU = (state, data) => {
    state.menus = data;
    state.submenuList = data[(data.length-1)].items;
};
const UPDATE_MENU_LIST = (state, data) => {
    state.menus = data.menuList;
    state.submenuList = data.menuList[data.index].items;
    console.log("UPDATE_MENU", data.index);
};
const UPDATE_SUBMENU_LIST = (state, data) => {
    state.submenuList = data;
};
const UPDATE_USER_INFO = (state, data) => {
    state.userInfo = data;
};
const UPDATE_USER = (state, data) => {
    state.user = data;
};
const UPDATE_DARK_MODE = (state, data) => {
    state.darkMode = data;
};
const UPDATE_EYE_IS_ON = (state, data) => {
    console.log("UPDATE_EYE_IS_ON mu :: ", data);
    state.eyeIsOn = data;
};
const UPDATE_MMENU_SELECTED = (state, data) => {
    state.mainMenuSelected = data;
};
const UPDATE_SMENU_SELECTED = (state, data) => {
    state.subMenuSelected = data;
};
const UPDATE_USER_ACCESS_PERMISSION = (state, data) => {
    state.userAccessPermission = data
}

export default {
    UPDATE_DRAWER,
    UPDATE_MENU,
    UPDATE_USER_INFO,
    UPDATE_DARK_MODE,
    UPDATE_USER,
    UPDATE_USER_ACCESS_PERMISSION,
    UPDATE_EYE_IS_ON,
    UPDATE_MENU_LIST,
    UPDATE_SUBMENU_LIST,
    UPDATE_MMENU_SELECTED,
    UPDATE_SMENU_SELECTED
};

import reverseLotvn from '@/modules/reverseResult/lotvn';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseLotvn',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseLotvn,
        name: 'reverseLotvn'
    }]
}];

export default routes;
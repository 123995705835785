const getDodenSettingData = (state) => state.dodenSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentDodenSettingData = (state) => state.currentDodenSettingData;


export default {
    getDodenSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentDodenSettingData,
};
const getSicboSettingData = (state) => state.sicboSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentSicboSettingData = (state) => state.currentSicboSettingData;


export default {
    getSicboSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentSicboSettingData,
};
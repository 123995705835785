<template>
  <v-card >
    <form-channel
      :channel="currentChannel.currentChannel"
      :objCoTypeOfBetting="objCoTypeOfBetting"
      :socketChannelType ="dataFromSocket.type"
      :socketChannelCountdown ="dataFromSocket.countDown"
      :selectResult="selectResult"      
      v-on:submitUpdateStatus="handleUpdateChanelStatus"
      v-on:submitUpdateChanel="handleSubmitUpdateChanel"
      v-on:submitResetResult="handleResetResult"
    />
      <table-result 
        v-on:submitResult="handleSubmitResult"
        :channel="currentChannel.currentChannel"
        :objCoTypeOfBetting="objCoTypeOfBetting"
        :objCoResult="objCoResult"
        :objSummaryCoResult="objSummaryCoResult"/>
  </v-card>
</template>

<script>
import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    formChannel,
    tableResult
  },
  data: () => {
    return {
      dataFromSocket:[],
      selectResult: {
        order: null,
        _id: null,
      },
      loading:false
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode"
    }),
    ...mapGetters("$_reverseResultYukiCh11", {
      currentChannel: "getCurrentChannel",
      objCoTypeOfBetting: "getBtnResults",
      objSummaryCoResult: "getSummaryResult",
      objCoResult: "getResultTable",
    })
  },

  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
      await this.fetchBtnResult();
    }
  },
  watch: {
    objCoResult: function () {
    },
    currentChannel:function () {
      
    },
  },
  async mounted() {
    
  },
  methods: {
    async handleResetResult(){
      await this.resetResult();
    },
    async handleUpdateChanelStatus() {
       let submitData = {
        _id: this.currentChannel.currentChannel._id,
      };
     await this.UpdateChanelStatus({ vm: this, data: submitData });
    },
    async handleSubmitResult(data) {
      let submitData = {
        channel_id  : data.channel_id,
        type_betting_id  : data.resultTypeId
      }
      console.log("submitData",submitData);
      await this.reverseResult({ vm: this, data: submitData });
      await this.fetchCurrentChannel();
    },
    async handleSubmitUpdateChanel(dataUpdateChanel) {
      await this.updateChannel({ vm: this, data: dataUpdateChanel } )
    },

    ...mapMutations("$_reverseResultYukiCh11", ["UPDATE_CHANNEL", "SET_RESULT", "SUMMARY_RESULT","RESULT_TABLE"]),
    ...mapActions("$_reverseResultYukiCh11", [
      "fetchCurrentChannel",
      "fetchBtnResult",
      "reverseResult",
      "updateChannel",
      "UpdateChanelStatus",
      "resetResult"
    ])
  }
};
</script>

<style lang="scss" scoped></style>

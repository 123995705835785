const getKenoSettingData = (state) => state.kenoSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentKenoSettingData = (state) => state.currentKenoSettingData;


export default {
    getKenoSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentKenoSettingData,
};
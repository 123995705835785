const UPDATE_CHANNEL = (state, data) => {
    state.currentChannel = data;
}
const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
    state.currentChannel = data;
}
const BTN_RESULT = (state, data) => {
    state.objCoTypeOfBetting = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}

const LOADINGRESULT = (state, data) => {
    state.loadingResult = data;
}

const SET_RESULT = (state, data) => {
    state.selectResult = data
}


const RESULT_TABLE = (state, data) => {
    state.objSnResult = data
}

const SUMMARY_RESULT = (state, data) => {
    state.objSummaryCoResult = data
}

export default {
    UPDATE_CHANNEL,
    BTN_RESULT,
    SET_RESULT,
    SUMMARY_RESULT,
    RESULT_TABLE,
    UPDATE_CHANNEL_AFTER_RESULT,
    LOADING,
    LOADINGRESULT
};
export default {
    permission: 'Permission',
    role: 'Role',
    description: 'Description',
    menu_name: 'Menu Name',
    icon: 'Icon',
    order: 'Order',
    active: 'Active(Betting)',
    actions: 'Actions',
    balance: 'Balance',
    created_at: 'Created At',
    bank_account: 'Account Note',
    user_name: 'Username',
    deposit_withdraw: 'Cash-in/Cash-out',
    address :"Address Ip",
    no :"No",
    suspend:"Suspend (Login To system)",
    public_ip:"Public Ip",
    parentName: "Parent Name",
    userName: "User Name",
    withdrawAmount :"Cash-out Amount",
    depositAmount :"Cash-in Amount",
    oldBalance :"Old Balance",
    newBalance :"New Balance",
    date:"Datetime",
    percentage_wala_min:"Wala Min",
    percentage_wala_max:"Wala Max",
    percentage_meron_min:"Meron Min",
    percentage_meron_max:"Meron Max",
    target_kh:"Target KH",
    target_usd:"target Usd",
    target_tb:"target TB",
    channel_type :"Channel Type",
    fight_no:"Fight No",
    percentage_wala_start:"Percentage Wala Start",
    percentage_meron_start :"percentage Meron Start",
    vip_option:"VIP Option",
    update_config:"VIP option",
    configUser:"VIP option",
    createdAt :"Created Date",
    updatedAt :"Updated Date",
    label : "Label",
    percentage:"Percentage",
    bet_type:"Bet Type",
    public_id:"Ip",
    amount:"Amount",
    payout:"Payout",
    username:"User Name",
    main_link: "Main Link",
    is_enable: "Enabled",
    api_player_link: "API Player Link",
    api_admin_link: "API Admin Link",
    app_name: "App Name",
    reportDW: "Report Cash-in/Cash-out",
    reportBetting:"Report Betting",
    updateConfig:"Limit Betting",
    group_no:"Group No",
    search:"SEARCH",
    finish:"Finish",
    start:"Start",
    bank_name: "Bank Name",
    account_name: "Account Name",
    account_number: "Account Number",
    account_info: "Account Info",
    currency_type: "Currency Type",
    agentUserName: "Agent User",
    filter_menu:"Fillter Menu"
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive",staticStyle:{"text-align":"center"}},[_c('v-data-table',{staticClass:"table table-striped",staticStyle:{"font-family":"'khmer mef1'"},attrs:{"headers":_vm.headers,"items":_vm.getStatement ? _vm.getStatement.object : [],"footer-props":_vm.footerProps,"item-key":"_id","id":"table-1","server-items-length":_vm.getStatement ? _vm.getStatement.count : null,"page":_vm.data.page,"items-per-page":_vm.data.rowsPerPage,"sort-desc":_vm.data.descendingFormat,"sort-by":_vm.data.sortByFormat,"hide-default-header":"","loading":_vm.getLoading,"value":_vm.getDeleteItems,"mobile-breakpoint":"0"},on:{"update:page":[function($event){return _vm.$set(_vm.data, "page", $event)},_vm.getData],"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},_vm.getData],"update:sortDesc":function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},_vm.getData],"update:sortBy":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"update:sort-by":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"input":_vm.itemDelete},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(head){return _c('th',{key:head._id},[_vm._v(" "+_vm._s(head.text)+" ")])})}},{key:"top",fn:function(){return [_c('Header',{attrs:{"darkmode":_vm.darkmode,"params":_vm.data,"dataDelete":_vm.getDeleteItems,"user-permission":_vm.userAccessPermission}})]},proxy:true},{key:`item`,fn:function({ item }){return [_c('tr',{style:(item.is_reciever_read == false
              ? 'background-color:#FFE3E5'
              : '')},[_c('td',{style:(item.type == 'WITHDRAW'
              ? 'color:red'
              : 'color:green')},[_vm._v(" "+_vm._s(item.type)+" ")]),_c('td',[_vm._v(_vm._s(_vm.currencyFormat(item.amount)))]),_c('td',[_vm._v(_vm._s(item.sender_username))]),_c('td',[_vm._v(_vm._s(item.currency_type == 1? "DOLLAR":item.currency_type == 2 ? "KHR": item.currency_type == 3? "THAI": "VN"))]),_c('td',[_vm._v(_vm._s(item.send_date))]),_c('td',[_vm._v(_vm._s(item.response_date))]),_c('td',{style:(item.status == 'REJECT'
              ? 'color:red'
              : item.status == 'SUCCESS'
              ? 'color:green'
              : item.status == 'PENDING'
              ?'color:#cbb70e'
              :'')},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('td',[_c('ActionComponents',{attrs:{"darkmode":_vm.darkmode,"item":item,"userp":_vm.userp,"dataDelete":_vm.getDeleteItems,"params":_vm.data,"user-permission":_vm.userAccessPermission}})],1)])]}}],null,true)})],1),_c('Dialog',{attrs:{"darkmode":_vm.darkmode,"loading":_vm.loading,"bankStatement":_vm.getCurrenBankStatement,"all_amount":parseFloat((parseFloat(_vm.getCurrenBankStatement.amount)+ (parseFloat(_vm.getCurrenBankStatement.promotion_percentage) * parseFloat(_vm.getCurrenBankStatement.amount)) / 100).toFixed(2)),"dialog":_vm.getPopUp,"is-validate":_vm.valid},on:{"approve":_vm.approvePlayer,"reject":_vm.rejectPlayer,"onCloseDialog":_vm.closeDialogCreate}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-layout class="table-result" style="width:100%!important; padding: 10px 30px">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          <tr>
            
          </tr>
          <tr v-if="objResult.length > 0">
            <td>
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span  height="50px"> No </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #cfb006">A</v-btn>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #034fa0">B</v-btn>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #05a723">C</v-btn>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #6c0606">D</v-btn>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #ac0707">E</v-btn>
              </div>
            </td>
            <td class="selectResult" v-for="(result, i) in objResult" :key="i" style="height:52px !important; width:52px !important" @click="selectResult(result)">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span v-if="result.no" height="50px"> {{result.no }} </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_a" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_a.color}">{{result.result.result_a.number_label[0] }} </v-btn>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_b" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_b.color}">{{result.result.result_b.number_label[0] }} </v-btn>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_c" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_c.color}">{{result.result.result_c.number_label[0] }} </v-btn>
              </div>  
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_d" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_d.color}">{{result.result.result_d.number_label[0] }} </v-btn>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_e" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_e.color}">{{result.result.result_e.number_label[0] }} </v-btn>
              </div> 
            </td>                                 
        </tr>
        <tr v-else>
          <td  style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  &nbsp;
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>  
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
                </div> 
                <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                    
                </div> 
            </td>  
        </tr>
        </tbody>
      </table>
    </v-layout>
    <!--  dialog result  -->
    <v-dialog v-model="dialogSelectResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-simple-table class="align-center">
                  <template v-slot:default>
                    <thead>
                    <tr class="tbl-reverse">
                      <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(244, 201, 5);">A</th>
                      <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(29, 203, 38);">B</th>
                      <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(199, 5, 210);">C</th>
                      <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(213, 2, 1);">D</th>
                      <th class="text-center" style="background-color: #0c1830; font-weight: bold; font-size: 22px; color: rgb(2, 195, 183);">E</th>
                    </tr>
                  </thead>
                  <tbody class="tbl-reverse">
                    <tr>
                      <td>
                        <v-text-field v-model="result.a.a1" :rules="rules.a1" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.b.b1" :rules="rules.b1" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.c.c1" :rules="rules.c1" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.d.d1" :rules="rules.d1" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.e.e1" :rules="rules.e1" required solo></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <v-text-field v-model="result.a.a2" :rules="rules.a2" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.b.b2" :rules="rules.b2" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.c.c2" :rules="rules.c2" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.d.d2" :rules="rules.d2" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.e.e2" :rules="rules.e2" required solo></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <v-text-field v-model="result.a.a3" :rules="rules.a3" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.b.b3" :rules="rules.b3" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.c.c3" :rules="rules.c3" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.d.d3" :rules="rules.d3" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.e.e3" :rules="rules.e3" required solo></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <v-text-field v-model="result.a.a4" :rules="rules.a4" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.b.b4" :rules="rules.b4" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.c.c4" :rules="rules.c4" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.d.d4" :rules="rules.d4" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.e.e4" :rules="rules.e4" required solo></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <v-text-field v-model="result.a.a5" :rules="rules.a5" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.b.b5" :rules="rules.b5" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.c.c5" :rules="rules.c5" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.d.d5" :rules="rules.d5" required solo></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="result.e.e5" :rules="rules.e5" required solo></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn>
          <v-btn color="error" @click="cancelResult" :disabled="loadingResult">Cancel Result</v-btn>
          <v-btn color="primary" @click="saveResult" :disabled="loadingResult">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import BtnResult from "../../../../components/shared/button/btn-result";
import { mapGetters, mapMutations } from "vuex";
export default {
  // components: {BtnResult},
  props: ['objResult', 'channel'],
  data() {
    return {
      dialogSelectResult: false,
      dialogConfirm:false,
      result_id:null,
      selectedResult: null,
      result: {
        a: {
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: ""
        },
        b: {
          b1: "",
          b2: "",
          b3: "",
          b4: "",
          b5: ""
        },
        c: {
          c1: "",
          c2: "",
          c3: "",
          c4: "",
          c5: ""
        },
        d: {
          d1: "",
          d2: "",
          d3: "",
          d4: "",
          d5: ""
        },
        e: {
          e1: "",
          e2: "",
          e3: "",
          e4: "",
          e5: ""
        }
      },
      rules: {
        a1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        a5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        b5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        c5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        d5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e1: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e2: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e3: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e4: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ],
        e5: [
            (v) => !!v || "Input is required",
            (v) => {
              const pattern = /^([0-9]{3})$/;
              return pattern.test(v) || "Invalid Result.";
            },
        ]
      }
    }
  },
  watch: {
    objResult: function () {
    },
    channel: function () {
    },
    loadingResult: function(newV){
      if(newV == false) this.cancelSelectResult();
    }
  },
  mounted(){
    
  },
  computed: {

...mapGetters("$_reverseLotvn", {
  loadingResult: "getLoadingResult",
}),
},
 
  methods: {
    calculateColumn(c) {
      if (this.channel.column - this.channel.fix_column > 0) {
        return c + (this.channel.column - this.channel.fix_column)
      } else {
        return c;
      }
    },
    selectResult(result){
      this.result_id = result._id;
      this.result.a.a1 = result.result.result_a.number_label[0] ?? result.result.result_.number_label[0];
      this.result.a.a2 = result.result.result_a.number_label[1] ?? result.result.result_a.number_label[1];
      this.result.a.a3 = result.result.result_a.number_label[2] ?? result.result.result_a.number_label[2];
      this.result.a.a4 = result.result.result_a.number_label[3] ?? result.result.result_a.number_label[3];
      this.result.a.a5 = result.result.result_a.number_label[4] ?? result.result.result_a.number_label[4];
      this.result.b.b1 = result.result.result_b.number_label[0] ?? result.result.result_b.number_label[0];
      this.result.b.b2 = result.result.result_b.number_label[1] ?? result.result.result_b.number_label[1];
      this.result.b.b3 = result.result.result_b.number_label[2] ?? result.result.result_b.number_label[2];
      this.result.b.b4 = result.result.result_b.number_label[3] ?? result.result.result_b.number_label[3];
      this.result.b.b5 = result.result.result_b.number_label[4] ?? result.result.result_b.number_label[4];
      this.result.c.c1 = result.result.result_c.number_label[0] ?? result.result.result_.number_label[0];
      this.result.c.c2 = result.result.result_c.number_label[1] ?? result.result.result_a.number_label[1];
      this.result.c.c3 = result.result.result_c.number_label[2] ?? result.result.result_a.number_label[2];
      this.result.c.c4 = result.result.result_c.number_label[3] ?? result.result.result_a.number_label[3];
      this.result.c.c5 = result.result.result_c.number_label[4] ?? result.result.result_a.number_label[4];
      this.result.d.d1 = result.result.result_d.number_label[0] ?? result.result.result_d.number_label[0];
      this.result.d.d2 = result.result.result_d.number_label[1] ?? result.result.result_d.number_label[1];
      this.result.d.d3 = result.result.result_d.number_label[2] ?? result.result.result_d.number_label[2];
      this.result.d.d4 = result.result.result_d.number_label[3] ?? result.result.result_d.number_label[3];
      this.result.d.d5 = result.result.result_d.number_label[4] ?? result.result.result_d.number_label[4];
      this.result.e.e1 = result.result.result_e.number_label[0] ?? result.result.result_e.number_label[0];
      this.result.e.e2 = result.result.result_e.number_label[1] ?? result.result.result_e.number_label[1];
      this.result.e.e3 = result.result.result_e.number_label[2] ?? result.result.result_e.number_label[2];
      this.result.e.e4 = result.result.result_e.number_label[3] ?? result.result.result_e.number_label[3];
      this.result.e.e5 = result.result.result_e.number_label[4] ?? result.result.result_e.number_label[4];
      this.dialogSelectResult = true;
    },
    saveResult(){
      if (this.$refs.form.validate()) {
        let dataResult = {
          result_id: this.result_id,
          result_a: [this.result.a.a1,this.result.a.a2,this.result.a.a3,this.result.a.a4,this.result.a.a5],
          result_b: [this.result.b.b1,this.result.b.b2,this.result.b.b3,this.result.b.b4,this.result.b.b5],
          result_c: [this.result.c.c1,this.result.c.c2,this.result.c.c3,this.result.c.c4,this.result.c.c5],
          result_d: [this.result.d.d1,this.result.d.d2,this.result.d.d3,this.result.d.d4,this.result.d.d5],
          result_e: [this.result.e.e1,this.result.e.e2,this.result.e.e3,this.result.e.e4,this.result.e.e5],
          result_lo:[this.result.a.a1,this.result.b.b1,this.result.c.c1,this.result.d.d1,this.result.e.e1,
                     this.result.a.a2,this.result.b.b2,this.result.c.c2,this.result.d.d2,this.result.e.e2,
                     this.result.a.a3,this.result.b.b3,this.result.c.c3,this.result.d.d3,this.result.e.e3,
                     this.result.a.a4,this.result.b.b4,this.result.c.c4,this.result.d.d4,this.result.e.e4,
                     this.result.a.a5,this.result.b.b5,this.result.c.c5,this.result.d.d5,this.result.e.e5]
        }
        this.$emit("submitResult",dataResult);
        this.dialogSetResult = false;
      }
    },
    cancelResult(){
      let dataResult = {
        result_id: this.result_id
      };
      this.$emit("cancelResult",dataResult);
      this.dialogSetResult = false;
    },
    cancelSelectResult(){
      this.dialogSelectResult = false;
    },
    ...mapMutations("$_reverseLotvn", ["LOADING", "LOADINGRESULT"]),
  }
}
</script>

<style scoped>
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
.allow:hover {
  color: gold;
  font-weight: bolder;
    font-size: 29px;
    margin-top: -21px;

}
.selectp1:hover {
  cursor: pointer;
}
.selectp2:hover {
  cursor: pointer;
}
.selectp3:hover {
  cursor: pointer;
}
.cir-shadow{
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.btn-height{
  height: 100px!important;
  width: 100px!important;
}
.selectResult:hover {
  cursor: pointer;
}
.tbl-reverse {
  border: solid #0c1830;
}
</style>

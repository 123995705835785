import kenoSetting from '@/modules/slotMachineSetting/kenoSetting';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/keno-setting',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'kenoSetting',
				component: kenoSetting
			}
		]
	}
];

export default routes;

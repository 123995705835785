<template>
  <div>
    <v-app class="bgImage">
      <v-main>
        <v-container v-if="isOTPShow == true">
            <div class="otp-layout" style="margin-top: 30px;">
            <div class="otp-label">INPUT OTP CODE</div>
            <v-otp-input class="justify-content-center" ref="otpInput" inputClasses="otp-input"
                :numInputs="6" :is-input-num="true" separator="-" :shouldAutoFocus="true"
                @on-complete="handleOnComplete" @on-change="handleOnChange" />
            <div v-if="isOTPError" class="otp-error">{{ otpErrorMgs }}</div>
          </div>
        </v-container>
        <v-container v-else fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm10>
              <v-card class="elevation-15 formLoginMobile my-auto">
                <v-row class="ma-0">
                  <v-flex xs12 class="position-relative">
                    <div class="formLogin-bg"></div>
                    <v-img src="images/logo.png" class="logo w-25 mx-auto"></v-img>
                    <div class="empty-space"></div>
                  </v-flex>
                  <v-flex xs12 class="position-relative py-5 px-2">
                    <v-form ref="form" lazy-validation @submit.prevent="login" class="login-style">

                      <div class="upper-case text-center justify-center font-weight-bold h2 text-white pt-3">
                        {{ websiteName }}
                      </div>
                      <v-card-text style="padding: 2px 16px !important">
                          <v-text-field style="color: #ffffff !important" dense class="velo1 my-5" placeholder="Username" v-model="user.user_name"
                            :rules="rules.required" required type="text" autocomplete="none" solo>

                            <template v-slot:prepend-inner style="z-index: 100000;">
                              <v-icon color="black" class="icon-form-login">mdi-account-outline
                              </v-icon>
                            </template>
                          </v-text-field>
                        <v-text-field v-model="user.password" placeholder="Password" class="velo1 my-5" dense :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          " :rules="rules.required" :type="showPassword ? 'text' : 'password'" name="input-10-1"
                          counter autocomplete="new-password" @click:append="showPassword = !showPassword"
                          style="color: #ffffff !important" solo >
                          <template v-slot:prepend-inner>
                            <v-icon color="black" class="icon-form-login">mdi-lock-outline
                            </v-icon>
                          </template>
                        </v-text-field>
                        <label style="color: red;">{{errorMessage}}</label>
                      </v-card-text>
                      <v-card-actions>
                        <v-col class="col-4 pa-0 pt-0 mx-auto">
                          <v-btn :loading="loading" type="submit" :disabled="loading" block outlined color="#336699"
                            class="text-center justify-center font-weight-bolder submit-btn-login button-login"
                            style="border-radius: 5px; height:35px!important;">
                            {{ $t("title.login") }}
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-form>
                  </v-flex>
                </v-row>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
  
<script>
import "@/assets/css/login1.css";
import { mapActions, mapMutations } from "vuex";
export default {
    props: ['passUser', 'passValid', 'passLoading', 'passLanguage'],
    data() {
        return {
            rules: {
                required: [(value) => !!value || 'Required.'],
                min: v => (v && 0 !== v.length >= 6) || 'Min 6 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            validate: false,
            formLogo: '/images/logo.png',
            isShowText: false,
            user: {
                user_name: null,
                password: null,
                app_id: null
            },
            loading: false,
            error: false,
            isOTPShow: false,
            tmpChannelUserId: null,
            show: false,
            isOTPError: false,
            otpErrorMgs: "Wrong OTP",
            tempChannelUser: null,
            description: "",
            websiteName:"SBC2888 ADMIN",
            showPassword: false,
            errorMessage: ""
        }
    },
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async handleOnComplete(value) {
            try {
                let otpData = {
                    user_id: this.tmpChannelUserId,
                    otp: value
                }
                const response = await this.fetchVerifyOtp(otpData)
                if (response.data.code) {
                    //success
                    this.$cookies.set("token", response.data.data.token);
                    this.$cookies.set("channel-type", 1);
                    this.$cookies.set("local", 'kh');
                    this.$cookies.set("user", this.tempChannelUser);
                    this.$router.push({ path: "/dashboard" });
                } else {
                    this.$refs.otpInput.clearInput();
                    this.otpErrorMgs = response.data.message.descriptions;
                    this.isOTPError = true;
                }

            } catch (error) {
                this.$refs.otpInput.clearInput();
                this.otpErrorMgs = error.message;
                this.isOTPError = true;
            }
        },
        handleOnChange() {
            this.isOTPError = false;
        },
        async login() {
         
            if (this.$refs.form.validate()) {
                try {
                    this.loading = true;
                    this.user.app_id = process.env.VUE_APP_APP_ID;
                    console.log("app-id", this.user.app_id);
                    const response = await this.fetchLogIn(this.user);
                    if (response.data.code
                        //condition dnt let user role player login in to admin system
                        && response.data.data.user.role != "61829df5e64b1b1768832700"
                        && response.data.data.user.role != "61829dfee64b1b1768832709"
                        && response.data.data.user.role != "61829e05e64b1b1768832712") {
                        const { token, uuid, user } = response.data.data;
                        if(user.role === "612a3fcc1a1fcead8871e822"){
                          this.UPDATE_EYE_IS_ON(false);
                        }else{
                          this.UPDATE_EYE_IS_ON(true);
                        }
                        this.$cookies.set("sbcad_sub_index", -1);
                        this.$cookies.set("sbcad_submenu_index", -1);
                        if (token == "") {
                            this.$cookies.set("uuid", uuid);
                            this.tempChannelUser = user
                            this.tmpChannelUserId = user._id
                            this.isOTPShow = true
                        } else {
                            let rolee = user.role
                            this.$cookies.set("token", token);
                            this.$cookies.set("user_role", rolee);
                            this.$cookies.set("channel-type", 1);
                            this.$cookies.set("menu_selected", '611fde700734048a0481201e');
                            this.$cookies.set("local", 'kh');
                            this.$cookies.set("user", user);
                            if(response.data.data.user.role == "6191dc644825e6f57ffa231f" || response.data.data.user.role == "6191dce94825e65937fa2340" || response.data.data.user.role == "6424080f50b77c6cf890ab11" || response.data.data.user.role == "6472bfce1ba4b8a1604940c2"){
                                this.$router.push({ path: "/userSubAgent" });
                            }else if(response.data.data.user._id == "647b3f7ef5113a5456696255"){
                                this.$router.push({ path: "/dashboard" });
                            }else if(response.data.data.user.role =="612a40a51a1fcead8871e833"){
                              this.$router.push({ path: "/userCompanyUnderManager" });
                            }else{
                                this.$router.push({ path: "/users" });
                            }
                        }

                    } else {
                        this.error = true;
                        this.description = response.data.message.descriptions;
                        this.errorMessage = response.data.message.descriptions;
                    }
                } catch (error) {
                    this.error = true;
                    return error;
                } finally {
                    this.loading = false;
                }
            }
        },
        ...mapActions("$_auth", ["fetchLogIn", "fetchVerifyOtp"]),
        ...mapMutations("$_modules", ["UPDATE_EYE_IS_ON"]),
        _changeLanguage(language) {
            this.$emit("changeLanguage", language);
        }
    }
}
</script>
  
<style>
.bgImage {
  background-image: url(*/../../images/login-page/login_background_pc.jpg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: #e3e3e3 !important;
}

.bg {
  background-color: #6699cc !important;
}

/* @media (max-width: 480px) {
    .screenMobile {
      width: 400px;
      padding: 0 25px 0 25px;
      margin-left: -2px !important;
      justify-content: center !important;
    }
  
    .noImageMobile {
      display: none !important;
    }
  } */

.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: black !important;
  border: none !important;
}
</style>
<style>
.mdi-eye-off {
  color: black !important;
  background: #cccccc !important;
  padding: 8.5px 15px !important;
  padding-right: 5px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.mdi-eye {
  color: black !important;
  background: #cccccc !important;
  padding: 8.5px 15px !important;
  padding-right: 5px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.velo1 .v-text-field__slot {
  background: #cccccc !important;
  padding: 6px;

}
</style>
<style scoped>
.container {
  min-height: 90%;
}
.otp-layout {
    border: 1px solid hsla(0, 0%, 100%, 0.05);
    border-radius: 4px;
    padding: 20px;
    margin: auto;
    background-color: white;
    width: fit-content;
    height: fit-content;
}
.otp-input {
    width: 60px !important;
}

.otp-layout {
    position: absolute !important;
}
</style>
  
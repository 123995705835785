<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            :headers="headers"
            :items="getStatement ? getStatement.object : []"
            :footer-props="footerProps"
            item-key="_id"
            class=" table table-striped"
            id="table-1"
            :server-items-length="getStatement ? getStatement.count : null"
            style="font-family: 'khmer mef1'"
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            hide-default-header
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            mobile-breakpoint="0"
          >
          <!-- <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head._id">
              {{ head.text }}
            </th>
          </template> -->
            <template v-slot:top>
              <Header
                :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
              />
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-btn :color="item.is_active ? 'success' : 'error'">
                  {{ item.is_active ? "Active" : "Inactive" }}
                </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ActionComponents
                :darkmode="darkmode"
                :item="item"
                :dataDelete="getDeleteItems"
                :params="data"
                :user-permission="userAccessPermission"
              />
            </template>
          </v-data-table>
          <Dialog
            :darkmode="darkmode"
            :loading="loading"
            :playerWhiteListData="getCurrenBannerData"
            :dialog="getPopUp"
            :is-validate="valid"
            v-on:submit="handleSubmit"
            v-on:onCloseDialog="closeDialogCreate"
          />
          <v-dialog v-model="suspendDialog" persistent max-width="500px">
            <v-card >
              <v-card-title class="text-h5 py-5">
              do you want to this Player ?
              </v-card-title>
              <v-card-actions>
                <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogSuspend">Cancel</v-btn>
                <v-spacer></v-spacer>
                <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
                <v-btn  class="ma-2 white--text" :color="selectedDealer && selectedDealer.is_active ?  'error':'success'"
                @click="_updateDealerSuspendStatus()">{{selectedDealer && selectedDealer.is_active ? "Inactive Channel" :"Active Channel"}}</v-btn>
                
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["user_name","_id"],
      search: "",
    },
    selectedDealer:[],
    suspendDialog : false,
    waiting:false,
    dialog: false,
    loading: false,
    baseUrl: process.env.VUE_APP_API_REPORT_URL
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("input.user_name"), value: "user_name" },
        { text: this.$t("input.app_name"), value: "app" },
        { text: this.$t("input.amount_min"), value: "amount_min" },
        { text: this.$t("input.amount_max"), value: "amount_max" },
        { text: this.$t("input.betting_frequency_1"), value: "betting_frequency" },
        { text: this.$t("header.channel_type"), value: "channel_type" },
        { text: this.$t("input.created_date"), value: "createdAt" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_playerWhiteList", [
      "getAllBanner",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getCurrenBannerData",
      "getStatement",
      "getCountStatement",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit(payload) {
      this.loading = true;
      let data = {
        user_id: payload.user_id,
        amount_min: parseInt(payload.amount_min),
        amount_max: parseInt(payload.amount_max),
        betting_frequency: parseInt(payload.betting_frequency),
        channel_type: parseInt(payload.channel_type)
      }
      await this.createBannerData({ vm: this, data: data });
      this.fetchPopUp(false)
      await this.getData();
      this.loading = false;
    },
    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListStatement(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    closeDialogCreate() {
      this.getCurrentConfigUserData = {};
      this.fetchPopUp(false);
    },
    async _updateDealerSuspendStatus(){
      this.waiting = true;
      let data = {
        is_active : !this.selectedDealer.is_active,
        dealerId: this.selectedDealer._id// channel_type : this.channel_type
      }
      await this.$request
        .post({
          url: "/dealer/suspendDealer",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        });
    },
    // _openDialogSuspend(item){
    //   this.selectedDealer = item
    //   this.suspendDialog = true
    // },
    _closeDialogSuspend(){
      this.selectedDealer = []
      this.suspendDialog = false
    },

    ...mapMutations("$_playerWhiteList", ["LIST_STATEMENT","COUNT_STATEMENT","UPDATE_BANNER_DATA", "DELETE_ITEMS"]),
    ...mapActions("$_playerWhiteList", [
      "fetchPopUp",
      "createBannerData",
      "fetchListStatement",
      "updateLimit",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
   // this.UPDATE_USERS([]);
    //this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

const UPDATE_ROLES = (state, data) => {
    state.roles = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentRole = {
            role_name: null,
            desrc: null,
            menu: []
        }
        state.permissionSelection = []
    }
    state.popUp = data;
};

const UPDATE_CURRENT_ROLE = (state, data) => {
    state.currentRole = data;
};

const UPDATE_SELECT_PERMISSION = (state, data) => {
    const format = []
    if (data.length > 0) {
        for (let i in data) {
            if (data[i].permissions.length > 0) {
                for (let j in data[i].permissions) {
                    format.push({
                        id: data[i].menu_id + '-' + data[i].permissions[j]
                    })
                }
            }
        }
    }
    console.log(format);
    state.permissionSelection = format
}

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
}

const UPDATE_ROLES_WITHOUT_BODY = (state, data) => {
    state.roleWithoutBody = data
}

const UPDATE_ROLES_DEPEND_ON_USER = (state, data) => {
    state.allRolesDependOnUser = data
}

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

export default {
    UPDATE_LOADING,
    UPDATE_ROLES,
    UPDATE_POPUP,
    UPDATE_CURRENT_ROLE,
    DELETE_ITEMS,
    UPDATE_ROLES_WITHOUT_BODY,
    UPDATE_SELECT_PERMISSION,
    UPDATE_ROLES_DEPEND_ON_USER
};

const headers = [
    //{ text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'No',
        align: 'start',
        value: 'no',
    },
    { text: 'App Name', value: 'app_id' },
    { text: 'Fight No', value: 'fight_no' },
    { text: 'Group No', value: 'group_no' },
    { text: 'Large', value: 'amount_big' },
    { text: 'Small', value: 'amount_small' },
    { text: '1', value: 'amount_one' },
    { text: '2', value: 'amount_two' },
    { text: '3', value: 'amount_three' },
    { text: '4', value: 'amount_four' },
    { text: '5', value: 'amount_five' },
    { text: 'Cast', value: 'cast' },
    { text: 'Amount Win', value: 'amount_win' },
    // { text: 'VIP option', value: '' },
];

export default headers;
<template>
  <div>
    <!-- sort-by="user_name" -->
    <!-- <div class="section-header"></div> -->
    <!-- <div class="section-body"> -->
      <div class="card">
        <div class="card-body">
          <div class="section-header-breadcrumb pl-5">
            <div class="breadcrumb-item">
              <b style="color: #1976d2;">History By Fight Dragon</b>
            </div>
          </div>
          <!-- <v-toolbar-title class=" text-h5 uppercase pl-5 ">Controller Dragon Report</v-toolbar-title> -->
          <div class="table-responsive" style="text-align: center">
            <v-data-table
              :headers="headers"
              :items="getStatement ? getStatement.object : []"
              :server-items-length="getStatement.objectCount.count"
              item-key="_id._id"
              class=" table table-striped"
              id="table-1"
              style="font-family: 'khmer mef1'"
              :footer-props="footerProps"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              hide-default-header
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :user-permission="userAccessPermission"
                  :user="user"
                  :time="time"
                  @child-checkbox="checkboxValue"
                />
              </template>

              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td>{{ getStatement.object.indexOf(item) + 1 }}</td>
                  <td>{{ item.app_id }}</td>
                  <td>{{ item.fight_no }}</td>
                  <td>{{ item.group_no }}</td>
                  <td>{{ currencyFormat(item.amount_tiger) }}</td>
                  <td>{{ currencyFormat(item.amount_tie) }}</td>
                  <td>{{ currencyFormat(item.amount_dragon) }}</td>
                  <td>{{ currencyFormat(item.cast) }}</td>
                  <td :style="
                    item.amount_win < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  ">{{ currencyFormat(item.amount_win) }}</td>
                </tr>
              </template>
              <template slot="body.append">
                <tr>
                  <td :colspan="3" class="text-xs-center pl-4 py-2">
                    {{ $t("title.total") }}
                  </td>
                  <td></td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getStatement.objectCount.amount_tiger
                        ? currencyFormat(getStatement.objectCount.amount_tiger)
                        : 0
                    }}
                  </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getStatement.objectCount.amount_tie
                        ? currencyFormat(getStatement.objectCount.amount_tie)
                        : 0
                    }}
                  </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getStatement.objectCount.amount_dragon
                        ? currencyFormat(getStatement.objectCount.amount_dragon)
                        : 0
                    }}
                  </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    
                  </td>
                  <td
                    class="
                      text-xs-center
                      pl-4
                      total-style
                      font-weight-bold
                      py-2
                    "
                    :style="
                      getStatement.objectCount.amount_win < 0
                        ? 'color:#BA2D4A'
                        : 'color:#39afd6'
                    "
                  >
                    {{
                      getStatement.objectCount.amount_win
                        ? currencyFormat(getStatement.objectCount.amount_win)
                        : 0
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          
          
            <!-- <div class="text-center pt-2">
              <v-pagination
                v-model="data.page"
                :length="
                  getCountStatement
                    ? getCountStatement.count % data.rowsPerPage != 0
                      ? getCountStatement.count / data.rowsPerPage + 1
                      : getCountStatement.count / data.rowsPerPage
                    : 0
                "
              ></v-pagination>
              <v-text-field
                :value="data.rowsPerPage"
                label="Items per page"
                type="number"
                min="-1"
                max="15"
                @input="data.rowsPerPage = parseInt($event, 10)"
              ></v-text-field>
            </div> -->
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";

export default {
  props: ["date"],
  components: {
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descending: true,
      sortBy: "fight_no",
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "fight_no"
      ],
      search: "",
      channelType: 8,
      currencyType: 1,
      groupNo: "",
      fightNo: "",
    },
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    listDetailReport: [],
    check: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],

    totalItems: 0,
    time: null,
    dialogDetailReport: false,
    selectedUser: [],
    item: [],
    // configuser
    dialogConfig: false,
    player: [],
    ipDialog: false,
    onCloseDialog: false,
    passLimitConfig: {},
    selectedPlayer: {},
    configUserData: {},
  }),
  watch: {
    user: function () {
      this.getData();
    },
    data: function () {
      this.getData();
    },
    getStatement: function () {},
  },
  computed: {
    reportUrl: function () {
      return "getViewTopRangeWinnerApiBranch";
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_historyByFightDragonReport", [
      "getStatement",
      "getCountStatement",
      "getLoading",
      "getParentLimitData",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    moment,
    // Gets the checkbox information from the child component
    checkboxValue: function (params) {
        (this.data.groupNo = params.groupNo ? params.groupNo : "");
        (this.data.fightNo = params.fightNo ? params.fightNo : "");
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    async getData() {
      try {
        let channel = parseInt(this.$cookies.get("channel-type"));
        let currency = parseInt(this.$cookies.get("currency-type"));
        if(channel == 8 || channel == 9 || channel == 19){
          this.data.channelType = parseInt(channel);
        }
        if(currency == 1 || currency == 2 || currency == 3 || currency == 4 || currency == 5){
          this.data.currencyType = parseInt(currency);
        }
        this.loader = true;
        let preData = {
          data: this.data,
          user_id: this.user._id,
          reportUrl: this.reportUrl,
        };
        // console.log('pre_data:',preData);
        this.checkUserPermission("VIEW")
          ? await this.fetchListStatement(preData)
          : [];
        // console.log(this.getCountStatement)
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    closeConfigUserDialog() {
      this.dialogConfig = false;
    },
    ...mapMutations("$_historyByFightDragonReport", [
      "LIST_STATEMENT",
      "COUNT-STATEMENT",
      "LIST_CHANNEL_TYPE",
      "PARENT_LIMIT_DATA",
      "UPDATE_POPUP",
    ]),
    ...mapActions("$_historyByFightDragonReport", [
      "fetchListStatement",
      "fetchChannelType",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}

.tab-filter {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}
.amount-style {
  text-decoration: underline !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    apps: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentApp: {
        name: null,
        is_enable: true,
        is_base: false,
        api_player_link: null,
        api_admin_link: null,
        d_company_id: null,
        d_senior_id: null,
        d_master_id: null,
        d_agent_id: null,
        r_company_id: null,
        r_senior_id: null,
        r_master_id: null,
        r_agent_id: null,
        b_company_id: null,
        b_senior_id: null,
        b_master_id: null,
        b_agent_id: null,
    },
    deleteItems: [],
    listCompany:[],
    listTreeMenuByApp:[],
    treeMenuSelection: [],
    listSenior:[],
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

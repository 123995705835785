<template>
  <v-card >
    <form-channel
      :objKdPayout="objKdPayout"
      :channel="currentChannel.currentChannel"
      :objCoTypeOfBetting="objCoTypeOfBetting"
      :socketChannelType="dataFromSocket.type"
      :socketChannelCountdown="dataFromSocket.countDown"
      :selectResult="selectResult"
      v-on:submitResult="handleSubmitResult"
      v-on:submitUpdateStatus="handleUpdateChanelStatus"
      v-on:submitUpdateChanel="handleSubmitUpdateChanel"
      v-on:submitResetResult="handleResetResult"
      v-on:submitFixPayout="handleSubmitFixPayout"
      
    />
    <table-result
      v-on:submitResult="handleSubmitResult"
      :channel="currentChannel.currentChannel"
      :objCoTypeOfBetting="objCoTypeOfBetting"
      :objCoResult="objCoResult"
      :loadingResult="loadingResult"
      :objSummaryCoResult="objSummaryCoResult"
    />
  </v-card>

</template>

<script>
import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    formChannel,
    tableResult,
  },
  data: () => {
    return {
      dataFromSocket: [],
      selectResult: {
        order: null,
        _id: null,
      },
      loading: false,
      
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_han2Ch20Channel", {
      currentChannel: "getCurrentChannel",
      objCoTypeOfBetting: "getBtnResults",
      objSummaryCoResult: "getSummaryResult",
      objCoResult: "getResultTable",
      objKdPayout: "getKdPayout",
      loadingResult: "getLoadingResult",
    }),
  },

  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
      await this.fetchBtnResult();
    }
  },
  watch: {
    objCoResult: function() {},
    currentChannel: function() {},
    loadingResult: function() {},
  },
  async mounted() {},
  methods: {
    async handleResetResult() {
      await this.resetResult();
    },
    async handleUpdateChanelStatus() {
      let submitData;
      if (this.objKdPayout) {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          meron_percentage: parseInt(this.objKdPayout.meron_percentage),
          wala_percentage: parseInt(this.objKdPayout.wala_percentage),
        };
      } else {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
        };
      }
      await this.UpdateChanelStatus({ vm: this, data: submitData });
    },
    async handleSubmitResult(data) {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        result_id_1 : data.result_id_1,
        result_id_2 : data.result_id_2,
        result_id_3 : data.result_id_3,
      };
      await this.createResult({ vm: this, data: submitData });
    },
    async handleSubmitUpdateChanel(dataUpdateChanel) {
      delete dataUpdateChanel.enable;
      await this.updateChannel({ vm: this, data: dataUpdateChanel });
    },
    async handleSubmitFixPayout(dataFixPayout){
      await this.updateFixedPayout({ vm: this, data: dataFixPayout });
    },
    ...mapMutations("$_han2Ch20Channel", [
      "UPDATE_CHANNEL",
      "SET_RESULT",
      "SUMMARY_RESULT",
      "RESULT_TABLE",
      "LOADINGRESULT",
      "UPDATE_KDPAYOUT",
    ]),
    ...mapActions("$_han2Ch20Channel", [
      "fetchCurrentChannel",
      "fetchBtnResult",
      "createResult",
      "updateChannel",
      "UpdateChanelStatus",
      "resetResult",
      "updateFixedPayout"
    ]),
  },
};
</script>

<style lang="scss" scoped></style>

/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListUsers = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    if(data.search){
        data.sortBy = 'user_name';
        data.descending = false;
    }else{
        data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
        data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    }
    
    const response = await request.post({
        url: 'users/getAllByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_USERS', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};


const createUser = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'users',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const updateUser = async ({commit}, {vm, data}) => {
    const response = await request.put({
        url: 'users/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
const updateConfigUser = async ({ commit }, { vm, d }) => {
    let data = d;
    const response = await request.post({
        url: 'configUser/update/' + d._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const deleteUser = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'users/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const changePassword = async ({commit}, {vm, data}) => {
    const response = await request.put({
        url: 'users/changePassword/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
}

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentUser = async ({ commit }, payload) => {
    commit('UPDATE_CURRENT_USER', payload);
};
const fetchHistoryWithdraw = async({commit}, data) => {
    const response = await request.post({
        url: 'users/getAllWithdraw',
        data
    });
    commit('UPDATE_VIEW_WITHDRAW', response.data.data)
}
const fetchHistoryDeposit = async({commit}, data) => {
    const response = await request.post({
        url: 'users/getAllDeposit',
        data
    });
    commit('UPDATE_VIEW_DEPOSIT', response.data.data)
}

const withDraw = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'users/Withdraw',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
}
const deposit = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'users/deposit',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
}
const fetchListCompany = async ({commit}) => {
    const response = await request.get({
        url: 'users/getListUserCompany'
    });
    if (response.data.code) {
        commit('LIST_COMPANY', response.data.data);
    }
};

const fetchListSenior = async ({commit}) => {
    const response = await request.get({
        url: 'users/getAllSenior'
    });
    if (response.data.code) {
        commit('LIST_SENIOR', response.data.data.object);
    }
};
const fetchListApp = async ({commit}) => {
    const response = await request.get({
        url: 'users/getApp'
    });
    if (response.data.code) {
        commit('LIST_APP', response.data.data.object);
    }
};
const fetchListMenuByApp = async ({commit},datas) => {
    let data={
        app_id:datas.app_id,
        role_id:datas.role_id,
        user_id:datas._id
    }
    const response = await request.post({
        url: 'app-menu/getMenuByApp',
        data: data
    });
    console.log("response.data.data",response.data.data);
    if (response.data.code) {
        commit('LIST_MENU_BY_APP', response.data.data);
    }
};
const fetchListTreeMenuByApp = async ({commit},data) => {
    let appId = {
        app_id:data.app_id
    };
    const response = await request.post({
        url: 'app-menu/getTreeMenuByCompany',
        data:appId
    });
    if (response.data.code) {
        commit('LIST_TREE_MENU_BY_APP', response.data.data);
    }
};
const fetchListTreeMenuSelections = async ({commit},datas) => {
    let data={
        app_id:datas.app_id
    }
    const response = await request.post({
        url: 'app-menu/getTreeMenuSelected',
        data:data
    });
    if (response.data.code) {
        commit('UPDATE_SELECT_TREE_MENU', response.data.data);
    }
};
const fetchListTreeMenuSelectionsOnEdit = async ({commit},datas) => {
    
    let data={
        app_id:datas.app_id,
        role_id:datas.role_id,
        user_id:datas._id
    }
    // console.log("data2",data);
    const response = await request.post({
        url: 'app-menu/getTreeMenuSelected',
        data:data
    });
    if (response.data.code) {
        commit('UPDATE_SELECT_TREE_MENU', response.data.data);
    }
};

const fetchCurrentBankUser = async({ commit }, data) => {
    let objdata = {user_id: data}
    const response = await request.post({
        url: 'bankAccount/getBankAccByUser',
        data: objdata
    });
    if (response.data.code) {
        commit('UPDATE_BANK_USER', response.data.data.object);
    }
};
const fetchPopUpBankUser = async ({commit}, payload) => {
    commit('UPDATE_POPUP_BANK_USER', payload);
};



const fetchUserChild = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'users/getAllByUserId',
        data
    });
    // if (response.data.code) {
    //     vm.$toastr.s(`${response.data.message.descriptions}`)
    //     commit('UPDATE_POPUP', false)
    // } else vm.$toastr.e(`${response.data.message.descriptions}`)
}

export default {
    fetchListUsers,
    fetchPopUp,
    fetchPopUpBankUser,
    createUser,
    fetchCurrentUser,
    updateUser,
    deleteUser,
    changePassword,
    fetchUserChild,
    fetchHistoryWithdraw,
    fetchHistoryDeposit,
    withDraw,
    deposit,
    updateConfigUser,
    fetchListCompany,
    fetchListSenior,
    fetchListApp,
    fetchCurrentBankUser,
    fetchListMenuByApp,
    fetchListTreeMenuByApp,
    fetchListTreeMenuSelections,
    fetchListTreeMenuSelectionsOnEdit
    // balanceUser
};

<template>
  <div>
    <div class="section-header" hidden>
      <!-- <h1>User Page</h1> -->
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="/">Dashboard</a>
        </div>
        <div class="breadcrumb-item">Senior Statement KM</div>
      </div>
    </div>
    <!-- <div class="section-body"> -->
      <div class="card">
        <div class="card-body">
          <div class="section-header-breadcrumb pl-5">
            <div class="breadcrumb-item">
              <b style="color: #1976d2;">Senior Statement RIEL</b>
            </div>
          </div>
          <!-- <v-toolbar-title class=" text-h5 uppercase pl-5 ">Senior Statement RIEL</v-toolbar-title> -->
          <div class="table table-responsive" style="text-align: center">
            <v-data-table
              :headers="headers"
              :items="getStatement ? getStatement.object : []"
              :server-items-length="
                getCountStatement ? parseInt(getCountStatement.count) : null
              "
              item-key="_id._id"
              class=" table table-striped"
              id="table-1"
              style="font-family: 'khmer mef1'"
              :footer-props="footerProps"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              hide-default-header
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              :loading="getLoading"
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :startTime="startTime"
                  :endTime="endTime"
                  :user="user"
                  :time="time"
                  :statusTimeOrigin="statusTimeOrigin"
                  @child-checkbox="checkboxValue"
                />

                <!--button date filter-->
                <div class="row">
                  <div
                    class="col-12 col-md-12 col-sm-12"
                    style="width: 100%; text-align: right; margin-bottom: 15px"
                  >
                    <v-btn
                      style="display: inline-block"
                      v-for="(item, i) in defaultFilterDates"
                      small
                      color="primary"
                      class="mx-4 mb-4 btn btn-primary"
                      :key="i"
                      @click="selectDate(item)"
                    >
                      <span style="color: white">{{ item.name }}</span>
                    </v-btn>
                  </div>
                </div>
              </template>

              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td>
                    {{
                      getStatement.object.indexOf(item) +
                      (data.page - 1) * data.rowsPerPage +
                      1
                    }}
                  </td>
                  <td>{{ item._id.app_id }}</td>
                  <td>{{ item._id.com }}</td>
                  <td>{{ item._id.admin }}</td>
                  <td>{{ currencyFormat(item._id.admin_balance) }}</td>
                  <td class="amount-bet-color">
                    {{ currencyFormat(item.amount) }}
                  </td>
                  <!-- <td class="win-color">{{ currencyFormat(item.amount_win) }}</td> -->
                  <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">
                    {{ currencyFormat(item.amount_win) }}
                  </td>
                </tr>
              </template>
              <template slot="body.append">
                <tr style="background-color: #faffe5; font-weight: bold;">
                  <td :colspan="4" class="text-xs-center pl-4 py-2">
                    {{ $t("title.total") }}
                  </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                  {{
                    getCountStatement.balance
                      ? currencyFormat(getCountStatement.balance)
                      : 0
                  }}
                </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getCountStatement.amount
                        ? currencyFormat(getCountStatement.amount)
                        : 0
                    }}
                  </td>
                  <td
                    class="
                      text-xs-center
                      pl-4
                      total-style
                      font-weight-bold
                      py-2
                    "
                    :style="
                      getCountStatement.amount_win < 0
                        ? 'color:#BA2D4A'
                        : 'color:#39afd6'
                    "
                  >
                    {{
                      getCountStatement.amount_win
                        ? currencyFormat(getCountStatement.amount_win)
                        : 0
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <!-- <div class="text-center pt-2">
            <v-pagination
              v-model="data.page"
              :length="
                getCountStatement
                  ? getCountStatement.count % data.rowsPerPage != 0
                    ? getCountStatement.count / data.rowsPerPage + 1
                    : getCountStatement.count / data.rowsPerPage
                  : 0
              "
            ></v-pagination>
            <v-text-field
              :value="data.rowsPerPage"
              label="Items per page"
              type="number"
              min="-1"
              max="15"
              @input="data.rowsPerPage = parseInt($event, 10)"
            ></v-text-field>
          </div> -->
          
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";

export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    startTime: "00:00",
    endTime: "23:59",
    data: {
      descendingFormat: [true],
      sortByFormat: ["amount_win"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "_id.com_id",
        "_id.com",
        "_id.con_balance",
        "_id.admin_id",
        "_id.admin",
        "_id.admin_balance",
        "amount",
        "amount_win",
      ],
      search: "",
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
    },
    appId : 0,
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    check: {
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      startTime: "00:00",
      endTime: "23:59",
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        nameKh: "ថ្ងៃនេះ",
        active: false,
      },
      {
        name: "Yesterday",
        nameKh: "ម្សិលមិញ",
        active: false,
      },
      {
        name: "This Week",
        nameKh: "សប្តាហ៍នេះ",
        active: false,
      },
      {
        name: "Last Week",
        nameKh: "សប្តាហ៍មុន",
        active: false,
      },
      {
        name: "This Month",
        nameKh: "ខែនេះ",
        active: false,
      },
      {
        name: "Last Month",
        nameKh: "ខែមុន",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
    statusTimeOrigin: false,
  }),
  watch: {
    user: function () {
      this.getData();
    },
    data: function () {
      this.getData();
    },
    getStatement: function () {},
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "senior";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "senior";
        else if (
          this.user.role_name == "SENIOR-D" ||
          this.user.role_name == "SUB-SENIOR-D"
        )
          return "senior";
        else if (
          this.user.role_name == "MASTER-D" ||
          this.user.role_name == "SUB-MASTER-D"
        )
          return "senior";
        else if (
          this.user.role_name == "AGENT-D" ||
          this.user.role_name == "SUB-AGENT-D"
        )
          return "senior";
        else return null;
      } else return null;
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_ocStatementSummarySeniorKm", [
      "getStatement",
      "getCountStatement",
      "getLoading",
    ]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    moment,
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment().subtract(1, "days").format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment().startOf("isoweek").format(format);
          this.endDate = this.moment().endOf("isoWeek").format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate, startTime, endTime, statusTimeOrigin } = this;
      this.time = {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusTimeOrigin: statusTimeOrigin,
      };
      this.check = { startDate, endDate, startTime, endTime };
      this.data.startDate = startDate + "T00:00:00";
      this.data.endDate = endDate + "T23:59:59";
      this.data.statusTime = this.statusTime ? true : false;
      this.getData();
    },
    // Gets the checkbox information from the child component
    checkboxValue: function (params) {
      this.check = params;
      this.appId = params.appId;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
      this.data.statusTime = params.statusTime;
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    async getData() {
      try {
        this.loader = true;
        let preData = {
          data: this.data,
          appId:this.appId,
          reportUrl: this.reportUrl,
        };
        await this.fetchListStatement(preData);
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_ocStatementSummarySeniorKm", [
      "LIST_STATEMENT",
      "COUNT-STATEMENT",
    ]),
    ...mapActions("$_ocStatementSummarySeniorKm", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>

<template>
  <div class="card">
    <div class="card-body">
      <div class="section-header-breadcrumb" style="padding-bottom: 8px;">
        <div class="breadcrumb-item">
          <b style="color: #1976d2;">Promotion</b>
        </div>
      </div>
      <v-data-table
        style="font-family: 'khmer mef1'"
        class="elevation-1 table"
        item-key="_id"
        :headers="headers"
        :items="getSetting && getSetting.object ? getSetting.object : []"
        :footer-props="footerProps"
        :server-items-length="getSetting ? getSetting.objectCount : null"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        @input="itemDelete"
        :loading="getLoading"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :params="data"
            :user-permission="userAccessPermission"
          />
        </template>
      </v-data-table>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :setting="getCurrentSetting"
        :dialog="getPopUp"
        :is-validate.sync="valid"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="fetchPopUp(false)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    ActionComponents: () => import("./_components/action"),
    // Header: () => import("./_components/header.vue"),
    Dialog: () => import("./_components/dialog.vue"),
  },
  data: () => ({
    valid: true,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["title_en", "title_kh", "title_vn", "title_thai"],
      search: "",
      app_id: "",
    },
    loading: false,
    app_id:0,
  }),
  computed: {
    headers() {
      return [
        { text: "Title En", align: "start", value: "title_en" },
        { text: "Title Kh", align: "start", value: "title_kh" },
        { text: "Title Vn", align: "start", value: "title_vn" },
        { text: "Title Th", align: "start", value: "title_thai" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_promotion", [
      "getSetting",
      "getPopUp",
      "getCurrentSetting",
      "getDeleteItems",
      "getLoading",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit(payload) {
      this.loading = true;
      this.getCurrentSetting.selection = payload;
      console.log("payload",payload);
      if (!this.getCurrentSetting._id) {
        await this.createSetting({ vm: this, data: this.getCurrentSetting });
      } else {
        this.getCurrentSetting.channel_type = parseInt(this.getCurrentSetting.channel_type);
        console.log(this.getCurrentSetting);
        await this.updateSetting({ vm: this, data: this.getCurrentSetting });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.data.app_id = process.env.VUE_APP_APP_ID;
      this.checkUserPermission("VIEW")
        ? await this.fetchListSetting(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },
    ...mapMutations("$_promotion", ["DELETE_ITEMS", "UPDATE_SETTING"]),
    ...mapActions("$_promotion", [
      "fetchListSetting",
      "fetchPopUp",
      "createSetting",
      "updateSetting",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_SETTING([]);
    next();
  },
};
</script>

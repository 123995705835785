const UPDATE_APP = (state, data) => {
    state.apps = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentApp =  {
            name: null,
            is_enable: true,
            is_base: false,
            api_player_link: null,
            api_admin_link: null,
            d_company_id: null,
            d_senior_id: null,
            d_master_id: null,
            d_agent_id: null,
            r_company_id: null,
            r_senior_id: null,
            r_master_id: null,
            r_agent_id: null,
            b_company_id: null,
            b_senior_id: null,
            b_master_id: null,
            b_agent_id: null,
        }
    }
    state.popUp = data;
};

const UPDATE_CURRENT_APP = (state, data) => {
    state.currentApp = data;
};


const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
}
const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
const LIST_COMPANY = (state, data) => {
    state.listCompany = data;
};
const LIST_SENIOR = (state, data) => {
    state.listSenior = data;
};
const LIST_TREE_MENU_BY_APP = (state, data) => {
    state.listTreeMenuByApp = data;
};
const UPDATE_SELECT_TREE_MENU = (state, data) => {
    state.treeMenuSelection = data
}

export default {
    UPDATE_LOADING,
    UPDATE_APP,
    UPDATE_POPUP,
    UPDATE_CURRENT_APP,
    DELETE_ITEMS,
    LIST_COMPANY,
    LIST_SENIOR,
    LIST_TREE_MENU_BY_APP,
    UPDATE_SELECT_TREE_MENU
};

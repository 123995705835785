export default {
    search: 'Search',
    permission_name: 'Permission Name',
    role_name: 'Role Name',
    menu_name: 'Menu Name',
    description: 'Description',
    order: 'Order',
    route_name: 'Route Name',
    url_name: 'URL Name',
    icon: 'Icon',
    parent: 'Parent',
    permission: 'Permission',
    visible: 'Visible',
    user_name: 'Username',
    bank_account: 'Account Note',
    user_role: 'User Role',
    password: 'Password',
    total_bet_a_fight: 'កំណត់ការឈ្នះក្នុង១ដៃធំបំផុត',
    total_bet_win_a_day:'កំណត់ការឈ្នះក្នុង១ថ្ងៃធំបំផុត',
    user:"Select User",
    default_bet_a_fight:"Default Bet a Fight",
    default_bet_win_a_day:"Default Bet a Day",
    agent_bet_a_fight:"Set Limit to Agent for their Player In a Fight",
    agent_bet_win_a_day:"Set Limit to Agent for their Player In a Day",
    target :  "Target",
    percentage_meron :  "Percentage of Meron",
    percentage_wala :  "Percenntage of Wala",
    meron_name : "Name of Meron",
    wala_name : "Name of Wala",
    start_time :  "Start Time",
    end_time :  "End Time",
    meron : "Meron",
    child_role_balance: "Banlance of Child",
    child_role_balance_d: "Banlance of Child USD",
    child_role_balance_b: "Banlance of Child Baht",
    child_role_balance_r: "Banlance of Child RIEL",
    child_role_name: "Name Of Child",
    chose_type: "Choose Currency",
    bet_win_a_day: "Total Win a Day",
    bet_win_a_Fight: "Total Win a Match",
    enable:"Enable",
    listAllCompany:"Select Company",
    channel_type:"Select channel Type",
    startDate:"Start Date",
    endDate:"End Date",
    percentage_wala_min:"Min Wala(%)",
    percentage_wala_max:"Max Wala(%)",
    percentage_meron_min:"Min Meron(%)",
    percentage_meron_max:"Max Meron(%)",
    target_kh:"Target KH",
    target_usd:"target Usd",
    target_tb: "target TB",
    target_vn: "target VN",
    fight_no : "Fight no",
    percentage_wala_start:"Start Wala(%)",
    percentage_meron_start :"Start Meron(%)",
    amount_max:"Amount Max",
    amount_min:"Amount Min",
    listAllSenior:"Select Senior",
    percentage_vip:"Decrease Percentage",
    suspend:"Suspend",
    channel1 :"CO1",
    channel2 :"CO2",
    channel3 :"CO3",
    channel4 :"CO4",
    channel5 :"KL1",
    channel6 :"BA1",
    channel7 :"BA2",
    channel18 :"BA3",
    channel8 :"TD1",
    channel9 :"TD2",
    channel19 :"TD3",
    channel10 :"LO1",
    channel11 :"YU1",
    channel12 :"AP1",
    channel13 :"FT1",
    channel14 :"TS1",
    channel15 :"CO5",
    channel16 :"CO6",
    channel17 :"AP2",
    channel20 :"HAN2",
    channel21 :"HAN3",
    main_link: "Main Link",
    list_company: "List Company",
    api_player_link: "Selecet API Player link",
    api_admin_link: "Selecet API Admin link",
    list_company_d: "Selecet Company Dollar",
    list_senior_d: "Selecet Senior Dollar",
    list_master_d: "Selecet Master Dollar",
    list_agent_d: "Selecet Agent Dollar",
    list_company_r: "Selecet Company Riel",
    list_senior_r: "Selecet Senior Riel",
    list_master_r: "Selecet Master Riel",
    list_agent_r: "Selecet Agent Riel",
    list_company_b: "Selecet Company Baht",
    list_senior_b: "Selecet Senior Baht",
    list_master_b: "Selecet Master Baht",
    list_agent_b: "Selecet Agent Baht",
    app_name: "App Name",
    is_base: "Is Base",
    barcode: "Barcode",
    dealer_name: "Username",
    dealer_dob: "Date of birth",
    profile_image: "Profile Name",
    maintenance_id: "APP ID",
    is_maintenance: "Maintenance Status",
    betting_frequency: "កំណត់ចំនួនដងនៃការចាក់",
    betting_frequency_1: "Betting Frequencys",
    created_date: "Created Date",
    name: "Name",
    is_enable: "Is Enable",
    is_start: "Is Start",
    channel: "Channel Type",
    updatedAt: "Updated At",
    percentage: "Percentage",
    win_lose_type: "Win Lose Type",
    one_day: "1 Day",
    one_month: "1 Month",
    bank_name: "Bank Name",
    account_name: "Account Name",
    account_number: "Account Number",
    account_info: "Account Info",
    currency_type: "Currency Type",
    amount:"Amount",
    sender_note:"Sender Note",
    reciever_note:"Reciever Note",
    max_win: "Max Win",
    max_loose: "Max Loose",
    total_bet_win_a_day_lotto160: "Maximum win a day limit for Lotto (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lotto 160s)",
    total_bet_win_a_day_lotto100: "Maximum win a day limit for Lotto (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lotto 100s)",
    total_bet_win_a_day_lotto30: "Maximum win a day limit for Lotto (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lotto 30s)",
    total_bet_win_a_day_yuki160: "Maximum win a day limit for Yuki (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Yuki 160s)",
    total_bet_win_a_day_yuki100: "Maximum win a day limit for Yuki (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Yuki 100s)",
    total_bet_win_a_day_yuki30: "Maximum win a day limit for Yuki (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Yuki 30s)",
    total_bet_win_a_day_pp78160: "Maximum win a day limit for PP78 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lottery 160s)",
    total_bet_win_a_day_pp78100: "Maximum win a day limit for PP78 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lottery 100s)",
    total_bet_win_a_day_pp7830: "Maximum win a day limit for PP78 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lottery 30s)",
    total_bet_win_a_day_klaklouk60: "Maximum win a day limit for Klaklouk (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Klaklouk(60s))",
    total_bet_win_a_day_klaklouk25: "Maximum win a day limit for Klaklouk (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Klaklouk(25s))",
    total_bet_win_a_day_klaklouk15: "Maximum win a day limit for Klaklouk (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Klaklouk(15s))",
    total_bet_win_a_day_dragon60: "Maximum win a day limit for Dragon (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Dragon(60s))",
    total_bet_win_a_day_dragon25: "Maximum win a day limit for Dragon (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Dragon(25s))",
    total_bet_win_a_day_dragon15: "Maximum win a day limit for Dragon (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Dragon(15s))",
    total_bet_win_a_day_baccarat60: "Maximum win a day limit for Baccarat (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Baccarat(60s))",
    total_bet_win_a_day_baccarat25: "Maximum win a day limit for Baccarat (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Baccarat(25s))",
    total_bet_win_a_day_baccarat15: "Maximum win a day limit for Baccarat (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Baccarat(15s))",
    total_bet_win_a_day_doden60: "Maximum win a day limit for Doden (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Doden(60s))",
    total_bet_win_a_day_doden25: "Maximum win a day limit for Doden (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Doden(25s))",
    total_bet_win_a_day_doden15: "Maximum win a day limit for Doden (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Doden(15s))",
    total_bet_win_a_day_taixiu60: "Maximum win a day limit for Taixiu (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Taixiu(60s))",
    total_bet_win_a_day_taixiu25: "Maximum win a day limit for Taixiu (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Taixiu(25s))",
    total_bet_win_a_day_taixiu15: "Maximum win a day limit for Taixiu (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Taixiu(15s))",
    total_bet_win_a_day_sicbo60: "Maximum win a day limit for Sicbo (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Sicbo(60s))",
    total_bet_win_a_day_sicbo25: "Maximum win a day limit for Sicbo (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Sicbo(25s))",
    total_bet_win_a_day_sicbo15: "Maximum win a day limit for Sicbo (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Sicbo(15s))",
    total_bet_win_a_day_keno160: "Maximum win a day limit for Keno (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Keno 160s)",
    total_bet_win_a_day_keno100: "Maximum win a day limit for Keno (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Keno 100s)",
    total_bet_win_a_day_keno30: "Maximum win a day limit for Keno (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Keno 30s)",
    total_bet_win_a_day_lot3160: "Maximum win a day limit for Lotery3 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lotery3 160s)",
    total_bet_win_a_day_lot3100: "Maximum win a day limit for Lotery3 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lotery3 100s)",
    total_bet_win_a_day_lot330: "Maximum win a day limit for Lotery3 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ Lotery3 30s)",
};

const headers = [
    //{ text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'No',
        align: 'start',
        value: 'no',
    },
    { text: 'App Name', value: 'app_id' },
    { text: 'Fight No', value: 'fight_no' },
    { text: 'Group No', value: 'group_no' },
    { text: 'Number1', value: 'amount_one' },
    { text: 'Number2', value: 'amount_two' },
    { text: 'Number3', value: 'amount_three' },
    { text: 'Number4', value: 'amount_four' },
    { text: 'Number5', value: 'amount_five' },
    { text: 'Number6', value: 'amount_six' },
    { text: 'Odd', value: 'amount_odd' },
    { text: 'Even', value: 'amount_even' },
    { text: 'Big', value: 'amount_big' },
    { text: 'Small', value: 'amount_small' },
    { text: 'Cast', value: 'cast' },
    { text: 'Amount Win', value: 'amount_win' },
    // { text: 'VIP option', value: '' },
];

export default headers;
const getBaccaratSettingData = (state) => state.baccaratSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentBaccaratSettingData = (state) => state.currentBaccaratSettingData;


export default {
    getBaccaratSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentBaccaratSettingData,
};
const UPDATE_SETTING = (state, data) => {
    state.setting = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentSetting =  {
            title_vn: "",
            title_en: "",
            title_thai: "",
            title_kh: ""
        }
    }
    state.popUp = data;
};

const UPDATE_CURRENT_SETTING = (state, data) => {
    state.currentSetting = data;
};


const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
}
const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
export default {
    UPDATE_LOADING,
    UPDATE_SETTING,
    UPDATE_POPUP,
    UPDATE_CURRENT_SETTING,
    DELETE_ITEMS
};

const UPDATE_LIST = (state, data) => {
    state.list = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentList =  {
            result_name: 0,
            fight_no: "",
            group_no: false,
            channel_type: 0
        }
    }
    state.popUp = data;
};

const UPDATE_CURRENT_LIST = (state, data) => {
    state.currentList = data;
};


const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
}
const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
export default {
    UPDATE_LOADING,
    UPDATE_LIST,
    UPDATE_POPUP,
    UPDATE_CURRENT_LIST,
    DELETE_ITEMS
};

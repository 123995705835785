<template>
  <div>
      <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import storeModules from "@/modules/_store";
import storeAuth from "@/modules/auth/_store";
import storeUser from "@/modules/user/_store";
import storeNewRegisterMember from "@/modules/newUserRegister/_store";
import storeNewRegisterMemberNotContact from "@/modules/newUserRegisterNotContact/_store";
import storeWithdrawPending from "@/modules/withdrawPending/_store";
import storeDepositPending from "@/modules/depositPending/_store";
import storeUserCompanyUnderManager from "@/modules/userCompanyUnderManager/_store";
import storeUserSubAgent from "@/modules/userSubAgent/_store";
import storeMenu from "@/modules/MenuManagement/_store";
import storeBankUser from "@/modules/bankUser/_store";
import storeTransaction from "@/modules/Transaction/_store";
import storeWidthdrawStatement from "@/modules/widthdrawStatement/_store";
import storeDepositStatement from "@/modules/depositStatement/_store";
import storeTopUp from "@/modules/topUp/_store";
import storeRole from "@/modules/roles/_store";
import storePermission from "@/modules/permission/_store";
import storeChannel from "@/modules/channel/_store";
import storeStatement from "@/modules/statement/_store";
import storeStatementUsd from "@/modules/statementReports/channel/ocStatementUsd/_store";
import storeStatementKh from "@/modules/statementReports/channel/ocStatementKh/_store";
import storeOcStatementVn from "@/modules/statementReports/channel/ocStatementVn/_store";
import storeOcStatementRp from "@/modules/statementReports/channel/ocStatementRp/_store";
import storeOptionOperation from "@/modules/setOptionOperation/_store";
import storeActivityLog from "@/modules/activityLog/_store";

import storeVipPercentage from "@/modules/vipPercentage/_store";

//cockfing
// import storeCockFightChannel from "@/modules/cockFightChannel/_store";
import storeReverseResult from "@/modules/reverseResult/cock/_store";
import storeReverseResultHan2 from "@/modules/reverseResult/han2/_store";
import storeReverseResultHan3 from "@/modules/reverseResult/han3/_store";
import storeReverseResultKlaklouk from "@/modules/reverseResult/klaklouk/_store";
import storeReverseResultDragonCh19 from "@/modules/reverseResult/dragonCh19/_store";
import storeReverseResultBaccaratCh18 from "@/modules/reverseResult/baccaratCh18/_store";
import storeReverseResultYukiCh11 from "@/modules/reverseResult/yukiCh11/_store";
import storeReverseResultApong from "@/modules/reverseResult/apong/_store";
import storeReverseResultFantan from "@/modules/reverseResult/fantan/_store";
import storeReverseResultTaixiu from "@/modules/reverseResult/taixiu/_store";
import storeReverseResultLotto from "@/modules/reverseResult/lottoCh10/_store";

import storeReverseBaccarat from "@/modules/reverseResult/baccarat/_store";
import storeReverseDragon from "@/modules/reverseResult/dragon/_store";//_reverseDragon
import storeConfigUser from "@/modules/configUser/_store";
import storePayOut from "@/modules/payOut/_store";
import storeWeight from "@/modules/weight/_store";
import storeRoleLevel from "@/modules/roleLevel/_store";
import storeControllerForManager from "@/modules/controllerForManager/_store";
import storeOcStatementSummary from "@/modules/statementReports/currency/ocStatementSummary/_store";
import storeOcStatementSummaryKm from "@/modules/statementReports/currency/ocStatementSummaryCompanyKm/_store";
import storeOcStatementSummarySeniorUSD from "@/modules/statementReports/currency/ocStatementSummarySeniorUSD/_store";
import storeOcStatementSummarySeniorKm from "@/modules/statementReports/currency/ocStatementSummarySeniorKm/_store";
import storeOcStatementSummaryMasterUSD from "@/modules/statementReports/currency/ocStatementSummaryMasterUSD/_store";
import storeOcStatementSummaryMasterKm from "@/modules/statementReports/currency/ocStatementSummaryMasterKm/_store";
import storeOcStatementSummaryAgentUSD from "@/modules/statementReports/currency/ocStatementSummaryAgentUSD/_store";
import storeOcStatementSummaryAgentKm from "@/modules/statementReports/currency/ocStatementSummaryAgentKm/_store";
import storeOcStatementUSD from "@/modules/statementReports/channel/ocStatementUsd/_store";
import storeOcStatementKH from "@/modules/statementReports/channel/ocStatementKh/_store";
import storeEnableDisablePlayer from "@/modules/enableDisablePlayer/_store";
import storeEnableDisableAgent from "@/modules/enableDisableAgent/_store";
import storeOcStatementSummaryPlayerUSD from "@/modules/ocControlStatementSummaryPlayerUSD/_store";

import storeOcStatementSummaryCompanyVn from "@/modules/statementReports/currency/ocStatementSummaryCompanyVn/_store";
import storeOcStatementSummarySeniorVn from "@/modules/statementReports/currency/ocStatementSummarySeniorVn/_store";
import storeOcStatementSummaryMasterVn from "@/modules/statementReports/currency/ocStatementSummaryMasterVn/_store";
import storeOcStatementSummaryAgentVn from "@/modules/statementReports/currency/ocStatementSummaryAgentVn/_store";
import storeOcStatementSummaryPlayerVn from "@/modules/statementReports/currency/ocStatementSummaryPlayerVn/_store";

import storeOcStatementSummaryCompanyRp from "@/modules/statementReports/currency/ocStatementSummaryCompanyRp/_store";
import storeOcStatementSummarySeniorRp from "@/modules/statementReports/currency/ocStatementSummarySeniorRp/_store";
import storeOcStatementSummaryMasterRp from "@/modules/statementReports/currency/ocStatementSummaryMasterRp/_store";
import storeOcStatementSummaryAgentRp from "@/modules/statementReports/currency/ocStatementSummaryAgentRp/_store";
import storeOcStatementSummaryPlayerRp from "@/modules/statementReports/currency/ocStatementSummaryPlayerRp/_store";



// Channel
import storeCockFightChannel from "@/modules/channelGames/cockChannel/_store";
import storeApongChannel from "@/modules/channelGames/apongChannel/_store";
import storeBaccaratChannel from "@/modules/channelGames/baccaratChannel/_store";
import storeDragonChannel from "@/modules/channelGames/dragonChannel/_store";
import storeKlakloukChannel from "@/modules/channelGames/klakloukChannel/_store";
import storeLottoChannel from "@/modules/channelGames/lottoChannel/_store";
import storeYukiChannel from "@/modules/channelGames/yukiChannel/_store";
import storeFantanChannel from "@/modules/channelGames/fantanChannel/_store";
import storeTaisievChannel from "@/modules/channelGames/taisievChannel/_store";
import storeBaccaratCh18Channel from "@/modules/channelGames/baccaratCh18Channel/_store";
import storeDragonCh19Channel from "@/modules/channelGames/dragonCh19Channel/_store";
import storeHan3Ch21Channel from "@/modules/channelGames/han3Ch21Channel/_store";
import storeHan2Ch20Channel from "@/modules/channelGames/han2Ch20Channel/_store";

// Kd-Payout
import storeKdPayout from "@/modules/setPayout/_store";
//Kd
import storeCompanyReport from "@/modules/companyReports/_store";
import storeSeniorReport from "@/modules/seniorReports/_store";
import storeMasterReport from "@/modules/masterReports/_store";
import storeAgentReport from "@/modules/agentReports/_store";
import storePlayerReport from "@/modules/statementReports/currency/ocStatementSummaryPlayerUSD/_store";
import storeUserOnlineReport from "@/modules/userOnlineReports/_store";
import storeTopRangeWinnerReportsReport from "@/modules/topRangeWinnerReports/_store";
import storeHistoryByFightReport from "@/modules/historyByFightReport/_store";
import storeHistoryByFightKlakloukReport from "@/modules/historyByFightKlakloukReport/_store";
import storeHistoryByFightDragonReport from "@/modules/historyByFightDragonReport/_store";
import storeHistoryByFightBaccarat from "@/modules/historyByFightBaccarat/_store";
import storeHistoryByFightApong from "@/modules/historyByFightApong/_store";
import storeHistoryByFightFantan from "@/modules/historyByFightFantan/_store";
import storeHistoryByFightTaixiu from "@/modules/historyByFightTaixiu/_store";
import storeHistoryByFightLotto from "@/modules/historyByFightLotto/_store";
import storeHistoryByFightYuki from "@/modules/historyByFightYuki/_store";
import storeHistoryByFightHan2 from "@/modules/historyByFightHan2/_store";
import storeHistoryByFightHan3 from "@/modules/historyByFightHan3/_store";
import storeSeniorManagePlayer from "@/modules/seniorManagePlayer/_store";
import storeTreeStatementUSD from "@/modules/statementReports/channel/treeStatementUSD/_store";
import storeTreeStatementVn from "@/modules/statementReports/channel/treeStatementVn/_store";
import storeTreeStatementRp from "@/modules/statementReports/channel/treeStatementRp/_store";
import storeCancelBetting from "@/modules/cancelBetting/_store";

import storeConfigApp from "@/modules/configApp/_store";
import storeDealer from "@/modules/dealer/_store";
import storeMaintenance from "@/modules/maintenance/_store";

//outStanding Reports
import storeCockFightOutStandingReport from "@/modules/outstandingReports/cockFight/_store";
import storeCockFightOutStandingReportCm from "@/modules/outstandingReports/cockFightCm/_store";
import storeAbongOutStandingReport from "@/modules/outstandingReports/abong/_store";
import storeAbongOutStandingReportCm from "@/modules/outstandingReports/abongCm/_store";
import storeBaccaratOutStandingReport from "@/modules/outstandingReports/baccarat/_store";
import storeBaccaratOutStandingReportCm from "@/modules/outstandingReports/baccaratCm/_store";
import storeDragonOutStandingReport from "@/modules/outstandingReports/dragon/_store";
import storeDragonOutStandingReportCm from "@/modules/outstandingReports/dragonCm/_store";
import storeKlakloukOutStandingReport from "@/modules/outstandingReports/klaklouk/_store";
import storeKlakloukOutStandingReportCm from "@/modules/outstandingReports/klakloukCm/_store";
import storeLottoOutStandingReport from "@/modules/outstandingReports/lotto/_store";
import storeLottoOutStandingReportCm from "@/modules/outstandingReports/lottoCm/_store";
import storeYukiOutStandingReport from "@/modules/outstandingReports/yuki/_store";
import storeYukiOutStandingReportCm from "@/modules/outstandingReports/yukiCm/_store";
import storeFantanOutStandingReport from "@/modules/outstandingReports/fantan/_store";
import storeFantanOutStandingReportCm from "@/modules/outstandingReports/fantanCm/_store";
import storeTaisievOutStandingReport from "@/modules/outstandingReports/taisiev/_store";
import storeTaisievOutStandingReportCm from "@/modules/outstandingReports/taisievCm/_store";
import storeHan2OutStandingReport from "@/modules/outstandingReports/han2/_store";
import storeHan2OutStandingReportCm from "@/modules/outstandingReports/han2Cm/_store";
import storeHan3OutStandingReport from "@/modules/outstandingReports/han3/_store";
import storeHan3OutStandingReportCm from "@/modules/outstandingReports/han3Cm/_store";
import storeLotVnOutStandingReport from "@/modules/outstandingReports/lotvn/_store";
import storeLotVnCmOutStandingReport from "@/modules/outstandingReports/lotvnCm/_store";

// Type of Game report
import storeTypeGameSummaryCompany from "@/modules/statementReports/typeGame/typeGameSummaryCompany/_store";
import storeTypeGameSummarySenior from "@/modules/statementReports/typeGame/typeGameSummarySenior/_store";
import storeTypeGameSummaryMaster from "@/modules/statementReports/typeGame/typeGameSummaryMaster/_store";
import storeTypeGameSummaryAgent from "@/modules/statementReports/typeGame/typeGameSummaryAgent/_store";
import storeTypeGameSummaryPlayer from "@/modules/statementReports/typeGame/typeGameSummaryPlayer/_store";

//player white list
import storePlayerWhiteList from "@/modules/playerWhiteList/_store";
//Bank Statement
import storeBankStatement from "@/modules/bankStatement/_store";

// Lotto Setting
import storeLottoSetting from "@/modules/slotMachineSetting/lottoSetting/_store";
import storeYukiSetting from "@/modules/slotMachineSetting/yukiSetting/_store";
import storeLottoPP78Setting from "@/modules/slotMachineSetting/lottoPP78Setting/_store";
import storeBaccaratSetting from "@/modules/slotMachineSetting/baccaratSetting/_store";
import storeDragonSetting from "@/modules/slotMachineSetting/dragonSetting/_store";
import storeKlakloukSetting from "@/modules/slotMachineSetting/klakloukSetting/_store";
import storeDodenSetting from "@/modules/slotMachineSetting/dodenSetting/_store";
import storeTaixiuSetting from "@/modules/slotMachineSetting/taixiuSetting/_store";
import storeSicboSetting from "@/modules/slotMachineSetting/sicboSetting/_store";
import storeLot3Setting from "@/modules/slotMachineSetting/lot3Setting/_store";
import storeKenoSetting from "@/modules/slotMachineSetting/kenoSetting/_store";
import storeResultSetting from "@/modules/resultSetting/_store";
import storeRetryCock from "@/modules/retryCock/_store";
import storeRetryBacc from "@/modules/retryBaccarat/_store";
import storeRetryDragon from "@/modules/retryDragon/_store";
import storeLotVnChannel from "@/modules/channelGames/lotvnChannel/_store";
import storeReverseLotvn from "@/modules/reverseResult/lotvn/_store";
import storePromotion from "@/modules/promotion/_store";
export default {
  name: "App",
  created() {
    this.registerStore();
    this.initData();
  },
  computed: {
    ...mapGetters("$_modules", {
      user: "getUserInfo"
    }),
  },
  methods: {
    initData() {
      const token = this.$cookies.get("token");
      if (!token) return;
      this.fetchUserInfo();
    },
    registerStore() {
      const CANCEL_BETTING = "$_cancelBetting";
      const VIP_PERCENTAGE = "$_vipPercentage";
      const TREE_STATEMENT_USD = "$_treeStatementUSD";
      const TREE_STATEMENT_VN = "$_treeStatementVn";
      const TREE_STATEMENT_RP = "$_treeStatementRp";
      const MODULES = "$_modules";
      const AUTH = "$_auth";
      const USER = "$_user";
      const NEW_REGISTER_MEMBER = "$_newRegisterMember";
      const NEW_REGISTER_MEMBER_NOT_CONTACT = "$_newRegisterMemberNotContact";
      const WITHDRAW_PENDING = "$_withdrawPending";
      const DEPOSIT_PENDING = "$_depositPending";
      const USER_UNDER_MANAGER = "$_userCompanyUnderManager";
      const USER_SUB_AGENT = "$_userSubAgent";
      const MENUS = "$_menu";
      const BANK_USER = "$_bankUser";
      const TRANSACTION = "$_transaction";
      const WidthdrawStatement = "$_widthdrawStatement";
      const DepositStatement = "$_depositStatement";
      const TOPUP = "$_topup";
      const ROLE = "$_role";
      const PERMISSION = "$_permission";
      const CHANNEL = "$_channel";
      const STATEMENT = "$_statement";
      const STATEMENT_USD = "$_statementUsd";
      const STATEMENT_KH = "$_statementKh";
      //cockfight
      const CockFightChannel = "$_cockFightChannel";
      const BaccaratCh18Channel = "$_baccaratCh18Channel";
      const DragonCh19Channel = "$_dragonCh19Channel";
      // Han 2 3
      const Han3Ch21Channel = "$_han3Ch21Channel";
      const Han2Ch20Channel = "$_han2Ch20Channel";
      //reverse result
      const ReverseResult = "$_reverseResult";
      const ReverseBaccarat = "$_reverseBaccarat";
      const ReverseDragon = "$_reverseDragon";
      const ReverseResultBaccaratCh18 = "$_reverseResultBaccaratCh18";
      const ReverseResultDragonCh19 = "$_reverseResultDragonCh19";
      const ReverseResultHan2 = "$_reverseResultHan2";
      const ReverseResultHan3 = "$_reverseResultHan3";
      const ReverseResultKlaklouk = "$_reverseResultKlaklouk";
      const ReverseResultYukiCh11 = "$_reverseResultYukiCh11";
      const ReverseResultApong = "$_reverseResultApong";
      const ReverseResultFantan = "$_reverseResultFantan";
      const ReverseResultTaixiu = "$_reverseResultTaixiu";
      const ReverseResultLotto = "$_reverseResultLotto";
      ///////
      const ConfigUser = "$_configUser";
      const PayOut = "$_payOut";
      const WEIGHT = "$_weight";
      const RoleLevel = "$_roleLevel";
      const ControllerForManager = "$_controllerForManager";
      const OcStatementSummary = "$_oCStatementSummary";
      const OcStatementSummaryKm = "$_oCStatementSummaryKm";
      const OcStatementSummarySeniorUSD = "$_ocStatementSummarySeniorUSD";
      const OcStatementSummarySeniorKm = "$_ocStatementSummarySeniorKm";
      const OcStatementSummaryMasterUSD = "$_ocStatementSummaryMasterUSD";
      const OcStatementSummaryMasterKm = "$_ocStatementSummaryMasterKm";
      const OcStatementSummaryAgentUSD = "$_ocStatementSummaryAgentUSD";
      const OcStatementSummaryAgentKm = "$_ocStatementSummaryAgentKm";
      const OcStatementKh = "$_ocStatementKh";
      const OcStatementUsd = "$_ocStatementUsd";
      const OcStatementVn = "$_ocStatementVn";
      const OcStatementRp = "$_ocStatementRp";
      const EnableDisablePlayer = "$_enableDisablePlayer";
      const EnableDisableAgent = "$_enableDisableAgent";
      const OcControlStatementSummaryPlayerUSD = "$_ocControlStatementSummaryPlayerUSD";
      const OPTIONOPERATION = "$_optionOperation";

      const OcStatementSummaryCompanyVn = "$_oCStatementSummaryCompanyVn";
      const OcStatementSummarySeniorVn = "$_ocStatementSummarySeniorVn";
      const OcStatementSummaryMasterVn = "$_ocStatementSummaryMasterVn";
      const OcStatementSummaryAgentVn = "$_ocStatementSummaryAgentVn";
      const OcStatementSummaryPlayerVn = "$_ocStatementSummaryPlayerVn";

      const OcStatementSummaryCompanyRp = "$_oCStatementSummaryCompanyRp";
      const OcStatementSummarySeniorRp = "$_ocStatementSummarySeniorRp";
      const OcStatementSummaryMasterRp = "$_ocStatementSummaryMasterRp";
      const OcStatementSummaryAgentRp = "$_ocStatementSummaryAgentRp";
      const OcStatementSummaryPlayerRp = "$_ocStatementSummaryPlayerRp";

      //KdPayout
      const KDPAYOUT = "$_kdPayout";
       //kd
      const STATEMENT_COMPANY_REPORTS = "$_statementCompanyReports";
      const STATEMENT_SENIOR_REPORTS = "$_statementSeniorReports";
      const STATEMENT_MASTER_REPORTS = "$_statementMasterReports";
      const STATEMENT_AGENT_REPORTS = "$_statementAgentReports";
      const STATEMENT_PLAYER_REPORTS = "$_statementPlayerReports"; 
      const STATEMENT_USER_ONLINE_REPORTS = "$_statementUserOnlineReports"; 
      const STATEMENT_TOP_RANG_WINNER_REPORTS = "$_statementTopRangeWinnerReports"; 
      const HistoryByFightReport = "$_historyByFightReport"; 
      const HistoryByFightKlakloukReport = "$_historyByFightKlakloukReport"; 
      const HistoryByFightDragonReport = "$_historyByFightDragonReport"; 
      const HistoryByFightBaccarat = "$_historyByFightBaccarat"; 
      const HistoryByFightApong = "$_historyByFightApong"; 
      const HistoryByFightFantan = "$_historyByFightFantan";
      const HistoryByFightTaixiu = "$_historyByFightTiaxiu";
      const HistoryByFightLotto = "$_historyByFightLotto";
      const HistoryByFightYuki = "$_historyByFightYuki";
      const HistoryByFightHan2 = "$_historyByFightHan2";
      const HistoryByFightHan3 = "$_historyByFightHan3";
      const SENIOR_MANAGE_PLAYER = "$_seniorManagePlayer"; 
      
      const ConfigApp = "$_configApp";
     
      //Channel
      const ApongChannel = "$_apongChannel";
      const BaccaratChannel = "$_baccaratChannel";
      const DragonChannel = "$_dragonChannel";
      const KlakloukChannel = "$_klakloukChannel";
      const LottoChannel = "$_lottoChannel";
      const YukiChannel = "$_yukiChannel";
      const FantanChannel = "$_fantanChannel";
      const TaisievChannel = "$_taisievChannel";

      // Outstanding
      const COCKFIGHT_REPORT_OUT_STANDING = "$_cockFightReportOutStanding";
      const COCKFIGHT_REPORT_OUT_STANDING_CM = "$_cockFightReportOutStandingCm";
      const ABONG_REPORT_OUT_STANDING = "$_abongReportOutStanding";
      const ABONG_REPORT_OUT_STANDING_CM = "$_abongReportOutStandingCm";
      const BACCARAT_REPORT_OUT_STANDING = "$_baccaratReportOutStanding";
      const BACCARAT_REPORT_OUT_STANDING_CM = "$_baccaratReportOutStandingCm";
      const DRAGON_REPORT_OUT_STANDING = "$_dragonReportOutStanding";
      const DRAGON_REPORT_OUT_STANDING_CM = "$_dragonReportOutStandingCm";
      const KLAKLOUK_REPORT_OUT_STANDING = "$_klakloukReportOutStanding";
      const KLAKLOUK_REPORT_OUT_STANDING_CM = "$_klakloukReportOutStandingCm";
      const LOTTO_REPORT_OUT_STANDING = "$_lottoReportOutStanding";
      const LOTTO_REPORT_OUT_STANDING_CM = "$_lottoReportOutStandingCm";
      const YUKI_REPORT_OUT_STANDING = "$_yukiReportOutStanding";
      const YUKI_REPORT_OUT_STANDING_CM = "$_yukiReportOutStandingCm";
      const FANTAN_REPORT_OUT_STANDING = "$_fantanReportOutStanding";
      const FANTAN_REPORT_OUT_STANDING_CM = "$_fantanReportOutStandingCm";
      const TAISIEV_REPORT_OUT_STANDING = "$_taisievReportOutStanding";
      const TAISIEV_REPORT_OUT_STANDING_CM = "$_taisievReportOutStandingCm";
      const HAN2_REPORT_OUT_STANDING = "$_han2ReportOutStanding";
      const HAN2_REPORT_OUT_STANDING_CM = "$_han2ReportOutStandingCm";
      const HAN3_REPORT_OUT_STANDING = "$_han3ReportOutStanding";
      const HAN3_REPORT_OUT_STANDING_CM = "$_han3ReportOutStandingCm";

      // Type Game
      const TypeGameSummaryCompany = "$_typeGameSummaryCompany";
      const TypeGameSummarySenior = "$_typeGameSummarySenior";
      const TypeGameSummaryMaster = "$_typeGameSummaryMaster";
      const TypeGameSummaryAgent = "$_typeGameSummaryAgent";
      const TypeGameSummaryPlayer = "$_typeGameSummaryPlayer";

      if (!(TypeGameSummaryCompany in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryCompany, storeTypeGameSummaryCompany);
      }
      if (!(TypeGameSummarySenior in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummarySenior, storeTypeGameSummarySenior);
      }
      if (!(TypeGameSummaryMaster in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryMaster, storeTypeGameSummaryMaster);
      }
      if (!(TypeGameSummaryAgent in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryAgent, storeTypeGameSummaryAgent);
      }
      if (!(TypeGameSummaryPlayer in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryPlayer, storeTypeGameSummaryPlayer);
      }

      if (!(HistoryByFightReport in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightReport, storeHistoryByFightReport);
      }
      if (!(HistoryByFightKlakloukReport in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightKlakloukReport, storeHistoryByFightKlakloukReport);
      }
      if (!(HistoryByFightDragonReport in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightDragonReport, storeHistoryByFightDragonReport);
      }
      if (!(storeHistoryByFightBaccarat in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightBaccarat, storeHistoryByFightBaccarat);
      }
      if (!(storeHistoryByFightApong in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightApong, storeHistoryByFightApong);
      }
      if (!(storeHistoryByFightFantan in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightFantan, storeHistoryByFightFantan);
      }
      if (!(storeHistoryByFightTaixiu in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightTaixiu, storeHistoryByFightTaixiu);
      }
      if (!(storeHistoryByFightLotto in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightLotto, storeHistoryByFightLotto);
      }
      if (!(storeHistoryByFightYuki in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightYuki, storeHistoryByFightYuki);
      }
      if (!(storeHistoryByFightHan2 in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightHan2, storeHistoryByFightHan2);
      }
      if (!(storeHistoryByFightHan3 in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightHan3, storeHistoryByFightHan3);
      }
      if (!(COCKFIGHT_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(COCKFIGHT_REPORT_OUT_STANDING, storeCockFightOutStandingReport);
      }
      if (!(COCKFIGHT_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(COCKFIGHT_REPORT_OUT_STANDING_CM, storeCockFightOutStandingReportCm);
      }
      if (!(ABONG_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(ABONG_REPORT_OUT_STANDING, storeAbongOutStandingReport);
      }
      if (!(ABONG_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(ABONG_REPORT_OUT_STANDING_CM, storeAbongOutStandingReportCm);
      }
      if (!(BACCARAT_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(BACCARAT_REPORT_OUT_STANDING, storeBaccaratOutStandingReport);
      }
      if (!(BACCARAT_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(BACCARAT_REPORT_OUT_STANDING_CM, storeBaccaratOutStandingReportCm);
      }
      if (!(DRAGON_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(DRAGON_REPORT_OUT_STANDING, storeDragonOutStandingReport);
      }
      if (!(DRAGON_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(DRAGON_REPORT_OUT_STANDING_CM, storeDragonOutStandingReportCm);
      }
      if (!(KLAKLOUK_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(KLAKLOUK_REPORT_OUT_STANDING, storeKlakloukOutStandingReport);
      }
      if (!(KLAKLOUK_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(KLAKLOUK_REPORT_OUT_STANDING_CM, storeKlakloukOutStandingReportCm);
      }
      if (!(LOTTO_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(LOTTO_REPORT_OUT_STANDING, storeLottoOutStandingReport);
      }
      if (!(LOTTO_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(LOTTO_REPORT_OUT_STANDING_CM, storeLottoOutStandingReportCm);
      }
      if (!(YUKI_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(YUKI_REPORT_OUT_STANDING, storeYukiOutStandingReport);
      }
      if (!(YUKI_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(YUKI_REPORT_OUT_STANDING_CM, storeYukiOutStandingReportCm);
      }
      if (!(FANTAN_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(FANTAN_REPORT_OUT_STANDING, storeFantanOutStandingReport);
      }
      if (!(FANTAN_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(FANTAN_REPORT_OUT_STANDING_CM, storeFantanOutStandingReportCm);
      }
      if (!(TAISIEV_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(TAISIEV_REPORT_OUT_STANDING, storeTaisievOutStandingReport);
      }
      if (!(TAISIEV_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(TAISIEV_REPORT_OUT_STANDING_CM, storeTaisievOutStandingReportCm);
      }
      if (!(HAN2_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(HAN2_REPORT_OUT_STANDING, storeHan2OutStandingReport);
      }
      if (!(HAN2_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(HAN2_REPORT_OUT_STANDING_CM, storeHan2OutStandingReportCm);
      }
      if (!(HAN3_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(HAN3_REPORT_OUT_STANDING, storeHan3OutStandingReport);
      }
      if (!(HAN3_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(HAN3_REPORT_OUT_STANDING_CM, storeHan3OutStandingReportCm);
      }

      // Lotto Setting
      const LottoSetting = "$_lottoSetting";

      if (!(LottoSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(LottoSetting, storeLottoSetting);
      }
      // Yuki Setting
      const YukiSetting = "$_yukiSetting";

      if (!(YukiSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(YukiSetting, storeYukiSetting);
      }

      // Lotto PP78 Setting
      const LottoPP78Setting = "$_lottoPP78Setting";

      if (!(LottoPP78Setting in this.$store._modules.root._children)) {
          this.$store.registerModule(LottoPP78Setting, storeLottoPP78Setting);
      }

       // Baccarat Setting
       const BaccaratSetting = "$_baccaratSetting";

      if (!(BaccaratSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(BaccaratSetting, storeBaccaratSetting);
      }
      // Tiger Dragon Setting
      const DragonSetting = "$_dragonSetting";

      if (!(DragonSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(DragonSetting, storeDragonSetting);
      }
      // Klaklouk Setting
      const KlakloukSetting = "$_klakloukSetting";

      if (!(KlakloukSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(KlakloukSetting, storeKlakloukSetting);
      }

      // Doden Setting
      const DodenSetting = "$_dodenSetting";

      if (!(DodenSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(DodenSetting, storeDodenSetting);
      }

      // Taixiu Setting
      const TaixiuSetting = "$_taixiuSetting";

      if (!(TaixiuSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(TaixiuSetting, storeTaixiuSetting);
      }

      // Taixiu Setting
      const SicboSetting = "$_sicboSetting";
      if (!(SicboSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(SicboSetting, storeSicboSetting);
      }
      // Lot3 Setting
      const Lot3Setting = "$_lot3Setting";
      if (!(Lot3Setting in this.$store._modules.root._children)) {
          this.$store.registerModule(Lot3Setting, storeLot3Setting);
      }
      // Keno Setting
      const KenoSetting = "$_kenoSetting";
      if (!(KenoSetting in this.$store._modules.root._children)) {
          this.$store.registerModule(KenoSetting, storeKenoSetting);
      }
      const DEALER = "$_dealer";
      if (!(DEALER in this.$store._modules.root._children)) {
          this.$store.registerModule(DEALER, storeDealer);
      }
      const PLAYER_WHITE_LIST = "$_playerWhiteList";
      if (!(PLAYER_WHITE_LIST in this.$store._modules.root._children)) {
          this.$store.registerModule(PLAYER_WHITE_LIST, storePlayerWhiteList);
      }
      const BANK_STATEMENT = "$_bankStatement";
      if (!(BANK_STATEMENT in this.$store._modules.root._children)) {
          this.$store.registerModule(BANK_STATEMENT, storeBankStatement);
      }
      
      const MAINTENANCE = "$_maintenance";
      if (!(MAINTENANCE in this.$store._modules.root._children)) {
          this.$store.registerModule(MAINTENANCE, storeMaintenance);
      }

      if (!(ApongChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(ApongChannel, storeApongChannel);
      }
      if (!(BaccaratChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(BaccaratChannel, storeBaccaratChannel);
      }
      if (!(DragonChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(DragonChannel, storeDragonChannel);
      }
      if (!(KlakloukChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(KlakloukChannel, storeKlakloukChannel);
      }
      if (!(LottoChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(LottoChannel, storeLottoChannel);
      }
      if (!(YukiChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(YukiChannel, storeYukiChannel);
      }
      if (!(FantanChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(FantanChannel, storeFantanChannel);
      }
      if (!(TaisievChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(TaisievChannel, storeTaisievChannel);
      }
      if (!(ConfigApp in this.$store._modules.root._children)) {
          this.$store.registerModule(ConfigApp, storeConfigApp);
      }
      if (!(CANCEL_BETTING in this.$store._modules.root._children)) {
        this.$store.registerModule(CANCEL_BETTING, storeCancelBetting);
      }

      if (!(VIP_PERCENTAGE in this.$store._modules.root._children)) {
        this.$store.registerModule(VIP_PERCENTAGE, storeVipPercentage);
      }
        if (!(TREE_STATEMENT_USD in this.$store._modules.root._children)) {
        this.$store.registerModule(TREE_STATEMENT_USD, storeTreeStatementUSD);
      }
      if (!(TREE_STATEMENT_VN in this.$store._modules.root._children)) {
        this.$store.registerModule(TREE_STATEMENT_VN, storeTreeStatementVn);
      }
      if (!(TREE_STATEMENT_RP in this.$store._modules.root._children)) {
        this.$store.registerModule(TREE_STATEMENT_RP, storeTreeStatementRp);
      }
       if (!(SENIOR_MANAGE_PLAYER in this.$store._modules.root._children)) {
        this.$store.registerModule(SENIOR_MANAGE_PLAYER, storeSeniorManagePlayer);
      }
      if (!(STATEMENT_COMPANY_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_COMPANY_REPORTS, storeCompanyReport);
      }
      if (!(STATEMENT_SENIOR_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_SENIOR_REPORTS, storeSeniorReport);
      }
      if (!(STATEMENT_MASTER_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_MASTER_REPORTS, storeMasterReport);
      }
      if (!(STATEMENT_AGENT_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_AGENT_REPORTS, storeAgentReport);
      }
      if (!(STATEMENT_PLAYER_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_PLAYER_REPORTS, storePlayerReport);
      }
      if (!(STATEMENT_USER_ONLINE_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_USER_ONLINE_REPORTS, storeUserOnlineReport);
      }
      if (!(STATEMENT_TOP_RANG_WINNER_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_TOP_RANG_WINNER_REPORTS, storeTopRangeWinnerReportsReport);
      }

      if (!(OPTIONOPERATION in this.$store._modules.root._children)) {
        this.$store.registerModule(OPTIONOPERATION, storeOptionOperation);
      }
      if (!(OcControlStatementSummaryPlayerUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcControlStatementSummaryPlayerUSD, storeOcStatementSummaryPlayerUSD);
      }
      if (!(EnableDisablePlayer in this.$store._modules.root._children)) {
        this.$store.registerModule(EnableDisablePlayer, storeEnableDisablePlayer);
      }
      if (!(EnableDisableAgent in this.$store._modules.root._children)) {
        this.$store.registerModule(EnableDisableAgent, storeEnableDisableAgent);
      }
      if (!(OcStatementSummaryMasterUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryMasterUSD, storeOcStatementSummaryMasterUSD);
      }
      if (!(OcStatementSummaryMasterKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryMasterKm, storeOcStatementSummaryMasterKm);
      }
      if (!(OcStatementUsd in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementUsd, storeOcStatementUSD);
      }
      if (!(OcStatementKh in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementKh, storeOcStatementKH);
      }
      if (!(OcStatementVn in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementVn, storeOcStatementVn);
      }
      if (!(OcStatementRp in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementRp, storeOcStatementRp);
      }
      if (!(OcStatementSummarySeniorUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummarySeniorUSD, storeOcStatementSummarySeniorUSD);
      }
      if (!(OcStatementSummarySeniorKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummarySeniorKm, storeOcStatementSummarySeniorKm);
      }
      if (!(OcStatementSummary in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummary, storeOcStatementSummary);
      }
      if (!(OcStatementSummaryKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryKm, storeOcStatementSummaryKm);
      }

      if (!(OcStatementSummaryCompanyVn in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryCompanyVn, storeOcStatementSummaryCompanyVn);
      }
      if (!(OcStatementSummarySeniorVn in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummarySeniorVn, storeOcStatementSummarySeniorVn);
      }
      if (!(OcStatementSummaryMasterVn in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryMasterVn, storeOcStatementSummaryMasterVn);
      }
      if (!(OcStatementSummaryAgentVn in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryAgentVn, storeOcStatementSummaryAgentVn);
      }
      if (!(OcStatementSummaryAgentUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryAgentUSD, storeOcStatementSummaryAgentUSD);
      }
      if (!(OcStatementSummaryAgentKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryAgentKm, storeOcStatementSummaryAgentKm);
      }
      if (!(OcStatementSummaryPlayerVn in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryPlayerVn, storeOcStatementSummaryPlayerVn);
      }

      if (!(OcStatementSummaryCompanyRp in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryCompanyRp, storeOcStatementSummaryCompanyRp);
      }
      if (!(OcStatementSummarySeniorRp in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummarySeniorRp, storeOcStatementSummarySeniorRp);
      }
      if (!(OcStatementSummaryMasterRp in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryMasterRp, storeOcStatementSummaryMasterRp);
      }
      if (!(OcStatementSummaryAgentRp in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryAgentRp, storeOcStatementSummaryAgentRp);
      }
      if (!(OcStatementSummaryPlayerRp in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryPlayerRp, storeOcStatementSummaryPlayerRp);
      }


      if (!(MODULES in this.$store._modules.root._children)) {
        this.$store.registerModule(MODULES, storeModules);
      }
      if (!(AUTH in this.$store._modules.root._children)) {
        this.$store.registerModule(AUTH, storeAuth);
      }
      if (!(USER in this.$store._modules.root._children)) {
        this.$store.registerModule(USER, storeUser);
      }
      if (!(NEW_REGISTER_MEMBER in this.$store._modules.root._children)) {
        this.$store.registerModule(NEW_REGISTER_MEMBER, storeNewRegisterMember);
      }
      if (!(NEW_REGISTER_MEMBER_NOT_CONTACT in this.$store._modules.root._children)) {
        this.$store.registerModule(NEW_REGISTER_MEMBER_NOT_CONTACT, storeNewRegisterMemberNotContact);
      }
      if (!(WITHDRAW_PENDING in this.$store._modules.root._children)) {
          this.$store.registerModule(WITHDRAW_PENDING, storeWithdrawPending);
      }
      if (!(DEPOSIT_PENDING in this.$store._modules.root._children)) {
          this.$store.registerModule(DEPOSIT_PENDING, storeDepositPending);
      }
      if (!(USER_UNDER_MANAGER in this.$store._modules.root._children)) {
        this.$store.registerModule(USER_UNDER_MANAGER, storeUserCompanyUnderManager);
      }
      if (!(USER_SUB_AGENT in this.$store._modules.root._children)) {
        this.$store.registerModule(USER_SUB_AGENT, storeUserSubAgent);
      }
      
      if (!(MENUS in this.$store._modules.root._children)) {
        this.$store.registerModule(MENUS, storeMenu);
      }
      if (!(BANK_USER in this.$store._modules.root._children)) {
        this.$store.registerModule(BANK_USER, storeBankUser);
      }
      if (!(TRANSACTION in this.$store._modules.root._children)) {
        this.$store.registerModule(TRANSACTION, storeTransaction);
      }
      if (!(WidthdrawStatement in this.$store._modules.root._children)) {
        this.$store.registerModule(WidthdrawStatement, storeWidthdrawStatement);
      }
      if (!(DepositStatement in this.$store._modules.root._children)) {
        this.$store.registerModule(DepositStatement, storeDepositStatement);
      }
      if (!(TOPUP in this.$store._modules.root._children)) {
        this.$store.registerModule(TOPUP, storeTopUp);
      }
      if (!(ROLE in this.$store._modules.root._children)) {
        this.$store.registerModule(ROLE, storeRole);
      }
      if (!(PERMISSION in this.$store._modules.root._children)) {
        this.$store.registerModule(PERMISSION, storePermission);
      }
      if (!(CHANNEL in this.$store._modules.root._children)) {
        this.$store.registerModule(CHANNEL, storeChannel);
      }
       if (!(STATEMENT in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT, storeStatement);
      }
       if (!(CockFightChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(CockFightChannel, storeCockFightChannel);
      }
      if (!(BaccaratCh18Channel in this.$store._modules.root._children)) {
        this.$store.registerModule(BaccaratCh18Channel, storeBaccaratCh18Channel);
      }
      if (!(Han3Ch21Channel in this.$store._modules.root._children)) {
        this.$store.registerModule(Han3Ch21Channel, storeHan3Ch21Channel);
      }
      if (!(Han2Ch20Channel in this.$store._modules.root._children)) {
        this.$store.registerModule(Han2Ch20Channel, storeHan2Ch20Channel);
      }
      if (!(DragonCh19Channel in this.$store._modules.root._children)) {
        this.$store.registerModule(DragonCh19Channel, storeDragonCh19Channel);
      }
      if (!(STATEMENT_USD in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_USD, storeStatementUsd);
      }
      if (!(STATEMENT_KH in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_KH, storeStatementKh);
      }
      if (!(ReverseResultBaccaratCh18 in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultBaccaratCh18, storeReverseResultBaccaratCh18);
      }
      if (!(ReverseResultDragonCh19 in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultDragonCh19, storeReverseResultDragonCh19);
      }
      
      //reverse result
      if (!(ReverseResult in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResult, storeReverseResult);
      }
      if (!(ReverseBaccarat in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseBaccarat, storeReverseBaccarat);
      }
      if (!(ReverseDragon in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseDragon, storeReverseDragon);
      }
      if (!(ReverseResultHan2 in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultHan2, storeReverseResultHan2);
      }
      if (!(ReverseResultHan3 in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultHan3, storeReverseResultHan3);
      }
      if (!(ReverseResultKlaklouk in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultKlaklouk, storeReverseResultKlaklouk);
      }
      if (!(ReverseResultYukiCh11 in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultYukiCh11, storeReverseResultYukiCh11);
      }
      if (!(ReverseResultApong in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultApong, storeReverseResultApong);
      }
      if (!(ReverseResultFantan in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultFantan, storeReverseResultFantan);
      }
      if (!(ReverseResultTaixiu in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultTaixiu, storeReverseResultTaixiu);
      }
      if (!(ReverseResultLotto in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResultLotto, storeReverseResultLotto);
      }
      //////
      if (!(ConfigUser in this.$store._modules.root._children)) {
        this.$store.registerModule(ConfigUser, storeConfigUser);
      }
      if (!(PayOut in this.$store._modules.root._children)) {
        this.$store.registerModule(PayOut, storePayOut);
      }
       if (!(WEIGHT in this.$store._modules.root._children)) {
        this.$store.registerModule(WEIGHT, storeWeight);
      }
      if (!(RoleLevel in this.$store._modules.root._children)) {
        this.$store.registerModule(RoleLevel, storeRoleLevel);
      }
      if (!(ControllerForManager in this.$store._modules.root._children)) {
        this.$store.registerModule(ControllerForManager, storeControllerForManager);
      }

       if (!(KDPAYOUT in this.$store._modules.root._children)) {
        this.$store.registerModule(KDPAYOUT, storeKdPayout);
      }

      const ResultSetting = "$_resultSetting";
      if (!(ResultSetting in this.$store._modules.root._children)) {
        this.$store.registerModule(ResultSetting, storeResultSetting);
      }
      const RetryCock = "$_retryCock";
      if (!(RetryCock in this.$store._modules.root._children)) {
        this.$store.registerModule(RetryCock, storeRetryCock);
      }

      const RetryBacc = "$_retryBacc";
      if (!(RetryBacc in this.$store._modules.root._children)) {
        this.$store.registerModule(RetryBacc, storeRetryBacc);
      }
      const RetryDragon = "$_retryDragon";
      if (!(RetryDragon in this.$store._modules.root._children)) {
        this.$store.registerModule(RetryDragon, storeRetryDragon);
      }
      const ActivityLog = "$_activityLog";
      if (!(ActivityLog in this.$store._modules.root._children)) {
        this.$store.registerModule(ActivityLog, storeActivityLog);
      }
      const LotVnChannel = "$_lotvnChannel";
      if (!(LotVnChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(LotVnChannel, storeLotVnChannel);
      }
      const ReverseLotvn = "$_reverseLotvn";
      if (!(ReverseLotvn in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseLotvn, storeReverseLotvn);
      }
      const LotvnOutstanding = "$_lotvnOutstanding";
      if (!(LotvnOutstanding in this.$store._modules.root._children)) {
        this.$store.registerModule(LotvnOutstanding, storeLotVnOutStandingReport);
      }
      //storeLotVnCmOutStandingReport
      const LotvnCmOutstanding = "$_lotvnCmOutstanding";
      if (!(LotvnCmOutstanding in this.$store._modules.root._children)) {
        this.$store.registerModule(LotvnCmOutstanding, storeLotVnCmOutStandingReport);
      }

      const Promotion = "$_promotion";
      if (!(Promotion in this.$store._modules.root._children)) {
        this.$store.registerModule(Promotion, storePromotion);
      }
    },
    ...mapActions("$_modules", ["fetchUserInfo"]),
  },
};
</script>
<style>
#particles-js{
  position: absolute;
  width: 100% !important;
  height:100% !important;
}
.vue-treeselect__option-arrow-placeholder{
    display: none !important;
  }
  .vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
      cursor: text;
      padding-top: 8px!important;
  }
</style>
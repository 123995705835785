<template>
  <v-app>
    <!--nav bar-->
    <div class="app-container">
      <HeaderComponent></HeaderComponent>
      <!--side bar-->
      <!-- <SideBarComponent></SideBarComponent> -->
      <section class="section mt-2">
        <!-- add content here -->
        <router-view></router-view>
      </section>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
// import { mapMutations } from "vuex/dist/vuex.common.js";

export default {
  components: {
    HeaderComponent: () => import("../components/Header.vue"),
    // SideBarComponent:() => import("../components/SideBar.vue")
  },
  data: () => ({
    selectedItem: null,
    lang: ["en", "kh"],
    noTree: [{ text: "ទំព័រដើម", icon: "mdi-home", action: "dashboard" }],
    // items: [
    //   { name: "ប៉ុស្តិ៌ 1", id: 1 },
    //   { name: "ប៉ុស្តិ៌ 2", id: 2 },
    //   { name: "ប៉ុស្តិ៌ 3", id: 3 },
    //   { name: "ប៉ុស្តិ៌ 4", id: 4 },
    // ],
    channelType: { name: "1", id: 1 },
    nn: [
      'adfadsf', 'asdasdeqweqwe111'
    ],
    menuObj: {
      menuList:[],
      index: -1
    }
  }),
  beforeMount() {
    this.$nextTick(() => {
      if (
        this.$cookies.get("local") !== undefined &&
        this.$cookies.get("local") !== null
      ) {
        this.$i18n.locale = this.$cookies.get("local");
      } else {
        this.$i18n.locale = "kh";
      }
    });
  },
  computed: {
    ...mapGetters("$_modules", {
      menu: "getMenu",
      user: "getUser",
      drawer: "getDrawer",
    }),
  },
  watch: {
    user() { },
  },
  async created() {
    this.connectSocket();
    if (this.$cookies.get("channel-type")) {
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
    }
    if (this.$cookies.get("token")) {
      await this.fetchUser();
      // await this.fetchUserAccessMenu();
      this.getMenu(); 
    }

    if (
      (this.user && this.user.role_id == "612a40c71a1fcead8871e835") ||
      this.user.role_id == "63cddbd5b51c1415b8bae1df" ||
      this.user.role_id == "63cddc81b51c1415b8bae1fd" ||
      this.user.role_id == "63cddccfb51c1415b8bae208" ||
      this.user.role_id == "63cddd30b51c1415b8bae213" ||
      this.user.role_id == "63cddd6fb51c1415b8bae21e" ||
      this.user.role_id == "63cddde1b51c1415b8bae229" ||
      this.user.role_id == "6406b857c2fa980eb0174708" ||
      this.user.role_id == "64095989c2fa980eb0174893" ||
      this.user.role_id == "64643d791ba4b87724c7158b" ||
      this.user.role_id == "64643d1f1ba4b87724c7158a" ||
      this.user.role_id == "648fd746fc595e5604b69dee" ||
      this.user.role_id == "648fd726fc595e5604b69ded" ||
      this.user.role_id == "6668ff5f822f3a61b7db827d"
    ) {
      this.$cookies.set("channel-type", this.user.channel_post);
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
    }
  },
  methods: {
    returnString(data) {
      return data;
    },
    async getMenu() {
      // console.log('data');
      try {
        const response = await this.getUserAccessMenu();
        if (response.data.code){
          // let menuList = response.data.data.object;
          // console.log('sbcad_sub_index',this.$cookies.get("sbcad_sub_index"));
          let objectList = response.data.data.object
          this.menuObj.menuList = objectList;
          this.menuObj.index = this.$cookies.get("sbcad_sub_index") != -1 ? this.$cookies.get("sbcad_sub_index") : (this.menuObj.menuList.length-1);
          let mMenuSelected = this.$cookies.get("sbcad_sub_index") != -1 ? this.$cookies.get("sbcad_menu_selected") : this.menuObj.menuList[(this.menuObj.menuList.length-1)]._id;
          let sMenuSelected = this.$cookies.get("sbcad_submenu_index") == -1 ? objectList[(objectList.length-1)].items[0]._id : this.$cookies.get("sbcad_submenu_selected");
          this.UPDATE_MMENU_SELECTED(mMenuSelected);
          this.UPDATE_SMENU_SELECTED(sMenuSelected);
          this.$cookies.set("sbcad_menu_selected", mMenuSelected);
          this.$cookies.set("sbcad_submenu_selected", sMenuSelected);
          // console.log('this.menuObj.index',this.menuObj.index);
          this.UPDATE_MENU_LIST(this.menuObj);
          console.log('sbcad_sub_sbcad_submenu_selected', this.$cookies.get("sbcad_submenu_selected"));
        }
      }catch (error) {
        return error;
      }
    },
    changeLocale() {
      if (this.$i18n.locale === "kh") {
        this.$cookies.set("local", "en");
        this.$i18n.locale = "en";
      } else {
        this.$cookies.set("local", "kh");
        this.$i18n.locale = "kh";
      }
    },
    selectChannelType(channelType) {
      this.$cookies.set("channel-type", channelType);
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
      if (
        parseInt(this.$cookies.get("channel-type")) == 2 ||
        parseInt(this.$cookies.get("channel-type")) == 3 ||
        parseInt(this.$cookies.get("channel-type")) == 4
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("channel-type")
        );
      this.$router.go();
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    connectSocket() {
      this.$connectSocket(
        this.$cookies.get("user_role"),
        this.$cookies.get("token")
      );
    },
    ...mapActions("$_modules", ["fetchUserAccessMenu", "fetchUser", "getUserAccessMenu"]),
    ...mapMutations("$_modules", ["UPDATE_MENU", 'UPDATE_MENU_LIST', 'UPDATE_SMENU_SELECTED', 'UPDATE_MMENU_SELECTED']),
  },
};
</script>

<style>
.v-application {

  height: 100vh;
  position: relative;
  background-position: 30% 30%;
  /* background-position: 55.65% 55%; */
  /* background-image: url('../assets/images/bg_folwer_t.gif') !important; */
}

.table-responsive {
  overflow-x: hidden !important;
}

.app-container {

  width: 100%;
  height: 200vh;
  background: #dfe2e6;
  background: -webkit-linear-gradient(to right,
      #dfe2e6,
      #dfe2e6);
  background: linear-gradient(to right,
      #dfe2e6,
      #dfe2e6);
}
</style>

const UPDATE_CHANNEL = (state, data) => {
    state.currentChannel = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}
const SET_RESULT = (state, data) => {
    state.selectResult = data
}
const LOADINGRESULT = (state, data) => {
    state.loadingResult = data;
}
const RESULT_TABLE = (state, data) => {
    state.objResult = data
}


export default {
    UPDATE_CHANNEL,
    RESULT_TABLE,
    LOADING,
    SET_RESULT,
    LOADINGRESULT
};
const getDrawer = (state) => state.drawer;
const getMenu = (state) => state.menus;
const getSubmenu = (state) => state.submenuList;
const getUserInfo = (state) => state.userInfo;
const getDarkMode = (state) => state.darkMode;
const getUser = (state) => state.user;
const getMMenuSelected = (state) => state.mainMenuSelected;
const getSMenuSelected = (state) => state.subMenuSelected;
const getEyeIsOn = (state) => state.eyeIsOn;
const getUserAccessPermission = (state) =>state.userAccessPermission;

export default {
	getDrawer,
	getMenu,
	getUserInfo,
	getDarkMode,
	getUser,
	getUserAccessPermission,
	getEyeIsOn,
	getSubmenu,
	getMMenuSelected,
	getSMenuSelected
};

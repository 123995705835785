import reverseFantan from '@/modules/reverseResult/fantan';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseFantan',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseFantan,
        name: 'reverseFantan'
    }]
}];

export default routes;
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
const state = {
    currentChannel: {
        _id: null,
        date: null,
        no: null,
        group: null,
        is_maintain: null,
        is_open: null,
        result:null
    },
    objResult: [],
    results: [],
    loading: false,
    loadingResult: false
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
import newRegisterMemberNotContact from '@/modules/newUserRegisterNotContact';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/newRegisterMemberNotContact',
		component: Layout,
		meta: {
			permission: 'user',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'newRegisterMemberNotContact',
				component: newRegisterMemberNotContact
			}
		]
	}
];

export default routes;

<template>
  <div>
    <v-container fluid>
      <v-chip :color="correctColorPosition(dealerInfo.position)" class="dealer-info px-4">{{ dealerInfo.position == 'pr'
    ? "Supervisor" : dealerInfo.position
}}</v-chip>
      <v-row class="pt-5">
        <p class="dealer-name pl-2">{{ dealerInfo && dealerInfo.name ? "Name: " + dealerInfo.name : '---' }}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.last_login ?
    "Last Login: " + moment(dealerInfo.last_login).format('DD/MM/YYYY hh:mm') : '---'
}}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.barcode ?
    'Id: ' + dealerInfo.barcode : 'id: ---'
}}</p>
        <v-btn @click="_openDialogScanDealer(true)" :loading="loadingDealerButton" :disabled="loadingDealerButton"
          color="blue-grey" class="white--text dealer-changer">
          Change Dealer
          <v-icon right dark>
            mdi-cloud-sync
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col cols="5" class="live-channel-info-container">
          <iframe v-if="channel && channel.live_urls" :src="channel.live_urls[0]" frameborder="0"
            width="100%" height="415px" style="padding:0px;overflow:hidden;"></iframe>
        </v-col>

        <v-col cols="2" class="game-control-container">
          <!-- <p
            :class="socketChannelCountdown && socketChannelCountdown <= 10 ? 'red--text uppercase default-count-down' : 'uppercase default-count-down'">
            {{ socketChannelCountdown ? socketChannelCountdown : defaultCountDown }}
          </p> -->
          <p :class="[
  channelStatus(channel.is_open).color,
  'uppercase channel-status',
]">
            {{ channelStatus(channel.is_open).status }}
          </p>
          <div style="width:100%; text-align:center;">
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error"
              class="mt-5" style="width:150px" @click="alertResetResultDialog" :disabled="
  channel.is_first_game == false
    ? true
    : false
">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Result
            </v-btn>
            <br>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error"
              class="mt-5" style="width:150px" @click="_clearCard" :disabled="
  loading == true ||
    channel.is_open == true
    ? true
    : false
">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Clear Result
            </v-btn>
          </div>
        </v-col>

        <v-col cols="5">
          <v-col cols="12">
            <!-- <v-text-field :autofocus="true" type="number" :disabled="!dealerInfo._id" label="Text Input barcode"
                      ref='search' v-model="scanCard" onpaste="return false">
                    </v-text-field> -->
            <v-text-field :autofocus="true" autocomplete="off" type="number"
              :disabled="!dealerInfo._id || !allowInput || getAllowScanCard" label="Text Input barcode" ref='search'
              v-model="scanCard" onpaste="return false">
            </v-text-field>
          </v-col>
          <v-row style="width: 100%;">
            <v-col cols="12" class="dealer-card-container">
              <v-row>
                <v-col cols="6">
                  <v-img :src="'images/klaklouk/' + image1" style="width: 200px;">
                  </v-img>
                </v-col>
                <v-col cols="6">
                  <v-img :src="'images/klaklouk/' + image2" style="width: 200px;">
                  </v-img>
                </v-col>
                <v-col cols="3">
                </v-col>
                <v-col cols="6">
                  <v-img :src="'images/klaklouk/' + image3" style="width: 200px;">
                  </v-img>
                </v-col>
                <v-col cols="3">
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="channel.is_last_game">
        <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:0px; text-align: right;">
          <v-btn class="update-chanel-btn" @click="_finishedCardKlaklouk"
            :color="channelUpdateStatus(channel.is_open).background"
            :disabled="(channel.is_first_game == true || channel.is_open == false || loadingResult ? true: false)">
            Finish
          </v-btn>
        </div>
      </div>
      <div v-else>
        <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:0px;text-align: right;">
          <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
            @click="_updateChanelStatusKlaklouk"
            :disabled="channel.is_open == false || loadingResult ? true: false|| !canUpdateStatus">
            {{ channelUpdateStatus(channel.is_open).status }}
          </v-btn>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="dialogResetShoe" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Score ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetShoe = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetShoe">
            Yes, Reset Score
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="getDealerDialog" width="420" height="420" persistent>
      <!-- <v-card class="text-right px-5">
        <v-btn class="mx-auto justify-center" color="danger" @click="_openDialogScanDealer(false)"> <span
            style="color:red;"> x</span></v-btn>
      </v-card> -->
      <v-card class="text-center px-5">

        <p class="text-h5 text-danger">Please Scan Card</p>
        <v-text-field autofocus autocomplete="off" label="" type="password" v-model="barcodeDealer"
          onpaste="return false"></v-text-field>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogResetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Shoe ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Shoe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVoidShoe" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Score ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogVoidShoe = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_voidShoe">
            Yes, Void Shoe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
addEventListener('keypress', (event) => {
  if (event.code == 'NumpadEnter') {
    if(window.callInside.loadingResult) {
      return false;
    }
    else{
      window.callInside._updateChanelStatusKlaklouk();
    }
  }
  if (event.code == 'NumpadSubtract') {
    if(window.callInside.loadingResult) {
      return false;
    }
    else{
      window.callInside._finishedCardKlaklouk();
    }
  }
  // if (event.code == 'NumpadEnter') window.callInside._updateChanelStatusKlaklouk();
  if (event.code == 'NumpadAdd') window.callInside._openDialogScanDealer(true);
  if (event.code == 'Backquote') window.callInside.increaseColumn();
  // if (event.code == 'NumpadSubtract') window.callInside._finishedCardKlaklouk();
  if (event.code == 'NumpadDivide') window.callInside.reloadWindow();
  if (event.code == 'NumpadMultiply') window.callInside.focusSearch();
});
window.onkeydown = function (e) {
  return !(e.keyCode == 32 && e.target == document.body);
};
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  props: [
    "channel",
    "objSnNumber",
    "socketChannelType",
    "socketChannelCountdown",
    "dealerInfo",
    "image1",
    "image2",
    "image3",
    "getDealerDialog",
    "getResultEvent",
    "getAllowScanCard"
  ],

  data() {
    return {
      imgPath: process.env.VUE_APP_API_REPORT_URL,
      defaultPic: 'images/defaultImg.jpg',
      valid: true,
      rules: [(value) => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      updateChanel: [],
      loadingDealerButton: false,
      canUpdateStatus: true,
      dialogResetResult: false,
      dialogResetShoe: false,
      dialogVoidShoe: false,
      scanCard: "",
      barcodeDealer: "",
      defaultCountDown: 0,
      allowInput: true,
      rule_meron_percentage: [(v) => {
        if (
          !isNaN(parseFloat(v)) &&
          v > 0 &&
          v <= this.objKdPayout.target_percentage - this.objKdPayout.wala_percentage &&
          !!v
        )
          return true;
        return "Number has to be bigger than 0 and smaller or equal target" + this.objKdPayout.target_percentage;
      }],
      rule_wala_percentage: [(v) => {
        if (
          v > 0 &&
          v <= this.objKdPayout.target_percentage - this.objKdPayout.meron_percentage &&
          !!v
        )
          return true;
        return "Number has to be bigger than 0 and smaller or equal target " + this.objKdPayout.target_percentage;
      },]
    };
  },
  computed: {

    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_klakloukChannel", {
      loading: "getLoading",
      loadingResult: "getLoadingResult",
    }),
  },
  watch: {
    image1: function (newV) {
      if (newV) this.scanCard = '';
    },
    image2: function (newV) {
      if (newV) this.scanCard = '';
    },
    image3: function (newV) {
      if (newV) this.scanCard = '';
    },
    dealerInfo: function () {
      this.$nextTick(() => this.$refs.search.focus())
    },
    getDealerDialog: function (newV) {
      if (newV == false) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    barcodeDealer: function (newV) {

      this.barcodeDealer = '';
      if (newV.length == 8) {
        this._submitDealerBarcode(newV);
      }
      if (newV.length > 8) {
        this.barcodeDealer = '';
      }
      if (newV == '') {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    scanCard: function (newV) {
      if (newV.length == 4) {
        this._submitCardcode(newV);
      }
      if (newV.length > 4) {
        this.scanCard = '';
      }
      if (newV == 2888) {
        this.allowInput = false;
        setTimeout(() => {
          this.allowInput = true;
          this.$nextTick(() => this.$refs.search.focus());
        }, 1200);
        this.scanCard = '';
      }
    },
    socketChannelType: function () { },
    socketChannelCountdown: function (newV) {
      if(newV == 0)
      this.$nextTick(() => this.$refs.search.focus());
    },
    loading: function () { },
    loadingResult: function () { },
    channel: function (newV) {
      if (newV.is_open == true) this.resetCard();
    }
  },
  async created() {
    await this.initialDealer();
  },
  async mounted() {
    window.callInside = this;
    // this.$refs.search.focus()
    this.$nextTick(() => this.$refs.search.focus());
    // this.$socket.on("server-update-channel-info", data => {
    //   if (data) {
    //     this.channel.is_approved = true;
    //   }
    // });
  },
  methods: {
    async initialDealer() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        await this._submitDealerBarcode(dealerId.barcode)
      }
      else this._openDialogScanDealer(true);
    },
    moment,
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    reloadWindow(){
      window.location.reload();
    },
    focusSearch(){
      this.$nextTick(() => this.$refs.search.focus());
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    alertResetShoeDialog() {
      this.dialogResetShoe = true;
    },
    alertVoidShoeDialog() {
      this.dialogVoidShoe = true;
    },
    _resetShoe() {
      this.$emit("submitResetShoe");
      this.dialogResetShoe = false;
    },
    _voidShoe() {
      this.$emit("submitVoidShoe");
      this.dialogVoidShoe = false;
    },
    _submitDealerBarcode(barcode) {
      this.$emit("submitBarcodeDealer", barcode);
      this.barcodeDealer = '';
    },
    _submitCardcode(cardCode) {
      if (!this.getAllowScanCard) {
        if (this.dealerInfo && this.dealerInfo.position == 'dealer'){
        if(!cardCode == ''){
        this.$emit("submitCardCode", cardCode);
        this.allowInput = false;
        setTimeout(() => {
          this.allowInput = true;
          this.$nextTick(() => this.$refs.search.focus());
        }, 1200);
        this.scanCard = '';
        }
       }
      } else {
        this.$toastr.e("Waiting for previous card return back")
      }
      
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
        this.canUpdateStatus = false;
      }
    },

    _openDialogScanDealer(payload) {
      this.barcodeDealer = '';
      this.$emit("popUpDealer", payload);
    },

    _openDialogResult() {
      this.$emit("submitDynamicRes", true);
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text",
        };
      } else {
        return {
          status: "CLOSE",
          color: "red--text",
        };
      }
    },

    channelUpdateStatus(status) {
      if (status === true) {
        return {
          status: "CLOSE",
          desc: "SET Status TO",
          background: "red",
        };
      } else if (status === false) {
        return {
          status: "Please set RESULT",
          desc: "",
          background: "white",
        };
      } else {
        return {
          status: "OPEN",
          desc: "SET Status TO",
          background: "success",
        };
      }
    },

    _select() {
      this.dialogResult = true;
    },

    _selectGame() {
      this.$socket.emit("server-start-count-down", { status: "start" });
    },
    increaseColumn() {
      this.channel.column = this.channel.column + 1;
      this.$emit("submitUpdateChanel", this.channel);
    },
    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult() {
      // this.confirmDialog = true;
    },

    async _updateChanelStatusKlaklouk() {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer') {
        if ((this.channel.is_open == false || !this.canUpdateStatus || Boolean(this.socketChannelCountdown && this.socketChannelCountdown > 2)) == false) {
          this.canUpdateStatus = false;
          this.$emit("submitUpdateStatus", this.resultData);
         
          this.$nextTick(() => this.$refs.search.focus());
          setTimeout(this.letUpdateChanelStatus, 3000);
        }
      }
    },
    async _finishedCardKlaklouk() {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer')
      if(this.channel.is_last_game == true){
        if ((this.channel.is_open == false || !this.canUpdateStatus || Boolean(this.socketChannelCountdown && this.socketChannelCountdown > 2)) == false) {
          this.$emit("submitFinishedCard", this.resultData);
          this.canUpdateStatus = false;
          this.$nextTick(() => this.$refs.search.focus());
          setTimeout(this.letUpdateChanelStatus, 3000);
        }
      }
    },
    async _clearCard() {
      if (this.dealerInfo && this.dealerInfo.position == 'pr' || this.dealerInfo.position == 'pitbox')
        if (this.channel.is_open == false || this.channel.is_open == null) {
          this.$emit("submitClearCard", this.resultData);
        }
    },
    _preventCardInput() {
      this.allowInput = false;
    },
    _allowCardInput() {
      this.allowInput = true;
    },

    letUpdateChanelStatus() {
      this.canUpdateStatus = true;
    },
    correctColorPosition(position) {
      switch (position) {
        case 'dealer':
          return 'error';
        case 'pr':
          return 'primary';
        case 'pitbox':
          return 'success';
        default:
          break;

      }
    },

    ...mapMutations("$_klakloukChannel", ["LOADING", "UPDATE_KDPAYOUT"]),
    ...mapActions("$_klakloukChannel", ["resetCard"]),
  },
};
</script>

<style scoped>
.default-count-down {
  font-size: 100px !important;
  letter-spacing: 9px !important;
  font-weight: 800;
  text-align: center;
  margin-top: 80px;
}

.count-number-container {
  justify-content: right;
  margin-top: 10px;
}

.channel-status {
  font-weight: 900;
  font-size: 45px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
  text-align: center;
  margin-top: 60px;
}

.result-image {
  max-width: fit-content;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.res-img-containter {
  width: 385px;
  height: 180px;
  justify-content: center;
}

.confirm-btn {
  color: white !important;
  font-weight: 700;
}

.right-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #fb8c00 !important;
}

.green-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #013220 !important;
}

.sample>>>input {
  margin-top: 5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}

.update-chanel-btn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: -7px !important;
}

.update-chanel {
  margin-top: -2px !important;
}

.cancel-btn {
  color: white !important;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  background-color: #3f9542 !important;
}

.dealer-title {
  font-size: 30px;
  text-align: right;
  font-weight: 800;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 40px;
  text-decoration: underline;

}

.timer {
  font-size: 70px;
  text-align: center;
  font-weight: 800;
  margin-top: 40px;
  margin-bottom: 15px;
}

.live-channel-info-container {
  width: 100%;
  height: 440px;
  border: 1px solid black;
  border-radius: 3px;
  position: relative;
  margin-top: 70px;
}

.dealer-photo {
  /* width: 45%;
  height: 190px;
  object-fit: cover; */
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: 50px;
  margin-top: 15px;
}

.dealer-info-container {
  width: 100%;
  height: 260px;
  border: 1px solid black;
  border-radius: 3px;
  position: relative;
  margin-top: 50px;

}

.dealer-info {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: -7px;
  text-transform: uppercase;

}

.game-control-container {
  margin-top: 50px;
  padding-left: 15px
}

.dealer-name {
  /* font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 7px;
  margin-left:30px; */
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: -40px;
  padding-left: 5px;
}

.dealer-dob {
  /* font-size: 18px;
  text-align: center; */
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 30px;
}

.dealer-last-login {
  /* font-size: 18px;
  text-align: center; */
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 30px;
}

.dealer-changer {
  /* position: absolute;
  bottom: 0px;
  right: 0px */
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: 0px
}

.player-card-container {
  height: 300px;
  /* border-left: 1px solid black; */
  text-align: center;
  padding: 0px;
}

.dealer-card-container {
  height: 300px;
  /* border-right: 1px solid black; */
  text-align: center;
  padding: 0px;
}

.player-stand-card {
  margin-left: 35px;
  width: 150px;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}

.stand-card {
  width: 150px;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}

.player-vertical-card {
  width: 150px;
  height: 200px;
  margin-top: -10px;
  transform: rotate(270deg);
  position: absolute;
  left: 35.5%;
  border: 1px solid;
  border-radius: 5px;
}

.dealer-vertical-card {
  width: 150px;
  height: 200px;
  margin-top: -10px;
  transform: rotate(270deg);
  position: absolute;
  left: 35.5%;
  border: 1px solid;
  border-radius: 5px;
}

.dealer-stand-card {
  margin-left: 35px;
  width: 150px;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}

.type_player {
  justify-content: center !important;
  width: 100%;
}

.type-player-title {
  font-size: 20px !important;
  font-weight: 700;
  color: rebeccapurple;

}
</style>

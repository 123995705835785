<template>
  <v-card >
    <form-channel
      :channel="currentChannel.currentChannel"
    />
    <table-result
      :channel="currentChannel.currentChannel"
      :objResult="objResult"
      :loadingResult="loadingResult"
      v-on:submitResult="handleSubmitResult"
      v-on:cancelResult="handleCancelResult"
    />
  </v-card>
</template>

<script>
import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    formChannel,
    tableResult
  },
  data: () => {
    return {
      dataFromSocket:[],
      selectResult: {
        order: null,
        _id: null,
      },
      loading:false
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode"
    }),
    ...mapGetters("$_reverseLotvn", {
      currentChannel: "getCurrentChannel",
      objResult: "getResultTable",
      loadingResult: "getLoadingResult"
    })
  },

  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
      
    }
  },
  watch: {
    objResult: function () {
    },
    currentChannel:function () {
      
    },
  },
  async mounted() {
    
  },
  methods: {
    async handleSubmitResult(data) {
      await this.reverseResult({ vm: this, data: data });
      await this.fetchCurrentChannel();
    },
    async handleCancelResult(data) {
      await this.reverseCancelResult({ vm: this, data: data });
      await this.fetchCurrentChannel();
    },
    ...mapMutations("$_reverseLotvn", [ "SET_RESULT","RESULT_TABLE"]),
    ...mapActions("$_reverseLotvn", [
      "fetchCurrentChannel",
      "reverseResult",
      "reverseCancelResult",
    ])
  }
};
</script>

<style lang="scss" scoped></style>

const getList = (state) => state.list;
const getPopUp = (state) => state.popUp;
const getCurrentList = (state) => state.currentList;
const getDeleteItems = (state) => state.deleteItems;
const getLoading = (state) => state.loading;

export default {
	getLoading,
	getList,
	getPopUp,
	getCurrentList,
	getDeleteItems,
};

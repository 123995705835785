const getStatement = (state) => {
    return state.statement;
};

const getCountStatement = (state) => {
    return state.countStatement;
};
const getListAppMenu = (state) => state.listAppMenu;
const getLoading = (state) => state.loading
// const getPopUp = (state) => state.popUp;
// const getCurrentUser = (state) => state.currentUser;
// const getDeleteItems = (state) => state.deleteItems;

export default {
    getStatement,
    getCountStatement,
    getLoading,
    getListAppMenu,
    
};
const UPDATE_USERS = (state, data) => {
    state.users = data;
};
const UPDATE_POPUP = (state, data) => {
   
    if (!data) {
        state.currentUser = {
            user_name: null,
            password: 123456,
            account_info: null,
            // balance: null,
            role_id: null,
            enable:true,
            app_id: null,
            full_permission: false,
            selectedChannel:[1,2,3,4]
        }
    }
    state.popUp = data;
};
const UPDATE_POPUP_BANK_USER = (state, data) => {
    if (!data) {
        state.currentBankUser = {
            user_id: null,
            bank_id: null,
            account_name: null,
            account_number: null,
            account_info: null,
            is_active: true,
            is_enable: true,
            currency_type: null,
            order: null,
        }
    }
    state.popUpBankUser = data;
};
const UPDATE_CURRENT_USER = (state, data) => {
    state.currentUser = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};

const UPDATE_VIEW_WITHDRAW = (state, data) => {
    state.viewTransaction = data;
}

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

const UPDATE_VIEW_DEPOSIT = (state, data) => {
    state.depositTransaction = data;
}
const LIST_COMPANY = (state, data) => {
    state.listCompany = data;
};
const LIST_SENIOR = (state, data) => {
    state.listSenior = data;
};
const LIST_APP = (state, data) => {
    // let obj = {
    //     _id: "all",
    //     name: "All"
    // }
    // data.unshift(obj);
    state.listApp = data;
};
const LIST_MENU_BY_APP = (state, data) => {
    state.listMenuByApp = data;
};
const UPDATE_BANK_USER = (state, data) => {
	state.currentBankUser = data;
};
const LIST_TREE_MENU_BY_APP = (state, data) => {
    state.listTreeMenuByApp = data;
};
const UPDATE_SELECT_TREE_MENU = (state, data) => {
    state.treeMenuSelection = data
}
export default {
    UPDATE_LOADING,
    UPDATE_USERS,
    UPDATE_POPUP,
    UPDATE_POPUP_BANK_USER,
    UPDATE_CURRENT_USER,
    DELETE_ITEMS,
    UPDATE_VIEW_WITHDRAW,
    UPDATE_VIEW_DEPOSIT,
    LIST_COMPANY,
    LIST_SENIOR,
    LIST_APP,
    UPDATE_BANK_USER,
    LIST_MENU_BY_APP,
    LIST_TREE_MENU_BY_APP,
    UPDATE_SELECT_TREE_MENU
};

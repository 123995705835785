<template>
   <div class="card">
    <div class="card-body">
      <v-data-table
        style="font-family: 'khmer mef1'"
        class="elevation-1 table"
        item-key="_id"
        :headers="headers"
        :items="getApp && getApp.object ? getApp.object : []"
        :footer-props="footerProps"
        :server-items-length="getApp ? getApp.objectCount : null"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        @input="itemDelete"
        :loading="getLoading"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.is_enable`]="{ item }">
          <v-icon v-if="item.is_enable == true" small
            >mdi-checkbox-marked-circle-outline
          </v-icon>
          <v-icon v-else-if="item.is_enable === false" small
            >mdi-checkbox-blank-circle-outline
          </v-icon>
        </template>
        <!-- <template v-slot:top>
          <Header
            :darkmode="darkmode"
            :params="data"
            :dataDelete="getDeleteItems"
            :user-permission="userAccessPermission"
          />
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :params="data"
            :user-permission="userAccessPermission"
          />
        </template>
      </v-data-table>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :configApp="getCurrentApp"
        :dialog="getPopUp"
        :is-validate.sync="valid"
        :listCompany="getListUserCompany"
        :selection="getTreeMenuSelection"
        :treeMenu="getTreeMenuByApp"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="fetchPopUp(false)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    ActionComponents: () => import("./_components/action"),
    // Header: () => import("./_components/header.vue"),
    Dialog: () => import("./_components/dialog.vue"),
  },
  data: () => ({
    valid: true,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "api_player_link", "api_admin_link"],
      search: "",
    },
    loading: false,
    app_id:0,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("header.app_name"), align: "start", value: "name" },
        {
          text: this.$t("header.api_player_link"),
          value: "api_player_link",
          sortable: false,
        },
        {
          text: this.$t("header.api_admin_link"),
          value: "api_admin_link",
          sortable: false,
        },
        {
          text: this.$t("header.is_enable"),
          value: "is_enable",
          sortable: false,
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_menu", ["getTreeMenu"]),
    ...mapGetters("$_configApp", [
      "getApp",
      "getPopUp",
      "getCurrentApp",
      "getDeleteItems",
      "getListUserCompany",
      "getLoading",
      "getListUserSenior",
      "getTreeMenuByApp",
      "getTreeMenuSelection"
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit(payload) {
      this.loading = true;
      this.getCurrentApp.selection = payload;
      console.log("payload",payload);
      if (!this.getCurrentApp._id) {
        await this.createApp({ vm: this, data: this.getCurrentApp });
      } else {
        await this.updateApp({ vm: this, data: this.getCurrentApp });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListApp(this.data)
        : [];
      this.fetchListCompany();
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },
    ...mapMutations("$_configApp", ["DELETE_ITEMS", "UPDATE_APP"]),
    ...mapActions("$_configApp", [
      "fetchListApp",
      "fetchPopUp",
      "createApp",
      "fetchListCompany",
      "fetchListTreeMenuByApp",
      "updateApp",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_APP([]);
    next();
  },
};
</script>

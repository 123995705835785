// update data over statement

const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};

const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
const LIST_APP_MENU = (state, data) => {
    state.listAppMenu = data;
};

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    UPDATE_LOADING,
    LIST_APP_MENU,
};
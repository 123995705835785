<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div style="text-align: center; vertical-align: middle">
              <v-data-table
                :headers="headers"
                :items="getUsers ? getUsers.object : []"
                :footer-props="footerProps"
                item-key="_id"
                class="table table-striped"
                id="table-1"
                :server-items-length="
                  getUsers ? getUsers.objectCount.count : null
                "
                :page.sync="data.page"
                :items-per-page.sync="data.rowsPerPage"
                :sort-desc.sync="data.descendingFormat"
                :sort-by.sync="data.sortByFormat"
                @update:page="getData"
                @update:items-per-page="getData"
                @update:sort-desc="getData"
                :loading="getLoading"
                :value="getDeleteItems"
                @input="itemDelete"
                mobile-breakpoint="0"
              >
                <!-- <template v-slot:header="{ props }">
                  <tr>
                    <th
                      class="fit"
                      v-for="head in props.headers"
                      :key="head._id"
                      :class="head.col"
                    >
                      {{ head.text }}
                    </th>
                  </tr>
                </template> -->
                <template v-slot:[`item`]="{ item }">
                  <tr class="">
                    <td class="col-1 fit">
                      {{ item.user_name }}
                    </td>
                    <td class="col-1 fit">
                      {{ item.app_name }}
                    </td>
                    <td class="col-2 fit">
                      {{ item.account_info }}
                    </td>
                    <td class="col-2 fit">
                      {{ item.createdAt }}
                    </td>


                    <!--balance-->
                    <td class="col-1 fit">
                      <span
                        v-if="
                          item && item.role_id === '612a3fcc1a1fcead8871e822'
                        "
                      >
                      </span>
                      <span
                        v-if="
                          item &&
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 1
                        "
                      >
                        {{ currencyFormatUsd(item.balance) }} $
                      </span>
                      <span
                        v-if="
                          item &&
                          item.role_id !== '612a3fcc1a1fcead8871e822' &&
                          item.currency_type === 2
                        "
                      >
                        {{ currencyFormatKh(item.balance) }} ៛
                      </span>
                    </td>

                    <td class="col-1 fit">
                      {{ item.role_name }}
                    </td>
                    <td class="col-1 fit">
                      <a @click="_openDialogListIpUser(item)">
                        {{ item.public_ip }}
                      </a>
                    </td>
                    <td class="col-2 fit">
                      <ActionComponents
                        :darkmode="darkmode"
                        :item="item"
                        :dataDelete="getDeleteItems"
                        :params="data"
                        :userp="userp"
                        :user-permission="userAccessPermission"
                      />
                    </td>
                  </tr>
                </template>

                <template v-slot:top>
                  <Header
                    :darkmode="darkmode"
                    :params="data"
                    :dataDelete="getDeleteItems"
                    :user-permission="userAccessPermission"
                  />
                </template>
              </v-data-table>
            </div>
            <DialogListIpUserComponents
              :dialog="ipDialog"
              :listData="listIpUserData"
              @onCloseDialog="ipDialog = !ipDialog"
            />
            <Dialog
              :darkmode="darkmode"
              :loading="loading"
              :user="getCurrentUser"
              :dialog="getPopUp"
              :userp="userp"
              :optionRoles="optionRoles"
              :is-validate="valid"
              :listCompany="getListUserCompany"
              :listSenior="getListUserSenior"
              :listApp="getListApp"
              :selectedChannel="selectedChannel"
              v-on:submit="handleSubmit"
              v-on:handleListCompany="getListCompany"
              v-on:handleListSenior="getListSenior"
              v-on:onCloseDialog="closeDialogCreate"
            />
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
    DialogListIpUserComponents: () => import("./_components/DialogListIpUser"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      loader: false,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "user_name",
        "balance",
        "account_info",
        "enable",
        "updatedAt",
      ],
      search: "",
    },
    dialog: false,
    loader: false,
    loading: false,
    selectedUser: [],
    // suspendDialog: false,
    waiting: false,
    selectedChannel: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14"
    ],
    ipDialog: false,
    listIpUserData: [],
  }),
  computed: {
    headers() {
      return [
        {
          text: "User name",
          align: "center",
          sortable: false,
          value: "user_name",
          col: "col-1",
        },
        { text: "Website", value: "app_name", col: "col-1", align: "center" },
        { text: "Bank info", value: "account_info", col: "col-2", align: "center"},
        { text: "Created Date", value: "createdAt", col: "col-2", align: "center" },
        { text: "Balance", value: "balance", col: "col-1", align: "center" },
        { text: "Role", value: "role_name", col: "col-1", align: "center" },
        { text: "Ip Address", value: "public_ip", col: "col-1" , align: "center"},
        { text: "", value: "", col: "col-1" , align: "center",sortable: false,},
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
    ...mapGetters("$_user", [
      "getUsers",
      "getPopUp",
      "getCurrentUser",
      "getDeleteItems",
      "getLoading",
      "getListUserCompany",
      "getListUserSenior",
      "getListApp",
    ]),
    ...mapGetters("$_role", { optionRoles: "getAllRolesDependOnUser" }),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.fetchAllRolesDependOnUser();
    await this.getListCompany();
    await this.getListSenior();
    await this.fetchListApp();
  },
  watch: {
    userp: function () {},
    optionRoles: function () {},
  },
  methods: {
    _openDialogListIpUser(item) {
      this.getListIpUserData(item._id);
      this.ipDialog = true;
    },
    async getListIpUserData(_id) {
      let data = {
        user_id: _id,
      };
      await this.$request
        .post({
          url: "/users/getLoginIpByUser",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.listIpUserData = res.data.data;
            // this.waiting = false;
          }
        });
    },
    async handleSubmit() {
      this.loading = true;
      if (this.getCurrentUser.app_id == null) {
        this.getCurrentUser.app_id = "62e397ceae71cfa8782aa325";
      }
      if (!this.getCurrentUser._id) {
        if (this.getCurrentUser.account_info == null)
          this.getCurrentUser.account_info = "";
        if (this.getCurrentUser.role_id.child_role_name)
          this.getCurrentUser.role_id =
            this.getCurrentUser.role_id.child_role_id;
        if (this.userp && this.userp.role_id == "612a3fcc1a1fcead8871e822") {
          this.getCurrentUser.role_id == "61829d84e64b1b17688326a3"
            ? (this.getCurrentUser.currency_type = 1)
            : this.getCurrentUser.role_id == "61829d75e64b1b1768832699"
            ? (this.getCurrentUser.currency_type = 2)
            : "";
        }
        this.getCurrentUser.user_name =
          this.getCurrentUser.user_name.toLowerCase();
        await this.createUser({ vm: this, data: this.getCurrentUser });
      } else {
       await this.updateUser({ vm: this, data: this.getCurrentUser });
      }
      await this.getData();
      this.loading = false;
    },

    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListUsers(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    async closeDialogCreate() {
      this.fetchPopUp(false);
      await this.fetchAllRolesDependOnUser();
    },
    async getListCompany() {
      await this.fetchListCompany();
    },
    async getListSenior() {
      await this.fetchListSenior();
    },

    async _updateUserSuspendStatus() {
      this.waiting = true;
      let data = {
        enable: !this.selectedUser.enable,
        userId: this.selectedUser._id, 
        // channel_type : this.channel_type
      };
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData();
            this._closeDialogSuspend();
            this.waiting = false;
          } else {
            this.getData();
            this._closeDialogSuspend();
            this.waiting = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      // this.targetChannel = [];
      // this.channel_type = null
      // this.confirmDialog = false;
    },

    ...mapMutations("$_user", [
      "UPDATE_CURRENT_USER",
      "DELETE_ITEMS",
      "UPDATE_USERS",
      "UPDATE_USER",
    ]),
    ...mapMutations("$_role", ["UPDATE_ROLES_DEPEND_ON_USER"]),
    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", [
      "fetchPopUp",
      "createUser",
      "fetchCurrentUser",
      "updateUser",
      "fetchListUsers",
      "fetchListCompany",
      "fetchListSenior",
      "fetchListApp",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style>
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
.table:not(.table-sm):not(.table-md):not(.dataTable) td,
.table:not(.table-sm):not(.table-md):not(.dataTable) th {
  height: 45px !important;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
  vertical-align: middle !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
}
</style>

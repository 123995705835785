<template>
  <div>
    <v-row
      ><v-col cols="2" class="mt-4 pt-3"> Channel Type:</v-col>
      <v-col cols="3">
        <v-select
          :items="items"
          v-model="selectedChanel"
          label="Channel Number"
          @input="_selectChannel()"
          solo
        ></v-select>
      </v-col>

    </v-row>
    <v-tabs color="deep-purple accent-4" right>
      <v-tab @click="_gotoOption(1)">Option Default</v-tab>
      <!-- <v-tab @click="_gotoOption(2)">VIP 1</v-tab>
      <v-tab @click="_gotoOption(3)">VIP 2</v-tab> -->

      <v-tab-item v-for="n in 1" :key="n">
        <v-col cols-12>
                <v-col
        cols="5"
        v-if="getRadioOption && getRadioOption.object"
        class="pl-2"
      >
      <v-row>
      <div class="mt-2 ml-2 pt-2 px-5" style="background-color:#1976D2;color:white; height:50px; border-radius:20px">Current Option <span style="color:orange; font-weight:bold; font-size:20px;">{{getCurrentRadioOption &&
          getCurrentRadioOption.object &&
          getCurrentRadioOption.object[0].option_num ? getCurrentRadioOption.object[0].option_num :"loading..." }}</span></div>
        <v-radio-group v-model="radioGroup" class="mt-1 pl-3 pt-3">
          <v-row class="mt-1 pl-3 ">
            <v-radio
              v-for="(n, index) in getRadioOption.object"
              :key="index"
              :label="n.name"
              :value="n.option_num"
              class="px-3 pt-0 mt-0"
              :style="
                index == getRadioOption.object.length - 1
                  ? 'margin-top:-9px!important;'
                  : ''
              "
            ></v-radio>
          </v-row>
        </v-radio-group>
        <v-spacer></v-spacer>
         <v-btn @click="_saveOption" color="success" class=" ml-3 mt-3 px-3">
          Save
        </v-btn>
        </v-row>
      </v-col>
        </v-col>
    <v-data-table
      v-if="
        radioGroup == 2
      "
      style="font-family: 'khmer mef1'"
      class="elevation-1"
      item-key="_id"
      :headers="headers"
      :items="getListOption && getListOption.object ? getListOption.object : []"
      :footer-props="footerProps"
      :server-items-length="1"
      :loading="getLoading"
      :hide-default-footer="true"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header :darkmode="darkmode" :params="data" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="_editOptionDefault(item)" color="primary">
          Edit <v-icon color="orange" small class="ml-2">mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>   
      </v-tab-item>
    </v-tabs>
    <br />
    <div
      v-if="
        radioGroup == 2
      "
      style="width:100%; text-align:right; padding-top:25px; "
    >
      <v-btn @click="openCreatedialog" color="success">
        <v-icon color="white" small class="pr-2">mdi-plus-circle</v-icon> Create
        Custom
      </v-btn>
      <!-- :disabled="dataDelete.length < 1" -->
      <v-btn
        class="ml-3"
        @click="dialogDelete = !dialogDelete"
        :disabled="getDeleteItems.length < 1"
        color="error "
      >
        <v-icon color="white" small class="pr-2">mdi-plus-circle</v-icon> Delete
        Selected
      </v-btn>
    </div>

    <v-data-table
      v-if="
        radioGroup == 2
      "
      style="font-family: 'khmer mef1'"
      class="elevation-1 mt-2 "
      item-key="_id"
      :headers="headersCreateDefault"
      :items="
        getListOptionDefaultCustom && getListOptionDefaultCustom.object
          ? getListOptionDefaultCustom.object
          : []
      "
      :footer-props="footerProps"
      :server-items-length="1"
      :loading="getLoadingCustom"
      :hide-default-footer="true"
      mobile-breakpoint="0"
      :value="getDeleteItems"
      @input="itemDelete"
      show-select
    >
      <template v-slot:top>
        <Header :darkmode="darkmode" :params="data" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="_editOptionDefault(item)" color="primary">
          Edit <v-icon color="orange" small class="ml-2">mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Custom -->
    <v-data-table
      v-if="
       radioGroup == 3
      "
      style="font-family: 'khmer mef1'"
      class="elevation-1"
      item-key="_id"
      :headers="headersCustom"
      :items="
        getListOptionCustom && getListOptionCustom.object
          ? getListOptionCustom.object
          : []
      "
      :footer-props="footerProps"
      :server-items-length="1"
      :loading="getLoading"
      :hide-default-footer="true"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header :darkmode="darkmode" :params="data" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="_editOptionCustom(item)" color="primary">
          Edit <v-icon color="orange" small class="ml-2">mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div
      v-if="
        radioGroup == 3
      "
      style="width:100%; text-align:right; padding-top:25px; "
    >
      <v-btn @click="openCustomCreatedialog" color="success">
        <v-icon color="white" small class="pr-2">mdi-plus-circle</v-icon> Create
        Custom
      </v-btn>
      <!-- :disabled="dataDelete.length < 1" -->
      <v-btn
        class="ml-3"
        @click="dialogCustomDelete = !dialogCustomDelete"
        :disabled="getCustomDeleteItems.length < 1"
        color="error "
      >
        <v-icon color="white" small class="pr-2">mdi-plus-circle</v-icon> Delete
        Selected
      </v-btn>
    </div>

    <v-data-table
      v-if="
        radioGroup == 3
      "
      style="font-family: 'khmer mef1'"
      class="elevation-1 mt-2 "
      item-key="_id"
      :headers="headersCustom"
      :items="
        getListCreateOptionCustom && getListCreateOptionCustom.object
          ? getListCreateOptionCustom.object
          : []
      "
      :footer-props="footerProps"
      :server-items-length="1"
      :loading="getLoadingCustom"
      :hide-default-footer="true"
      mobile-breakpoint="0"
      :value="getCustomDeleteItems"
      @input="itemCustomDelete"
      show-select
    >
      <template v-slot:top>
        <Header :darkmode="darkmode" :params="data" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="_editOptionCustom(item)" color="primary">
          Edit <v-icon color="orange" small class="ml-2">mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <div v-if="dialogEdit">
      <v-dialog
        :value="dialogEdit"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn icon @click="dialogEdit = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="pr-5"> Update Channel: </v-toolbar-title
            ><v-toolbar-title
              style="font-weight:bold; padding:5px 15px; background-color:#013220; color:white; border-radius:55px"
              >{{ preUpdateData.channel_type }}
            </v-toolbar-title>

            <v-toolbar-title
              v-if="preUpdateData.fight_no != 0"
              class="pl-5 pr-5"
            >
              Fight no: </v-toolbar-title
            ><v-toolbar-title
              v-if="preUpdateData.fight_no != 0"
              style="font-weight:bold; padding:5px 15px; background-color:#63c76f; color:white; border-radius:55px"
              >{{ preUpdateData.fight_no }}
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text color="success" @click="handleSubmit()">
                save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Loading :visible="loading" />
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateData.percentage_wala_min"
                      :label="$t('input.percentage_wala_min')"
                      type="number"
                      suffix="%"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateData.percentage_wala_max"
                      :label="$t('input.percentage_wala_max')"
                      type="number"
                      suffix="%"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateData.percentage_meron_min"
                      :label="$t('input.percentage_meron_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateData.percentage_meron_max"
                      :label="$t('input.percentage_meron_max')"
                      type="number"
                      suffix="%"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateData.target_usd"
                      :label="$t('input.target_usd')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                   {{ $t('input.target_kh') }} : <span class="money_target"> {{calKh(preUpdateData.target_usd)? calKh(preUpdateData.target_usd) : 0}}</span>
                  </v-col>
                   <v-col cols="12">
                   {{ $t('input.target_vn') }} :  <span class="money_target">{{calVn(preUpdateData.target_usd) ? calVn(preUpdateData.target_usd)  : 0}}</span>
                
                  </v-col>
                  <v-col cols="12">
                   Target ฿ :  <span class="money_target">{{calVn(preUpdateData.target_usd) ? calRp(preUpdateData.target_usd)  : 0}}</span>
                
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialogCreate">
      <v-dialog
        :value="dialogCreate"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn icon @click="dialogCreate = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="pr-5">
              Create New Default Option </v-toolbar-title
            ><v-toolbar-title
              style="font-weight:bold; padding:5px 15px; background-color:#013220; color:white; border-radius:55px"
              >{{ selectedChanel ? selectedChanel : 1 }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text color="success" @click="handleSubmitCreate()">
                Create
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Loading :visible="loading" />
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createData.fight_no"
                      :label="$t('input.fight_no')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createData.percentage_wala_min"
                      :label="$t('input.percentage_wala_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createData.percentage_wala_max"
                      :label="$t('input.percentage_wala_max')"
                      type="number"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createData.percentage_meron_min"
                      :label="$t('input.percentage_meron_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createData.percentage_meron_max"
                      :label="$t('input.percentage_meron_max')"
                      type="number"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createData.target_usd"
                      :label="$t('input.target_usd')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12">
                   {{ $t('input.target_kh') }} : <span class="money_target"> {{calKh(createData.target_usd)? calKh(createData.target_usd) : 0}}</span>
                  </v-col>
                  <v-col cols="12">
                   {{ $t('input.target_vn') }} :  <span class="money_target">{{calVn(createData.target_usd) ? calVn(createData.target_usd)  : 0}}</span>
                
                  </v-col>
                  <v-col cols="12">
                   target ฿ :  <span class="money_target">{{calVn(createData.target_usd) ? calRp(createData.target_usd)  : 0}}</span>
                
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialogDelete">
      <v-dialog :value="dialogDelete" persistent max-width="500px">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeDelete">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="eventDelete">Okay</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Custom Dialog -->
    <div v-if="dialogCustomCreate">
      <v-dialog
        :value="dialogCustomCreate"
        persistent
        transition="dialog-bottom-transition"
        style="z-index: 999999;"
      >
        <v-card>
          <v-toolbar>
            <v-btn icon @click="dialogCustomCreate = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="pr-5">
              Create New Custom Option </v-toolbar-title
            ><v-toolbar-title
              style="font-weight:bold; padding:5px 15px; background-color:#013220; color:white; border-radius:55px"
              >{{ selectedChanel ? selectedChanel : 1 }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text color="success" @click="handleSubmitCustomCreate()">
                Create
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Loading :visible="loading" />
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.fight_no"
                      :label="$t('input.fight_no')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.percentage_wala_min"
                      :label="$t('input.percentage_wala_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.percentage_wala_max"
                      :label="$t('input.percentage_wala_max')"
                      type="number"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.percentage_meron_min"
                      :label="$t('input.percentage_meron_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.percentage_meron_max"
                      :label="$t('input.percentage_meron_max')"
                      type="number"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.percentage_wala_start"
                      :label="$t('input.percentage_wala_start')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.percentage_meron_start"
                      :label="$t('input.percentage_meron_start')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.betting_frequency"
                      :label="$t('input.betting_frequency')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.target_usd"
                      :label="$t('input.target_usd')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createCustomData.amount_max"
                      label="Amount Max Usd"
                      type="number"
                      :rules="rules.amount_max_create"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    Amount Max Kh: <span class="money_target"> {{calKh(createCustomData.amount_max) ?calKh(createCustomData.amount_max):0 }}</span>
                  </v-col>
                    <v-col cols="12">
                   {{ $t('input.target_kh') }} : <span class="money_target"> {{calKh(createCustomData.target_usd)? calKh(createCustomData.target_usd) : 0}}</span>
                  </v-col>
                  <v-col cols="12">
                   {{ $t('input.target_vn') }} : <span class="money_target"> {{calVn(createCustomData.target_usd)? calVn(createCustomData.target_usd) : 0}}</span>
                  </v-col>
                  <v-col cols="12">
                   target ฿ : <span class="money_target"> {{calVn(createCustomData.target_usd)? calRp(createCustomData.target_usd) : 0}}</span>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialogCustomEdit">
      <v-dialog
        :value="dialogCustomEdit"
        persistent
        transition="dialog-bottom-transition"
        style="z-index: 999999;"
      >
        <v-card>
          <v-toolbar>
            <v-btn icon @click="dialogCustomEdit = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="pr-5"> Update Channel: </v-toolbar-title
            ><v-toolbar-title
              style="font-weight:bold; padding:5px 15px; background-color:#013220; color:white; border-radius:55px"
              >{{ preUpdateCustomData.channel_type }}
            </v-toolbar-title>

            <v-toolbar-title
              v-if="preUpdateCustomData.fight_no != 0"
              class="pl-5 pr-5"
            >
              Fight no: </v-toolbar-title
            ><v-toolbar-title
              v-if="preUpdateCustomData.fight_no != 0"
              style="font-weight:bold; padding:5px 15px; background-color:#63c76f; color:white; border-radius:55px"
              >{{ preUpdateCustomData.fight_no }}
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text color="success" @click="handleCustomEditSubmit()">
                save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Loading :visible="loading" />
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.percentage_wala_min"
                      :label="$t('input.percentage_wala_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.percentage_wala_max"
                      :label="$t('input.percentage_wala_max')"
                      type="number"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.percentage_meron_min"
                      :label="$t('input.percentage_meron_min')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.percentage_meron_max"
                      :label="$t('input.percentage_meron_max')"
                      type="number"
                      :rules="[numberRuleMax]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.percentage_meron_start"
                      :label="$t('input.percentage_meron_start')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.percentage_wala_start"
                      :label="$t('input.percentage_wala_start')"
                      type="number"
                      :rules="[numberRuleMin]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.betting_frequency"
                      :label="$t('input.betting_frequency')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.target_usd"
                      :label="$t('input.target_usd')"
                      type="number"
                      :rules="[numberRuleLarge]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="preUpdateCustomData.amount_max"
                      label="amount max usd "
                      type="number"
                      :rules="rules.amount_max"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    Amount Max Kh: <span class="money_target"> {{calKh(preUpdateCustomData.amount_max)}}</span>
                  </v-col>
                  <v-col cols="12">
                    {{ $t('input.target_kh')}} : <span class="money_target"> {{calKh(preUpdateCustomData.target_usd)}}</span>
                  </v-col>
                  <v-col cols="12">
                   {{ $t('input.target_vn') }} : <span class="money_target"> {{calVn(preUpdateCustomData.target_usd)? calVn(preUpdateCustomData.target_usd) : 0}}</span>
                  </v-col>
                  <v-col cols="12">
                   target ฿ : <span class="money_target"> {{calVn(preUpdateCustomData.target_usd)? calRp(preUpdateCustomData.target_usd) : 0}}</span>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialogCustomDelete">
      <v-dialog :value="dialogCustomDelete" persistent max-width="500px">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to delete this Custom item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeCustomDelete">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="eventCustomDelete">Okay</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    rules: {
      type: Object,
      default: function() {
        return {
          amount_max: [
            (v) => !!v || 'Input is required',
            (v) => !(v && parseFloat(v) <= 0) || 'Input must from 1 to ' + this.preUpdateCustomData.target_usd ,
            (v) => !(v && parseFloat(v) > parseFloat( this.preUpdateCustomData.target_usd) ) || 'Input must from 0 to ' + this.preUpdateCustomData.target_usd,
           // v => /^-?[0-9]+$/.test(v) || 'input is must be integer'
          ],
          amount_max_create: [
            (v) => !!v || 'Input is required',
            (v) => !(v && parseFloat(v) <= 0) || 'Input must from 1 to ' + this.createCustomData.target_usd ,
            (v) => !(v && parseFloat(v) > parseFloat( this.createCustomData.target_usd) ) || 'Input must from 0 to ' + this.preUpdateCustomData.target_usd,
           // v => /^-?[0-9]+$/.test(v) || 'input is must be integer'
          ]
        };
      },
    },
  },  
  data: () => ({
    valid: true,
    items: [1, 2, 3, 4, 15, 16],
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    loading: false,
    data: {
      channel_type: 1,
      vip_option: 1
    },
    selectedChanel: 1,
    optionDefault: [],
    dialogEdit: false,
    preUpdateData: [
      {
        percentage_wala_min: 0,
        percentage_wala_max: 0,
        percentage_meron_min: 0,
        percentage_meron_max: 0,
        target_kh: 0,
        target_usd: 0,
        target_tb: 0,
        target_vn: 0,
        target_rp: 0,
      },
    ],
    preUpdateCustomData: [
      {
        percentage_wala_min: 0,
        percentage_wala_max: 0,
        percentage_meron_min: 0,
        percentage_meron_max: 0,
        percentage_wala_start: 0,
        percentage_meron_start: 0,
        target_kh: 0,
        target_usd: 0,
        target_tb: 0,
        target_vn: 0,
        target_rp: 0,
        betting_frequency: 0,
        amount_max: 0,
      },
    ],
    amount_max: 6, 
    getDeleteItems: [],
    getCustomDeleteItems: [],
    dialogCustomDelete: false,
    numberRuleMin: (v) => {
      if (!isNaN(parseFloat(v)) && v > 0 && v <= 100 &&  !!v) return true;
      return "Number has to be bigger than 0 and smaller than 100";
    },
    numberRuleMax: (v) => {
      if (!isNaN(parseFloat(v)) && v > 0 && v <= 195 &&  !!v) return true;
      return "Number has to be bigger than 0 and smaller than 195";
    },
    numberRuleLarge: (v) => {
      if (!isNaN(parseFloat(v)) && v > 0 &&  !!v) return true;
      return "Number has to be bigger than 0";
    },
    numberRuleAmountMax: (v) => {
      let d = this.getTarget();
      if (!isNaN(parseFloat(v)) && v > 0 && v <= parseFloat(d) && !!v) return true;
      return "Number has to be bigger than 0";
    },
    createData: [],
    createCustomData: [],
    dialogCreate: false,
    dialogDelete: false,
    radioGroup: 0,
    dialogCustomCreate: false,
    dialogCustomEdit: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("input.percentage_wala_min"),
          align: "start",
          value: "percentage_wala_min",
        },
        {
          text: this.$t("input.percentage_wala_max"),
          value: "percentage_wala_max",
          sortable: false,
        },
        {
          text: this.$t("input.percentage_meron_min"),
          align: "start",
          value: "percentage_meron_min",
        },
        {
          text: this.$t("input.percentage_meron_max"),
          value: "percentage_meron_max",
          sortable: false,
        },
        {
          text: this.$t("header.target_usd"),
          value: "target_usd",
          sortable: false,
        },
        {
          text: this.$t("header.channel_type"),
          align: "start",
          value: "channel_type",
        },
        {
          text: this.$t("header.fight_no"),
          value: "fight_no",
          sortable: false,
        },
          {
          text: this.$t("header.vip_option"),
          align: "start",
          value: "vip_option",
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    headersCreateDefault() {
      return [
        {
          text: this.$t("header.channel_type"),
          align: "start",
          value: "channel_type",
        },
        {
          text: this.$t("header.fight_no"),
          value: "fight_no",
          sortable: false,
        },

        {
          text: this.$t("input.percentage_wala_min"),
          align: "start",
          value: "percentage_wala_min",
        },
        {
          text: this.$t("input.percentage_wala_max"),
          value: "percentage_wala_max",
          sortable: false,
        },
        {
          text: this.$t("input.percentage_meron_min"),
          align: "start",
          value: "percentage_meron_min",
        },
        {
          text: this.$t("input.percentage_meron_max"),
          value: "percentage_meron_max",
          sortable: false,
        },
        {
          text: this.$t("header.target_usd"),
          value: "target_usd",
          sortable: false,
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    headersCustom() {
      return [
        {
          text: this.$t("header.channel_type"),
          align: "start",
          value: "channel_type",
        },
        {
          text: this.$t("header.fight_no"),
          value: "fight_no",
          sortable: false,
        },

        {
          text: this.$t("input.percentage_wala_min"),
          align: "start",
          value: "percentage_wala_min",
        },
        {
          text: this.$t("input.percentage_wala_max"),
          value: "percentage_wala_max",
          sortable: false,
        },
        {
          text: this.$t("input.percentage_meron_min"),
          align: "start",
          value: "percentage_meron_min",
        },
        {
          text: this.$t("input.percentage_meron_max"),
          value: "percentage_meron_max",
          sortable: false,
        },
        {
          text: this.$t("header.target_usd"),
          value: "target_usd",
          sortable: false,
        },
        {
          text: this.$t("input.percentage_meron_start"),
          value: "percentage_meron_start",
          sortable: false,
        },
        {
          text: this.$t("input.percentage_wala_start"),
          value: "percentage_wala_start",
          sortable: false,
        },
        {
          text: this.$t("input.betting_frequency"),//betting_frequency
          align: "start",
          value: "betting_frequency",
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_optionOperation", [
      "getListOption",
      "getLoading",
      "getListOptionDefaultCustom",
      "getLoadingCustom",
      "getRadioOption",
      "getCurrentRadioOption",
      "getListOptionCustom",
      "getListCreateOptionCustom",
    ]),
  },
  async created() {
    await this.getData();
  },
  mounted() {
    this.$refs.form.validate();
  },
  watch: {
    getCurrentRadioOption: function() {},
    preUpdateCustomData: function(val){
      console.log("val",val);
    }
  },
  methods: {
    calKh(data){
      return data *4000;
    },
  
    calTb(data){
      return data *36;
    },
    calVn(data){
      return data *23490;
    },
    calRp(data){
      return data *15000;
    },
    getTarget(){
      return this.preUpdateCustomData.target_usd;
    },
    _gotoOption(selectOption){
      this.data.vip_option = selectOption;
      this.getData();
    },
    async _saveOption() {
      let updateData = {
        channel_type: this.selectedChanel,
        option_num: this.radioGroup,
        vip_option:  this.data.vip_option 
      };
      await this.updateSelectedOptions({
        vm: this,
        data: updateData,
      });
      await this.getData();
    },
    async getRadioOptionData() {
      try {
        let data = {
          vip_option: parseInt(this.data.vip_option ? this.data.vip_option : 1),
          channel_type: parseInt(this.selectedChanel ? this.selectedChanel : 1),
          option_num: parseInt(this.radioGroup),
        };
        await this.fetchRadioOption(data);
      } catch {
        // console.log("handle error");
      }
    },
    async _selectChannel() {
      await this.getData();
    },
    _editOptionDefault(item) {
      this.dialogEdit = true;
      this.preUpdateData = item;
    },
    _editOptionCustom(item) {
      this.dialogCustomEdit = true;
      this.preUpdateCustomData = item;
    },
    async handleSubmit() {
      this.$refs.form.validate();
      this.loading = true;

      let updateData = {
        percentage_wala_min: parseInt(this.preUpdateData.percentage_wala_min),
        percentage_wala_max: parseInt(this.preUpdateData.percentage_wala_max),
        percentage_meron_min: parseInt(this.preUpdateData.percentage_meron_min),
        percentage_meron_max: parseInt(this.preUpdateData.percentage_meron_max),
       
        target_usd: parseInt(this.preUpdateData.target_usd),
        target_kh: parseInt(this.preUpdateData.target_usd) * 4000,
        target_tb: parseInt(this.preUpdateData.target_usd) * 36,
        target_vn: parseInt(this.preUpdateData.target_usd) * 23490,
        target_rp: parseInt(this.preUpdateData.target_usd) * 15000,
        is_base: this.preUpdateData.is_base,
        channel_type: parseInt(this.preUpdateData.channel_type),
        fight_no: parseInt(this.preUpdateData.fight_no),
      };
      if (this.$refs.form.validate()) {
        await this.updateOptionDefault({
          vm: this,
          data: updateData,
          _id: this.preUpdateData._id,
        });
        await this.getData();
        this.dialogEdit = false;
        this.loading = false;
      }
    },
    // Edit Custom
    async handleCustomEditSubmit() {
      this.$refs.form.validate();
      this.loading = true;
      let updateData = {
        percentage_wala_min: parseInt(
          this.preUpdateCustomData.percentage_wala_min
        ),
        percentage_wala_max: parseInt(
          this.preUpdateCustomData.percentage_wala_max
        ),
        percentage_meron_min: parseInt(
          this.preUpdateCustomData.percentage_meron_min
        ),
        percentage_meron_max: parseInt(
          this.preUpdateCustomData.percentage_meron_max
        ),
        percentage_wala_start: parseInt(
          this.preUpdateCustomData.percentage_wala_start
        ),
        percentage_meron_start: parseInt(
          this.preUpdateCustomData.percentage_meron_start
        ),
        betting_frequency: parseInt(
          this.preUpdateCustomData.betting_frequency
        ),
        amount_max: parseInt(
          this.preUpdateCustomData.amount_max
        ),
        target_kh: parseInt(this.preUpdateCustomData.target_usd) *4000,
        target_usd: parseInt(this.preUpdateCustomData.target_usd),
        target_tb: parseInt(this.preUpdateCustomData.target_usd) *36,
        target_vn: parseInt(this.preUpdateCustomData.target_usd) * 23490,
        target_rp: parseInt(this.preUpdateCustomData.target_usd) * 15000,
        is_base: this.preUpdateCustomData.is_base,
        channel_type: parseInt(this.preUpdateCustomData.channel_type),
        fight_no: parseInt(this.preUpdateCustomData.fight_no),
        vip_option: this.data.vip_option,
      };
      if (this.$refs.form.validate()) {
        await this.updateOptionCustom({
          vm: this,
          data: updateData,
          _id: this.preUpdateCustomData._id,
        });
        await this.getData();
        this.dialogCustomEdit = false;
        this.loading = false;
        this.preUpdateCustomData = [];
      }
    },
    async handleSubmitCreate() {
     
      this.$refs.form.validate();
      this.loading = true;
      let tmpCreatedData = {
        percentage_wala_min: parseInt(this.createData.percentage_wala_min),
        percentage_wala_max: parseInt(this.createData.percentage_wala_max),
        percentage_meron_min: parseInt(this.createData.percentage_meron_min),
        percentage_meron_max: parseInt(this.createData.percentage_meron_max),
        target_usd: parseInt(this.createData.target_usd),
        target_kh: parseInt(this.createData.target_usd) * 4000,
        target_tb: parseInt(this.createData.target_usd) * 36,
        target_vn: parseInt(this.createData.target_usd) * 23490,
        target_rp: parseInt(this.createData.target_usd) * 15000,
        is_base: false,
        channel_type: this.selectedChanel ? this.selectedChanel : 1,
        fight_no: parseInt(this.createData.fight_no),
        vip_option: this.data.vip_option,
      };
      //  console.log(tmpCreatedData);
      if (this.$refs.form.validate()) {
        await this.createOptionDefaultCustom({
          vm: this,
          data: tmpCreatedData,
        });
        await this.getData();
        this.dialogCreate = false;
        this.loading = false;
      }
    },
    async handleSubmitCustomCreate() {
      this.$refs.form.validate();
      this.loading = true;
      let tmpCreatedData = {
        percentage_wala_min: parseInt(
          this.createCustomData.percentage_wala_min
        ),
        percentage_wala_max: parseInt(
          this.createCustomData.percentage_wala_max
        ),
        percentage_meron_min: parseInt(
          this.createCustomData.percentage_meron_min
        ),
        percentage_meron_max: parseInt(
          this.createCustomData.percentage_meron_max
        ),
        percentage_wala_start: parseInt(
          this.createCustomData.percentage_wala_start
        ),
        percentage_meron_start: parseInt(
          this.createCustomData.percentage_meron_start
        ),
        betting_frequency: parseInt(
          this.createCustomData.betting_frequency
        ),
        amount_max: parseInt(
          this.createCustomData.amount_max
        ),
        vip_option: this.data.vip_option,
        target_usd: parseInt(this.createCustomData.target_usd),
        target_kh: parseInt(this.createCustomData.target_usd) * 4000,
        target_tb: parseInt(this.createCustomData.target_usd) * 36,
        target_vn: parseInt(this.createCustomData.target_usd) * 23490,
        target_rp: parseInt(this.createCustomData.target_usd) * 15000,
        is_base: false,
        channel_type: this.selectedChanel ? this.selectedChanel : 1,
        fight_no: parseInt(this.createCustomData.fight_no),
      };
      
      if (this.$refs.form.validate()) {
        await this.createNewOptionCustom({
          vm: this,
          data: tmpCreatedData,
        });
        await this.getData();
        this.dialogCustomCreate = false;
        this.loading = false;
        this.createCustomData = [];
      }
    },
    openCreatedialog() {
      this.dialogCreate = true;
    },
    openCustomCreatedialog() {
      this.dialogCustomCreate = true;
    },
    async getData() {
      if (this.selectedChanel) this.data.channel_type = this.selectedChanel;
      else this.data.channel_type = 1;
      await this.fetchListOptions(this.data);
      await this.fetchListOptionsDefaultCustom(this.data);
      await this.getRadioOptionData();
      await this.getCurrentSelectOption();
      await this.fetchOptionCustom(this.data);
      await this.fetchCreateOptionCustom(this.data);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeCustomDelete() {
      this.dialogCustomDelete = false;
    },
    itemDelete(e) {
      const items = e.map(function(e) {
        return { _id: e._id };
      });
      this.getDeleteItems = items;
    },
    itemCustomDelete(e) {
      const items = e.map(function(e) {
        return { _id: e._id };
      });
      this.getCustomDeleteItems = items;
    },
    async eventDelete() {
      await this.removeOptionDefault({
        vm: this,
        data: { deleteItems: this.getDeleteItems },
      });
      await this.getData();
      this.dialogDelete = false;
    },
    async eventCustomDelete() {
      await this.removeOptionCustom({
        vm: this,
        data: { deleteItems: this.getCustomDeleteItems },
      });
      await this.getData();
      this.dialogCustomDelete = false;
      this.getCustomDeleteItems = [];
    },
    async getCurrentSelectOption() {
      let data = {
        channel_type: parseInt(this.selectedChanel ? this.selectedChanel : 1),
        vip_option: this.data.vip_option
      };
      await this.fetchCurrentRadioOption(data);
      this.radioGroup =
        this.getCurrentRadioOption && this.getCurrentRadioOption.object
          ? this.getCurrentRadioOption.object[0].option_num
          : 0;
    },

    ...mapMutations("$_optionOperation", [
      "UPDATE_LIST_OPTION",
      "UPDATE_LOADING",
      "UPDATE_LIST_OPTION_DEFAULT_CUSTOM",
      "UPDATE_RADIO_OPTION",
      "UPDATE_CURRENT_RADIO_OPTION",
      "UPDATE_LIST_CUSTOM_OPTION",
      "UPDATE_LIST_CREATE_CUSTOM_OPTION",
    ]),
    ...mapActions("$_optionOperation", [
      // fetch Data
      "fetchListOptions",
      "fetchListOptionsDefaultCustom",
      "createOptionDefaultCustom",
      "createNewOptionCustom",
      "removeOptionDefault",
      "fetchRadioOption",
      "fetchCurrentRadioOption",
      "fetchOptionCustom",
      "fetchCreateOptionCustom",
      "removeOptionCustom",
      // update
      "updateSelectedOptions",
      "updateOptionDefault",
      "updateOptionCustom",
    ]),
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none  !important;;
  margin: 0!important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield!important;
}
.money_target{
  font-size:20px;
  font-weight:bold;
}
</style>
/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchListStatement = async ({ commit }, data) => {
    const response = await request.post({
        url: 'bankStatement/getDepositPending',
        data
    });
    console.log('response:', response.data.data);
    if (response.data.code) {
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
    }
}

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentBankStatement = async ({commit}, payload) => {
    console.log("payload",payload)
    commit('UPDATE_CURRENT_BANK_STATEMENT', payload);
};

const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'cock-whitelist/remove',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchListStatement,
    fetchPopUp,
    fetchCurrentBankStatement,
    deleteData,
};
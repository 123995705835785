import historyByFightLotto from '@/modules/historyByFightLotto';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightLotto',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightLotto,
        name: 'historyByFightLotto'
    }]
}];

export default routes;
<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="getKlakloukSettingData ? getKlakloukSettingData.object : []"
        :footer-props="footerProps"
        item-key="_id"
        class="elevation-1"
        :server-items-length="getKlakloukSettingData ? getKlakloukSettingData.objectCount : null"
        style="font-family: 'khmer mef1'"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        show-select
        :loading="getLoading"
        :value="getDeleteItems"
        @input="itemDelete"
        mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
        />
      </template>
      <template v-slot:[`item.is_enable`]="{ item }">
        <v-icon
          v-if="item.is_enable == true"
          small>mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon
          v-else-if="item.is_enable === false"
          small>mdi-checkbox-blank-circle-outline
        </v-icon>
      </template>
      <template v-slot:[`item.is_start`]="{ item }">
        <v-icon
          v-if="item.is_start == true"
          small>mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon
          v-else-if="item.is_start === false"
          small>mdi-checkbox-blank-circle-outline
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :dataDelete="getDeleteItems"
            :params="data"
            :user-permission="userAccessPermission"
        />
      </template>
     
    </v-data-table>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :setting="getCurrentKlakloukSettingData"
        :dialog="getPopUp"
        :is-validate="valid"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="closeDialogCreate"
    />
      <DialogPecent
        :darkmode="darkmode"
        :loading="loading"
        :setting="getCurrentKlakloukSettingData"
        :dialog="getPopUpPercent"
        :is-validate="valid"
        v-on:submit="handleSubmitPercent"
        v-on:onCloseDialog="closeDialogPercent"
    />

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  components: {
    ActionComponents: () => import("./_components/action"),
    Header: () => import("./_components/headerTable.vue"),
    Dialog: () => import("./_components/dialog.vue"),
    DialogPecent: () => import("./_components/dialogPercent.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      'items-per-page-options': [10, 20, 50, 100, 200]
    },
    data: {
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "is_enable", "is_start", "updatedAt"],
      search: ''
    },
    loading: false,
  }),
  computed: {
    headers() {
      return [
        // {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_id'},
        { text: this.$t('input.name'), value: 'name' },
        { text: this.$t('input.channel'), value: 'channel_type' },
        { text: this.$t('input.is_enable'), value: 'is_enable' },
         { text: this.$t('input.is_start'), value: 'is_start' },
        { text: this.$t('input.updatedAt'), value: 'updatedAt' },
        { text: this.$t('header.actions'), value: 'actions', sortable: false }
      ];
    },
    ...mapGetters("$_modules", {darkmode: "getDarkMode"}),
    ...mapGetters("$_klakloukSetting", ["getKlakloukSettingData", "getLoading" , "getDeleteItems","getPopUp", "getPopUpPercent", "getCurrentKlakloukSettingData" ]),
    
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path)
    await this.getData()
    
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentKlakloukSettingData._id) {  
        await this.createConfigData({vm: this, data: this.getCurrentKlakloukSettingData})
        this.getCurrentKlakloukSettingData = []
        this.fetchPopUp(false)
      } else {
        
        let uploadData = {
          name : this.getCurrentKlakloukSettingData.name,
          is_enable :  this.getCurrentKlakloukSettingData.is_enable,
          is_start :  this.getCurrentKlakloukSettingData.is_start,
          channel_type :  this.getCurrentKlakloukSettingData.channel_type,
        }
        if(this.getCurrentKlakloukSettingData.channel_type == 93){
          await this.updateKlakloukSetting({ vm: this, data: uploadData, _id : this.getCurrentKlakloukSettingData._id });
        }else if(this.getCurrentKlakloukSettingData.channel_type == 931){
          await this.updateKlakloukSetting2({ vm: this, data: uploadData, _id : this.getCurrentKlakloukSettingData._id });
        }else if(this.getCurrentKlakloukSettingData.channel_type == 932){
          await this.updateKlakloukSetting3({ vm: this, data: uploadData, _id : this.getCurrentKlakloukSettingData._id });
        }else if(this.getCurrentKlakloukSettingData.channel_type == 933){
          await this.updateKlakloukSetting4({ vm: this, data: uploadData, _id : this.getCurrentKlakloukSettingData._id });
        }else if(this.getCurrentKlakloukSettingData.channel_type == 934){
          await this.updateKlakloukSetting5({ vm: this, data: uploadData, _id : this.getCurrentKlakloukSettingData._id });
        }
        
        this.fetchPopUp(false)
      }
      await this.getData()
      this.loading = false
    },
    async handleSubmitPercent() {
      if(this.getCurrentKlakloukSettingData._id){
        let uploadData = {
          max_win : this.getCurrentKlakloukSettingData.max_win,
          max_lose: this.getCurrentKlakloukSettingData.max_lose,
          percentage: this.getCurrentKlakloukSettingData.percentage,
          win_lose_type: this.getCurrentKlakloukSettingData.win_lose_type,
          is_start :  this.getCurrentKlakloukSettingData.is_start,
        }
        await this.updateKlakloukSetting({ vm: this, data: uploadData, _id : this.getCurrentKlakloukSettingData._id })
        this.fetchPopUpPercent(false)
      }
      await this.getData()
      this.loading = false
    },
    async getData() {
      this.checkUserPermission('VIEW') ? await this.fetchGetAllKlakloukSetting(this.data) : []
    },
    

    itemDelete(e) {
      const items = e.map(function (e) {
        return {_id: e._id}
      })
      this.DELETE_ITEMS(items)
    },

    closeDialogCreate() {
      this.fetchPopUp(false)
    },


    closeDialogPercent() {
      this.fetchPopUpPercent(false)
    },

    ...mapMutations("$_klakloukSetting", ["UPDATE_KLAKLOUK_SETTIN_DATA", "DELETE_ITEMS"]),
    ...mapActions("$_klakloukSetting", [
      "fetchPopUp",
      "fetchPopUpPercent",
      "createConfigData",
      "fetchGetAllKlakloukSetting",
      "updateKlakloukSetting",
      "updateKlakloukSetting2",
      "updateKlakloukSetting3",
      "updateKlakloukSetting4",
      "updateKlakloukSetting5"
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USER_ACCESS_PERMISSION([])
    next()
  },
};
</script>

<style lang="scss" scoped>
    .users {
        width: 50%;
        float: left;

        h3 {
            font-size: 17px;
        }
    }

    table {
        font-family: "khmer mef1" !important;
    }
</style>

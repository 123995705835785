import lot3Setting from '@/modules/slotMachineSetting/lot3Setting';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/lot3-setting',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'lot3Setting',
				component: lot3Setting
			}
		]
	}
];

export default routes;

<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            :headers="headers"
            :items="getStatement ? getStatement.object : []"
            :footer-props="footerProps"
            item-key="_id"
            class=" table table-striped"
            id="table-1"
            :server-items-length="getStatement ? getStatement.count : null"
            style="font-family: 'khmer mef1'"
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            hide-default-header
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            mobile-breakpoint="0"
          >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head._id">
              {{ head.text }}
            </th>
          </template>
            <template v-slot:top>
              <Header
                :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
              />
            </template>
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <ActionComponents
                :darkmode="darkmode"
                :item="item"
                :userp="userp"
                :dataDelete="getDeleteItems"
                :params="data"
                :user-permission="userAccessPermission"
              />
            </template> -->
            <template v-slot:[`item`]="{ item }">
              <tr :style="item.is_reciever_read == false
                ? 'background-color:#FFE3E5'
                : ''
                ">
                <td :style="item.type == 'WITHDRAW'
                ? 'color:red'
                : 'color:green'"
                >
                {{ item.type}}
                </td>
                <td>{{ currencyFormat(item.amount)}}</td>
                <td>{{ item.sender_username}}</td>
                <td>{{ item.currency_type == 1? "DOLLAR":item.currency_type == 2 ? "KHR": item.currency_type == 3? "THAI": "VN"}}</td>
                <td>{{ item.send_date}}</td>
                <td>{{ item.response_date}}</td>
                <td :style="item.status == 'REJECT'
                ? 'color:red'
                : item.status == 'SUCCESS'
                ? 'color:green'
                : item.status == 'PENDING'
                ?'color:#cbb70e'
                :''"
                >
                {{ item.status}}
                </td>
                <td>
                  <ActionComponents
                  :darkmode="darkmode"
                  :item="item"
                  :userp="userp"
                  :dataDelete="getDeleteItems"
                  :params="data"
                  :user-permission="userAccessPermission"
                />
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
          <Dialog
            :darkmode="darkmode"
            :loading="loading"
            :bankStatement="getCurrenBankStatement"
            :all_amount="parseFloat((parseFloat(getCurrenBankStatement.amount)+ (parseFloat(getCurrenBankStatement.promotion_percentage) * parseFloat(getCurrenBankStatement.amount)) / 100).toFixed(2))"
            :dialog="getPopUp"
            :is-validate="valid"
            v-on:approve="approvePlayer"
            v-on:reject="rejectPlayer"
            v-on:onCloseDialog="closeDialogCreate"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["sender_username","type","reciever_username","status"],
      search: "",
    },
    waiting:false,
    dialog: false,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        { text: "Type", value: "type" },
        { text: "Amount", value: "amount" },
        { text: "Players", value: "sender_username" },
        { text: "Currency Type", value: "currency_type" },
        { text: "Request Date", value: "send_date" },
        { text: "Approve Date", value: "response_date" },
        { text: "Status", value: "status" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode",userp: "getUser" }),
    ...mapGetters("$_depositPending", [
      "getAllBanner",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getCurrenBankStatement",
      "getStatement",
      "getCountStatement",
    ]),
  },
  async created() {
    //await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async approvePlayer(payload) {
      this.waiting = true;
      let data = {
        statement_id:payload._id,
        note: payload.reciever_note, 
        promotion_percentage: payload.promotion_percentage,
      };
      await this.$request
        .post({
          url: "/bankStatement/approved",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.$toastr.s(`Update Success!`)
            this.fetchPopUp(false);
            this.getData();
            this.waiting = false;
            window.location.reload();
          }else{
            this.$toastr.e(res.data.message.descriptions)
          }
        });
    },
    async rejectPlayer(payload) {
      this.waiting = true;
      let data = {
        statement_id:payload._id,
        note: payload.reciever_note, 
      };
      await this.$request
        .post({
          url: "/bankStatement/reject",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.$toastr.s(`Update Success!`)
            this.fetchPopUp(false);
            this.getData();
            this.waiting = false;
            window.location.reload();
          }else{
            this.$toastr.e(res.data.message.descriptions)
          }
        });
    },
    // async handleSubmit(payload) {
    //   this.loading = true;
    //   let data = {
    //     user_id: payload.user_id,
    //     amount_min: parseInt(payload.amount_min),
    //     amount_max: parseInt(payload.amount_max),
    //     betting_frequency: parseInt(payload.betting_frequency),
    //     channel_type: parseInt(payload.channel_type)
    //   }
    //   await this.createBannerData({ vm: this, data: data });
    //   this.fetchPopUp(false)
    //   await this.getData();
    //   this.loading = false;
    // },
    async getData() {
      // this.checkUserPermission("VIEW")
      //   ? await this.fetchListStatement(this.data)
      //   : [];
      await this.fetchListStatement(this.data);
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    closeDialogCreate() {
      this.getCurrentConfigUserData = {};
      this.fetchPopUp(false);
    },
    ...mapMutations("$_depositPending", ["LIST_STATEMENT","COUNT_STATEMENT", "DELETE_ITEMS","UPDATE_CURRENT_BANK_STATEMENT"]),
    ...mapActions("$_depositPending", [
      "fetchPopUp",
      "fetchListStatement",
      "fetchCurrentBankStatement",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
   // this.UPDATE_USERS([]);
    //this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    statement: {
        object: [],
    },
    countStatement: {
        objectCount: null,
    },
    currentBankStatement:{
        "_id": null,
        "type": null,
        "sender_note": "",
        "sender_username": "",
        "reciever_username": "",
        "reciever_note": null,
        "currency_type": "",
        "bank_sender": null,
        "bank_reciever": null,
        "status": "",
        "is_reciever_read": null,
        "is_sender_read": null,
        "amount": 0,
        "promotion_percentage": 0,
        "send_date": null,
        "response_date": null
    },
    loading: false,
    popUp: false,
    deleteItems: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchList = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true

    const response = await request.post({
        url: 'retry-cock/getAll',
        data
    });

    if (response.data.code) {
        commit('UPDATE_LIST', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const create = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'retry-cock',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created app.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`);
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrent = async({ commit }, payload) => {
    commit('UPDATE_CURRENT_SETTING', payload);
};

export default {
    fetchList,
    fetchPopUp,
    fetchCurrent,
    create
};

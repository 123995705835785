const getTaixiuSettingData = (state) => state.taixiuSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentTaixiuSettingData = (state) => state.currentTaixiuSettingData;


export default {
    getTaixiuSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentTaixiuSettingData,
};
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    logs: {
        object: [],
        objectCount: null
    },
    popUp: false,
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

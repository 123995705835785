/* eslint-disable no-unused-vars */
import axios from 'axios';
import Request from '@/services/axios'
let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    commit('UPDATE_LOADING', true)
    let data = preData.data;
    const response = await request.post({
      url: 'adminReport/getViewHistoryByFightYuki',
      data,
    });
    if (response.data.code) {
      commit('LIST_STATEMENT', response.data.data);
      commit('UPDATE_LOADING', false)
    }
};

export default {
    fetchListStatement,
};
const getCurrentChannel = (state) => state;

const getLoading = (state) => state.loading;

const getLoadingResult = (state) => state.loadingResult;

const getResultTable = (state) => state.objResult

export default {
    getCurrentChannel,
    getResultTable,
    getLoading,
    getLoadingResult
};
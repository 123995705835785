import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    users: {
        users: [],
        totalRow: null
    },
    loading: false,
    limitData:{},
    listMenuByApp:[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

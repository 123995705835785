/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListUsers = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'users/getAllPlayer',
        data
    });
    if (response.data.code) {
        commit('UPDATE_USERS', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};


const updateUser = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'users/updateStatusPlayer' ,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const updateSuspendUser = async ({commit}, {vm, data}) => {
    // console.log("akdshf", data)
    const response = await request.post({
        
        url: 'users/updatePlayerSuspendStatus' ,
        data
    });
    if (response.data.code) {
        // console.log(response.data)
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const requestLimitData = async ({commit}, submitLimitData) => {
    commit('UPDATE_LOADING_CONFIG', true)
    console.log("response.data.data.object",submitLimitData)
    const response = await request.post({
        url: "limitBetting/getParentLimitBettingByChannel",
        data: submitLimitData,
      });
    if (response.data.code) {
        
        commit('UPDATE_LIMIT_DATA',response.data.data.object);
        commit('UPDATE_LOADING_CONFIG', false)
    }
};
const updateConfigUser = async ({ commit }, { vm, d }) => {
    let data = d
    const response = await request.post({
        url: 'configUser/update/' + d._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
const fetchListMenuByApp = async ({commit},datas) => {
    let data={
        app_id:datas.app_id,
        role_id:datas.role_id,
        user_id:datas._id
    }
    console.log("data",data);
    const response = await request.post({
        url: 'app-menu/getMenuByApp',
        data: data
    });
    if (response.data.code) {
        commit('LIST_MENU_BY_APP', response.data.data);
    }
};


export default {
    fetchListUsers,
    updateUser,
    updateSuspendUser,
    requestLimitData,
    updateConfigUser,
    fetchListMenuByApp
};

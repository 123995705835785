import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    currentChannel: {
        _id: null,
        tem_id: null,
        info: null,
        video_info: null,
        date: null,
        fight_no: null,
        live_urls: [],
        maintain_desc: null,
        is_maintain: null,
        // enable: null,
        is_open: null,
    },
    objCoResult: [],
    objCoTypeOfBetting: [],
    results: [],
    objSummaryCoResult: {
        Rat: 0,
            Ox: 0,
            Tiger: 0,
            Rabbit: 0,
            Dragon: 0,
            Snake: 0,
            Horse: 0,
            Goat: 0,
            Monkey: 0,
            Rooster: 0,
            Dog: 0,
            Pig: 0,
            CANCEL: 0,
    },
    loading: false,
    loadingResult: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
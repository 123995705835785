const headers = [
    //{ text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'No',
        align: 'start',
        value: 'no',
    },
    { text: 'App Name', value: 'app_id' },
    { text: 'Fight No', value: 'fight_no' },
    { text: 'Group No', value: 'group_no' },
    { text: '1', value: '1' },
    { text: '2', value: '2' },
    { text: '3', value: '3' },
    { text: '4', value: '4' },
    { text: '5', value: '5' },
    { text: '6', value: '6' },
    { text: '7', value: '7' },
    { text: '8', value: '8' },
    { text: '9', value: '9' },
    { text: '10', value: '10' },
    { text: '11', value: '11' },
    { text: '12', value: '12' },
    // { text: '1_2_3', value: '1_2_3' },
    // { text: '4_5_6', value: '4_5_6' },
    // { text: '7_8_9', value: '7_8_9' },
    // { text: '10_11_12', value: '10_11_12' },
    { text: 'Under', value: 'under' },
    { text: 'Over', value: 'over' },
    { text: 'Red', value: 'red' },
    { text: 'Blue', value: 'Blue' },
    { text: 'Odd', value: 'odd' },
    { text: 'Even', value: 'even' },
    { text: 'Cast', value: 'cast' },
    { text: 'Amount Win', value: 'amount_win' },
    // { text: 'VIP option', value: '' },
];

export default headers;
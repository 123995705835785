import historyByFightHan2 from '@/modules/historyByFightHan2';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightHan2',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightHan2,
        name: 'historyByFightHan2'
    }]
}];

export default routes;
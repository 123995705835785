<template>
  <div>
    <div class="section-header" hidden>
      <!-- <h1>User Page</h1> -->
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="/">Dashboard</a>
        </div>
        <div class="breadcrumb-item">Player Statement KM</div>
      </div>
    </div>
    <!-- <div class="section-body"> -->
      <div class="card">
        <div class="card-body">
          <div class="section-header-breadcrumb pl-5">
            <div class="breadcrumb-item">
              <b style="color: #1976d2;">Player Statement RIEL</b>
            </div>
          </div>
          <!-- <v-toolbar-title class=" text-h5 uppercase pl-5 ">Player Statement RIEL</v-toolbar-title> -->
          <div class="table-responsive" style="text-align: center">
            <v-data-table
              :headers="headers"
              :items="getStatement ? getStatement.object : []"
              :server-items-length="
                getCountStatement ? parseInt(getCountStatement.count) : null
              "
              item-key="_id._id"
              class="table table-striped"
              id="table-1"
              style="font-family: 'khmer mef1'"
              :footer-props="footerProps"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              hide-default-header
              :loading="getLoading"
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :user="user"
                  :time="time"
                  :startTime="startTime"
                  :endTime="endTime"
                  :statusTimeOrigin="statusTimeOrigin"
                  @child-checkbox="checkboxValue"
                />

                <!--button date filter-->
                <div class="row">
                  <div
                    class="col-12 col-md-12 col-sm-12"
                    style="width: 100%; text-align: right; margin-bottom: 15px"
                  >
                    <v-btn
                      style="display: inline-block"
                      v-for="(item, i) in defaultFilterDates"
                      small
                      color="primary"
                      class="mx-4 mb-4 btn btn-primary"
                      :key="i"
                      @click="selectDate(item)"
                    >
                      <span style="color: white">{{ item.name }}</span>
                    </v-btn>
                  </div>
                </div>
              </template>

              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td>
                    {{
                      getStatement.object.indexOf(item) +
                      (data.page - 1) * data.rowsPerPage +
                      1
                    }}
                  </td>
                  <td>{{ item._id.app_id }}</td>
                  <td>
                    {{
                      user.role_name == "DEVELOPER" ||
                      user.role_name == "MANAGER" ||
                      user.role_name == "CONTROLLER" ||
                      user.role_name == "COMPANY" ||
                      user.role_name == "SPECIAL-MANAGER"
                        ? item._id.com
                        : "---"
                    }}
                  </td>
                  <td>
                    {{
                      user.role_name == "DEVELOPER" ||
                      user.role_name == "MANAGER" ||
                      user.role_name == "CONTROLLER" ||
                      user.role_name == "COMPANY" ||
                      user.role_name == "SPECIAL-MANAGER" ||
                      user.role_name == "SENIOR-B" ||
                      user.role_name == "SENIOR-D" ||
                      user.role_name == "SENIOR-R"
                        ? item._id.admin
                        : "---"
                    }}
                  </td>
                  <td>
                    {{
                      user.role_name == "DEVELOPER" ||
                      user.role_name == "MANAGER" ||
                      user.role_name == "CONTROLLER" ||
                      user.role_name == "COMPANY" ||
                      user.role_name == "SPECIAL-MANAGER" ||
                      user.role_name == "SENIOR-B" ||
                      user.role_name == "SENIOR-D" ||
                      user.role_name == "SENIOR-R" ||
                      user.role_name == "MASTER-B" ||
                      user.role_name == "MASTER-D" ||
                      user.role_name == "MASTER-R"
                        ? item._id.master
                        : "---"
                    }}
                  </td>
                  <td>{{ item._id.agent }}</td>
                  <td>{{ item._id.player }}</td>
                  <td>{{ currencyFormat(item._id.player_balance) }}</td>
                  <td
                    class="amount-bet-color to-detail"
                    @click="_goToDetail(item._id.player_id)"
                  >
                    {{ currencyFormat(item.amount) }}
                  </td>
                  <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">
                    {{ currencyFormat(item.amount_win) }}
                  </td>
                </tr>
              </template>
              <template slot="body.append">
                <tr style="background-color: #faffe5; font-weight: bold;">
                  <td :colspan="7" class="text-xs-center pl-4 py-2">
                    {{ $t("title.total") }}
                  </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                  {{
                    getCountStatement.balance
                      ? currencyFormat(getCountStatement.balance)
                      : 0
                  }}
                </td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getCountStatement.amount
                        ? currencyFormat(getCountStatement.amount)
                        : 0
                    }}
                  </td>
                  <td
                    class="
                      text-xs-center
                      pl-4
                      total-style
                      font-weight-bold
                      py-2
                    "
                    :style="
                      getCountStatement.amount_win < 0
                        ? 'color:#BA2D4A'
                        : 'color:#39afd6'
                    "
                  >
                    {{
                      getCountStatement.amount_win
                        ? currencyFormat(getCountStatement.amount_win)
                        : 0
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-dialog
              :value="dialog"
              max-width="80%"
              persistent
              transition="dialog-bottom-transition"
              style="z-index: 9999 !important"
            >
              <v-card>
                <div class="modal-header">
                  <h5 class="modal-title" id="formModal">
                    Detail Player Statement
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="$emit('onCloseDialog')"
                  >
                    <span @click="dialog = false" aria-hidden="true">×</span>
                  </button>
                </div>
                <v-card-text>
                  <br />
                  <!-- <v-row class="pl-5">
                    <br />
                    <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                      <v-select
                        v-model="defaultSelected"
                        :items="selectChannel"
                        item-text="name"
                        item-value="id"
                        style="margin-top: -10px"
                        solo
                      ></v-select>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" class="text-right">
                      <v-text-field
                        v-model="v_search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                        class="mr-5"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                      <v-btn
                        class="white--text btn-custom mr-4 mt-3"
                        color="green darken-1"
                        depressed
                        @click="searchq"
                      >
                        SEARCH
                      </v-btn>
                    </v-col>
                  </v-row> -->
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <div class="input-group">
                        <div class="col-md-3 col-sm-12">
                          <!-- <v-select
                            v-model="defaultSelected"
                            :items="selectChannel"
                            item-text="name"
                            item-value="id"
                            solo
                          ></v-select> -->
                          <div class="form-group">
                            <!-- <select
                              v-model="defaultSelected"
                              class="form-control form-control-lg"
                              style="padding-top: 0!important; padding-bottom: 0 !important;"
                            >
                              <option selected :value="0">All</option>
                              <option
                                v-for="option in selectChannel"
                                v-bind:key="option.id"
                                v-bind:value="option.id"
                              >
                                {{ option.name }}
                              </option>
                            </select> -->

                            <select class="form-control form-control-sm" v-model="defaultSelected">
                              <option :value="null" disabled selected>Choose Channel</option>
                              <option
                                v-for="option in selectChannel"
                                v-bind:key="option.id"
                                v-bind:value="option.id"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-9 col-sm-12">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="fas fa-search"></i>
                              </div>
                            </div>
                            <input
                              v-model="v_search"
                              type="text"
                              class="form-control mr-1"
                            />
                            <button class="btn btn-primary" @click="searchq">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-data-table
                    style="font-family: 'khmer mef1'"
                    class="elevation-1"
                    item-key="date"
                    :headers="headersDetail"
                    :footer-props="footerProps"
                    :items="detailData ? detailData : []"
                    :server-items-length="
                      detailDataCount ? parseInt(detailDataCount.count) : null
                    "
                    :sort-desc.sync="dataRequest.descendingFormat"
                    :sort-by.sync="dataRequest.sortByFormat"
                    :page.sync="dataRequest.page"
                    :items-per-page.sync="dataRequest.rowsPerPage"
                    @update:page="updatePage"
                    @update:items-per-page="updatePage"
                    @update:sort-desc="updatePage"
                    :loading="getLoadingDetail"
                    mobile-breakpoint="0"
                  >
                    <template v-slot:[`item`]="{ item }">
                      <tr>
                        <td>
                          {{
                            detailData
                              ? detailData.indexOf(item) +
                                (dataRequest.page - 1) *
                                  dataRequest.rowsPerPage +
                                1
                              : "loading ..."
                          }}
                        </td>
                        <td>{{ item.app_id }}</td>
                        <td>{{ item.public_ip }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.fight_no }}</td>
                        <td>
                          <span v-if="item.channel_type == 90"> Lotto(SLOT) </span>
                          <span v-else-if="item.channel_type == 91"> Yuki(SLOT) </span>
                          <span v-else-if="item.channel_type == 92"> PP78(SLOT) </span>
                          <span v-else-if="item.channel_type == 1"> CO1 </span>
                          <span v-else-if="item.channel_type == 2"> CO2 </span>
                          <span v-else-if="item.channel_type == 3"> CO3 </span>
                          <span v-else-if="item.channel_type == 4"> CO4 </span>
                          <span v-else-if="item.channel_type == 15"> CO5 </span>
                          <span v-else-if="item.channel_type == 16"> CO6 </span>
                          <span v-else-if="item.channel_type == 5"> KL1 </span>
                          <span v-else-if="item.channel_type == 6"> BA1 </span>
                          <span v-else-if="item.channel_type == 7"> BA2 </span>
                          <span v-else-if="item.channel_type == 18"> BA3 </span>
                          <span v-else-if="item.channel_type == 8"> TD1 </span>
                          <span v-else-if="item.channel_type == 9"> TD2 </span>
                          <span v-else-if="item.channel_type == 19"> TD3 </span>
                          <span v-else-if="item.channel_type == 10"> LO1 </span>
                          <span v-else-if="item.channel_type == 11"> YU1 </span>
                          <span v-else-if="item.channel_type == 12"> AP1 </span>
                          <span v-else-if="item.channel_type == 17"> AP2 </span>
                          <span v-else-if="item.channel_type == 13"> FT1 </span>
                          <span v-else-if="item.channel_type == 14"> TS1 </span>
                          <span v-else-if="item.channel_type == 20"> HAN2 </span>
                          <span v-else-if="item.channel_type == 21"> HAN3 </span>
                          <span v-else-if="item.channel_type == 93"> KL(SLOT) </span>
                          <span v-else-if="item.channel_type == 94"> TD(SLOT) </span>
                          <span v-else-if="item.channel_type == 95"> BA(SLOT) </span>
                          <span v-else-if="item.channel_type == 96"> DODEN(SLOT) </span>
                          <span v-else-if="item.channel_type == 97"> SICBO(SLOT) </span>
                          <span v-else-if="item.channel_type == 98"> TAIXIU(SLOT) </span>
                        </td>
                        <td :class="item.type_of_betting.color">
                          <v-chip v-if="item.channel_type == 92" label :color=item.type_of_betting.color style="padding:15px;color:#ffffff; font-size:13px;height:22px">
                              <span class="white--text">{{item.type_of_betting.name}} : {{item.type_of_betting.number_label}}</span>
                          </v-chip>
                          <span v-else-if="item.channel_type == 11">
                            {{ item.type_of_betting.desc }}
                          </span>
                          <span v-else-if="item.channel_type == 5 || item.channel_type == 93"> 
                            <v-img v-if="item.type_of_betting.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.type_of_betting.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                          </span>
                          <span v-else>
                            {{ item.type_of_betting.name }}
                          </span>
                        </td>
                        <td>
                          <span v-if="item.channel_type == 10">
                            {{ item.result1.name }}, {{ item.result2.name }}
                          </span>
                          <span
                            v-else-if="
                              item.channel_type == 6 || item.channel_type == 7
                            "
                          >
                            <span>{{ item.result1.name }}</span>
                            <span v-if="item.result3.name"
                              >, {{ item.result3.name }}
                            </span>
                          </span>
                          <span v-else-if="item.channel_type == 12">
                            {{ item.result1.name }}
                          </span>
                          <span v-else-if="item.channel_type == 5 || item.channel_type == 93">
                            <v-img v-if="item.result1.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.result1.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.result2.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ខ្លា'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0001.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ឃ្លោក'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0002.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'មាន់'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0003.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'បង្កង'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0004.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ក្ដាម'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0005.png'"> </v-img>
                            <v-img v-if="item.result3.name == 'ត្រី'" height="25px" width="25px" class="pair-card" :src="'images/klaklouk/0006.png'"> </v-img>
                          </span>
                          <span v-else-if="item.channel_type == 90">
                            {{ item.result1.name }} | {{ item.result2.name }}
                          </span>
                          <span v-else-if="item.channel_type == 91">
                            {{ item.result1.name }} | {{ item.result2.name }} | {{ item.result3.name }} | {{ item.result4.name }} | {{ item.result5.name }} 
                          </span>
                          <span v-else-if="item.channel_type == 92">
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result1.color"
                            >
                            {{item.result1.name}}:{{item.result1.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result2.color"
                            >
                            {{item.result2.name}}:{{item.result2.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result3.color"
                            >
                            {{item.result3.name}}:{{item.result3.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result4.color"
                            >
                            {{item.result4.name}}:{{item.result4.number_label}}
                            </v-btn>
                            <v-btn
                              class="mx-1"
                              fab
                              dark
                              x-small
                              :color="item.result5.color"
                            >
                            {{item.result5.name}}:{{item.result5.number_label}}
                            </v-btn>
                          </span>
                          <span v-else-if="item.channel_type == 20" >
                            <span :class="item.result1.color">{{ item.result1.name }} |</span>
                            <span :class="item.result2.color">{{ item.result2.name }} |</span>
                            <span :class="item.result3.color">{{ item.result3.name }} </span>
                          </span>
                          <span v-else-if="item.channel_type == 21">
                            <span :class="item.result1.color">{{ item.result1.name }} |</span>
                            <span :class="item.result2.color">{{ item.result2.name }} |</span>
                            <span :class="item.result3.color">{{ item.result3.name }} </span> 
                          </span>
                          <span v-else-if="item.channel_type == 94" >
                            <span :class="item.result1.color">{{ item.result1.name }} </span>
                          </span>
                          <span v-else-if="item.channel_type == 95">
                            <span :class="item.result1.color">{{ item.result1.name }}</span>
                            <span v-if="item.result2.name" :class="item.result2.color">, {{ item.result2.name }}
                            </span>
                          </span>
                          <span v-else-if="item.channel_type == 96">
                            <span :class="item.result1.color">{{ item.result1.name }}|</span>
                            <span :class="item.result2.color">{{ item.result2.name }}|</span>
                            <span :class="item.result3.color">{{ item.result3.name }} </span> 
                          </span>
                          <span v-else-if="item.channel_type == 97">
                            <span :class="item.result2.color">{{ item.result2.name }}|</span>
                            <span :class="item.result3.color">{{ item.result3.name }}</span>
                          </span>
                          <span v-else-if="item.channel_type == 98">
                            <span :class="item.result2.color">{{ item.result2.name }}|</span>
                            <span :class="item.result1.color">{{ item.result1.name }}</span>
                          </span>
                          <span v-else>
                            {{ item.result1.desc }}
                          </span>
                        </td>
                        <td class="amount-bet-color">
                          <!-- {{ dataRequest && dataRequest.currencyType == 2 ? currencyFormatKm(item.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 ?  currencyFormatEn(item.amount) :"Loading ..." }} -->
                          {{ currencyFormatEn(item.amount) }}
                        </td>
                        <td>{{ item.payout }}</td>
                        <td
                          :class="
                            item.amount_win < 0
                              ? 'lose-color font-weight-bold'
                              : 'win-color font-weight-bold'
                          "
                        >
                          {{
                            item.amount_win
                              ? currencyFormatEn(item.amount_win)
                              : 0
                          }}
                        </td>
                        <td>
                          <span v-if="Object.keys( item.result1 ).length > 0">
                            <span v-if="item.result1.name == 'CANCEL' || item.result1.name == 'TIE'">{{ currencyFormatEn(item.amount) }}</span>
                            <span v-else>{{ currencyFormatEn(item.cast) }}</span>
                          </span>  
                          <span v-else>0</span>
                        </td>
                      </tr>
                    </template>
                    <template slot="body.append">
                      <tr style="background-color: #faffe5; font-weight: bold;">
                      <td :colspan="8" class="text-xs-center pl-4 py-2">
                        {{ $t("title.total") }}
                      </td>
                      <td
                        :class="
                          detailDataCount.amount < 0
                            ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                            : 'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                        "
                      >
                        {{
                          detailDataCount
                            ? currencyFormat(detailDataCount.amount)
                            : "loading ..."
                        }}
                      </td>
                      <td></td>
                      <td
                        :class="
                          detailDataCount.amount_win < 0
                            ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                            : 'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'
                        "
                      >
                        <!-- 123 -->
                        <!-- {{ dataRequest && dataRequest.currencyType == 2 && detailDataCount ? currencyFormatKm(detailDataCount.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 && detailDataCount ?  currencyFormatEn(detailDataCount.amount) :"Loading ..." }} -->
                        {{
                          detailDataCount
                            ? currencyFormat(detailDataCount.amount_win)
                            : "loading ..."
                        }}
                      </td>
                      <td></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- <div class="text-center pt-2">
              <v-pagination
                v-model="data.page"
                :length="
                  getUsers
                    ? getCountStatement.count % data.rowsPerPage != 0
                      ? getCountStatement.count / data.rowsPerPage + 1
                      : getCountStatement.count / data.rowsPerPage
                    : 0
                "
              ></v-pagination>
              <v-text-field
                :value="data.rowsPerPage"
                label="Items per page"
                type="number"
                min="-1"
                max="15"
                @input="data.rowsPerPage = parseInt($event, 10)"
              ></v-text-field>
            </div> -->
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import headersDetail from "./_api/columns_detail";
import moment from "moment";
import Vue from "vue";
export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    v_search: null,
    valid: true,
    headers: headers,
    headersDetail: headersDetail,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    // defaultSelected: {
    //   id: 0,
    //   name: "All",
    // },
    defaultSelected: null,
    selectChannel: [
      {
        id: 0,
        name: "All",
      },
      {
        id: 1,
        name: "CO1",
      },
      {
        id: 2,
        name: "CO2",
      },
      {
        id: 3,
        name: "CO3",
      },
      {
        id: 4,
        name: "CO4",
      },
      {
        id: 15,
        name: "CO5",
      },
      {
        id: 16,
        name: "CO6",
      },
      {
        id: 5,
        name: "KL1",
      },
      {
        id: 6,
        name: "BA1",
      },
      {
        id: 7,
        name: "BA2",
      },
      {
        id: 18,
        name: "BA3",
      },
      {
        id: 8,
        name: "TD1",
      },
      {
        id: 9,
        name: "TD2",
      },
      {
        id: 19,
        name: "TD3",
      },
      {
        id: 10,
        name: "LO1",
      },
      {
        id: 11,
        name: "YU1",
      },
      {
        id: 12,
        name: "AP1",
      },
      {
        id: 17,
        name: "AP2",
      },
      {
        id: 13,
        name: "FT1",
      },
      {
        id: 14,
        name: "TS1",
      },
      {
        id: 20,
        name: "HAN2",
      },
      {
        id: 21,
        name: "HAN3",
      },
      {
          id: 90,
          name: "LOTTO99(SLOT)",
        },
        {
          id: 91,
          name: "YUKI(SLOT)",
        },
        {
          id: 92,
          name: "PP78(SLOT)",
        },
        {
          id: 93,
          name: "KLAKLOUK(SLOT)",
        },
        {
          id: 94,
          name: "DRAGON(SLOT)",
        },
        {
          id: 95,
          name: "BACCARAT(SLOT)",
        },
        {
          id: 96,
          name: "DODEN(SLOT)",
        },
        {
          id: 97,
          name: "SICBO(SLOT)",
        },
        {
          id: 98,
          name: "TAIXIU(SLOT)",
        },
    ],
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "public_ip",
        "date",
        "amount",
        "amount_win",
        "co_type_of_betting.name",
        "fight_no",
      ],
      search: "",
      channelType: 0,
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["amount_win"],
      descending: true,
      sortBy: "amount_win",
      page: 1,
      rowsPerPage: 10,
      fields: [
        "_id.app_id",
        "_id.com",
        "_id.con_balance",
        "_id.admin_id",
        "_id.admin",
        "_id.admin_balance",
        "_id.master_id",
        "_id.master",
        "_id.master_balance",
        "_id.agent_id",
        "_id.agent",
        "_id.agent_balance",
        "_id.player_id",
        "_id.player",
        "_id.player_balance",
        "amount",
        "amount_win",
      ],
      search: "",
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
    },
    appId : 0,
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    detailData: [],
    detailDataCount: [],
    check: {
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",

      startTime: "00:00",
      endTime: "23:59",
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        nameKh: "ថ្ងៃនេះ",
        active: false,
      },
      {
        name: "Yesterday",
        nameKh: "ម្សិលមិញ",
        active: false,
      },
      {
        name: "This Week",
        nameKh: "សប្តាហ៍នេះ",
        active: false,
      },
      {
        name: "Last Week",
        nameKh: "សប្តាហ៍មុន",
        active: false,
      },
      {
        name: "This Month",
        nameKh: "ខែនេះ",
        active: false,
      },
      {
        name: "Last Month",
        nameKh: "ខែមុន",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
    getLoadingDetail: false,
    user_iid: null,
    channel_type: null,
    startTime: "00:00",
    endTime: "23:59",
    statusTimeOrigin: false,
  }),
  watch: {
    user: function () {
      this.getData();
    },
    data: function () {
      this.getData();
    },
    getStatement: function () {},
  },
  computed: {
    reportUrl: function () {
      return "player";
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_ocStatementSummarySeniorKm", [
      "getStatement",
      "getCountStatement",
      "getLoading",
    ]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    moment,
    _goToDetail(_id) {
      this.user_iid = _id;
      this.loadDetailData(_id);
      this.dialog = true;
    },
    searchq() {
      if (this.defaultSelected && this.defaultSelected._id)
        this.channel_type = this.defaultSelected._id;
      else this.channel_type = this.defaultSelected;
      this.dataRequest.search = this.v_search;
      this.loadDetailData(this.user_iid);
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.user_iid);
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id) {
      (this.detailData = []),
        (this.detailDataCount = []),
        (this.getLoadingDetail = true);
      if (this.channel_type != null && typeof this.channel_type === "object") {
        this.dataRequest.channelType = this.channel_type.id;
      }
      if (this.channel_type != null && typeof this.channel_type !== "object") {
        this.dataRequest.channelType = this.channel_type;
      }
      this.dataRequest.startDate = this.data.startDate;
      this.dataRequest.endDate = this.data.endDate;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      Vue.$cookies.set("currency-type", 2);
      console.log(this.dataRequest);
      await this.$request
        .post({
          url: "statementCurrency/playerDetail/" + _id,
          data: this.dataRequest,
        })
        .then((res) => {
          console.log("res.data.data", res.data.data);
          if (res.data.code) {
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetail = false;
          } else {
            this.getLoadingDetail = false;
          }
        });
    },
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment().subtract(1, "days").format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment().startOf("isoweek").format(format);
          this.endDate = this.moment().endOf("isoWeek").format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate, startTime, endTime, statusTimeOrigin } = this;
      this.time = {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        statusTimeOrigin: statusTimeOrigin,
      };
      this.check = { startDate, endDate, startTime, endTime };
      this.data.startDate = startDate + "T00:00:00";
      this.data.endDate = endDate + "T23:59:59";
      this.data.statusTime = this.statusTime ? true : false;
      this.getData();
    },
    // Gets the checkbox information from the child component
    checkboxValue: function (params) {
      this.check = params;
      this.appId = params.appId;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
      this.data.statusTime = params.statusTime;
    },
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    async getData() {
      try {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER" ||
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY" ||
          this.user.role_name == "SENIOR-R" ||
          this.user.role_name == "SUB-SENIOR-R" ||
          this.user.role_name == "MASTER-R" ||
          this.user.role_name == "SUB-MASTER-R" ||
          this.user.role_name == "AGENT-R" ||
          this.user.role_name == "SUB-AGENT-R"
        ) {
          this.loader = true;
          let preData = {
            data: this.data,
            appId:this.appId,
            reportUrl: this.reportUrl,
          };
          await this.fetchListStatement(preData);
          this.loader = false;
        }
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_ocStatementSummarySeniorKm", [
      "LIST_STATEMENT",
      "COUNT-STATEMENT",
    ]),
    ...mapActions("$_ocStatementSummarySeniorKm", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.to-detail {
  cursor: pointer;
  text-decoration: underline;
}
.MERON {
  color: #ba2d4a;
  font-weight: bold;
}
.WALA {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.cir-red {
  color: #ba2d4a;
  font-weight: bold;
}
.cir-blue {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.cir-green {
  color: green;
}
.cir-cancel {
  color: grey;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>

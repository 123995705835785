import Vue from 'vue';
import Request from './../../services/axios'

const request = new Request();

const fetchUserInfo = async({ commit }) => {
    const response = await Vue.$cookies.get('token');
    if (response) {
        commit('UPDATE_USER_INFO', response);
    }
};

const fetchUserAccessMenu = async({ commit }) => {
    const response = await request.get({
        url: 'users/UserAccessMenu'
    })
    if (response.data.code) {
        commit('UPDATE_MENU', response.data.data.object)
    }
}

const getUserAccessMenu = async () => {
	return await request.get({
		url: 'users/UserAccessMenu'
	});
};

const fetchUser = async({ commit }) => {
    const response = await request.get({
        url: 'users'
    })
    if (response.data.code) {
        commit('UPDATE_USER', response.data.data.object)
    }
}

const fetchUserAccessPermission = async({ commit }, data) => {
    const response = await request.post({
        url: 'menus/getPermissionBaseOnURL',
        data: {
            url: data
        }
    })
    if (response.data.code) {
        commit('UPDATE_USER_ACCESS_PERMISSION', response.data.data.object[0].permissions)
    }
}

export default {
    fetchUserInfo,
    fetchUserAccessMenu,
    fetchUser,
    fetchUserAccessPermission,
    getUserAccessMenu
};
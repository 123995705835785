const headers = [
    //{ text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'No',
        align: 'start',
        value: 'no',
    },
    { text: 'App Name', value: 'app_id' },
    { text: 'Fight No', value: 'fight_no' },
    { text: 'Group No', value: 'group_no' },
    { text: 'Player1', value: 'amount_player1' },
    { text: 'Banker1', value: 'amount_banker1' },
    { text: 'Player2', value: 'amount_player2' },
    { text: 'Banker2', value: 'amount_banker2' },
    { text: 'Player3', value: 'amount_player3' },
    { text: 'Banker3', value: 'amount_banker3' },
    { text: 'Cast', value: 'cast' },
    { text: 'Amount Win', value: 'amount_win' },
    // { text: 'VIP option', value: '' },
];

export default headers;
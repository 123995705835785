const UPDATE_LOT3_SETTING_DATA = (state, data) => {
    state.lot3SettingData = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}
const UPDATE_CURRENT_DATA = (state, data) => {
    console.log("data",data);
   state.currentlot3SettingData = data

};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentlot3SettingData = {
            _id: null,
            name: null,
            is_enable: null,
            is_start: null,
            user_id: null,
            percentage: 0,
            channel_type: 0,
            updatedAt: null
        }
    }
    state.popUp = data;
};

const UPDATE_POPUP_PERCENT = (state, data) => {
    if (!data) {
        state.currentlot3SettingData = {
            _id: null,
            name: null,
            is_enable: null,
            is_start: null,
            user_id: null,
            percentage: 0,
            channel_type: 0,
            updatedAt: null
        }
    }
    state.popUpPercent = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};

export default {
    UPDATE_LOT3_SETTING_DATA,
    LOADING,
    UPDATE_POPUP,
    UPDATE_POPUP_PERCENT,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS
};
/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchGetAllBaccaratSetting = async ({ commit }, data) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'slot-baccarat-setting/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        commit('LOADING', false);
    }
};

const updateBaccaratSetting = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateBaccaratSetting2 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-2/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateBaccaratSetting3 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-3/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateBaccaratSetting4 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-4/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateBaccaratSetting5 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-5/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateBaccaratSetting6 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-6/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateBaccaratSetting7 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-7/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateBaccaratSetting8 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-8/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateBaccaratSetting9 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-9/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateBaccaratSetting10 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-baccarat-setting-10/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_BACCARAT_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchPopUpPercent = async ({commit}, payload) => {
    commit('UPDATE_POPUP_PERCENT', payload);
};
const fetchCurrentBaccaratSettingData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'limitBetting/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchGetAllBaccaratSetting,
    updateBaccaratSetting,
    updateBaccaratSetting2,
    updateBaccaratSetting3,
    updateBaccaratSetting4,
    updateBaccaratSetting5,
    updateBaccaratSetting6,
    updateBaccaratSetting7,
    updateBaccaratSetting8,
    updateBaccaratSetting9,
    updateBaccaratSetting10,
    fetchPopUp,
    fetchPopUpPercent,
    fetchCurrentBaccaratSettingData,
    deleteData
};
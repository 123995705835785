<template>
  <div>
    <v-container fluid>
      <v-chip :color="correctColorPosition(dealerInfo.position)"
        class="dealer-info px-4"> {{ dealerInfo.position == 'pr' ? "Supervisor" : dealerInfo.position }}</v-chip>
      <v-row class="pt-5">
        <p class="dealer-name pl-2">{{ dealerInfo && dealerInfo.name ? "Name: " + dealerInfo.name : '---' }}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.last_login ?
            "Last Login: " + moment(dealerInfo.last_login).format('DD/MM/YYYY hh:mm') : '---'
        }}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.barcode ?
            'Id: ' + dealerInfo.barcode : 'id: ---'
        }}</p>
        <v-btn @click="_openDialogScanDealer(true)" :loading="loadingDealerButton" :disabled="loadingDealerButton"
          color="blue-grey" class="white--text dealer-changer">
          Change Dealer
          <v-icon right dark>
            mdi-cloud-sync
          </v-icon>
        </v-btn>
      </v-row>

      <v-row>
        <v-col cols="5" class="live-channel-info-container">
          <iframe v-if="channel && channel.live_urls" :src="channel.live_urls[0]" frameborder="0"
            width="100%" height="415px" scrolling="no" style="padding:0px; scroll"></iframe>
        </v-col>
        <v-col cols="2" class="game-control-container">
          <p
            :class="socketChannelCountdown && socketChannelCountdown <= 10 ? 'red--text uppercase default-count-down' : 'uppercase default-count-down'">
            {{ socketChannelCountdown ? socketChannelCountdown : defaultCountDown }}
          </p>
          <p :class="[
            channelStatus(channel.is_open).color,
            'uppercase channel-status',
          ]">
            {{ channelStatus(channel.is_open).status }}
          </p>
          <div style="width:100%; text-align:center;">
            <!-- <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pr')" :disabled="
              channel.is_open == false ||
                loading == true ||
                channel.is_open == true
                ? true
                : false
                " color="error" class="ma-2 white--text mt-4" style="width:150px" @click="alertResetShoeDialog">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Shoe
            </v-btn>
            <br> -->
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error" class="mt-5" style="width:150px"
              @click="alertResetResultDialog" :disabled="
                channel.is_first_game == false
                  ? true
                  : false
              ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Result
            </v-btn>
            <br>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error"
              class="mt-5" style="width:150px" @click="_clearCard" :disabled="
                loading == true ||
                  channel.is_open == true
                  ? true
                  : false
              ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Clear Result
            </v-btn>

            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" :disabled="loading == true || channel.is_open == true
                  ? true
                  : false
              " color="error" class="ma-2 white--text mt-4" style="width:150px" @click="alertVoidShoeDialog">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Void Game
            </v-btn>
            
          </div>
        </v-col>
        <v-col cols="5" style="position: relative;top: -46px;">
          <v-col cols="12">
            <v-text-field :autofocus="true" type="number" :disabled="!dealerInfo._id || !allowInput || getAllowScanCard" 
              class="black--text text-h4 font-weight-bold"
              style="font-weight: bolder !important;"
              ref='search' autocomplete="off" v-model="scanCard" onpaste="return false">
            </v-text-field>
          </v-col>
          <v-row style="width: 100%;">
            <v-col cols="12">
              <span class="write--text text-h6 font-weight-bold">Total: {{totalValue}}</span>
            </v-col>
            <v-col cols="12" class="player-card-container">
              <div v-if="(arrNumber.length >0)">
                <v-btn v-for="num in arrNumber" :key="num"
                class="mx-2"
                fab
                dark
                color="indigo"
                
                style="margin-top: 10px; font-weight: bold;width:90px; height: 90px;"
              >
                <span class="write--text text-h2 font-weight-bold">{{num}}</span>
              </v-btn>
              </div>
            </v-col>  
          </v-row>
          <div v-if="channel.is_last_game">
            <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:170px; text-align: right;">
              <v-btn class="update-chanel-btn" @click="_finishedCardLotto"
                :color="channelUpdateStatus(channel.is_open).background"
                :disabled="(channel.is_first_game == true || channel.is_open == false || loadingResult ? true: false)">
                Finish
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:170px;text-align: right;">
              <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
                @click="_updateChanelStatus"
                :disabled="channel.is_open == false || loadingResult ? true: false || !canUpdateStatus || Boolean(socketChannelCountdown && socketChannelCountdown > 2)">
                {{ channelUpdateStatus(channel.is_open).status }}
              </v-btn>
            </div>
          </div> 
         
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialogResetResult"
      persistent
      max-width="480"
      max-height="380"
    >
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="grey darken-1"
            class="cancel-btn"
            @click="dialogResetResult = false"
          >
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Result
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogResetShoe" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Shoe ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetShoe = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetShoe">
            Yes, Reset Shoe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="getDealerDialog" width="420" height="420" persistent>
     

      <v-card class="text-center px-5">

        <p class="text-h5 text-danger">Please Scan Card</p>
        <v-text-field autofocus label="" v-model="barcodeDealer" type="password" onpaste="return false"></v-text-field>
      </v-card>



    </v-dialog>

    <v-dialog v-model="dialogVoidShoe" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Score ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogVoidShoe = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_voidShoe">
            Yes, Void Shoe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//NumpadEnter
addEventListener('keypress', (event) => {
  if (event.code == 'NumpadEnter') {
    if(window.callInside.loadingResult) {
      return false;
    }
    else{
      window.callInside._updateOpenStatusLotto();
    }
  }
  if (event.code == 'NumpadSubtract') {
    if(window.callInside.loadingResult) {
      return false;
    }
    else{
      window.callInside._finishedCardLotto();
    }
  }
  // if (event.code == 'NumpadEnter') window.callInside._updateOpenStatusLotto();
  if (event.code == 'Enter') window.callInside._updateOpenStatusLotto();
  if (event.code == 'NumpadAdd') window.callInside._openDialogScanDealer(true);
  if (event.code == 'Backquote') window.callInside.increaseColumn();
  // if (event.code == 'NumpadSubtract') window.callInside._finishedCardLotto();
 // if (event.code == 'NumpadDivide') window.callInside._submitCardcode();
  if (event.code == 'NumpadDivide') window.callInside.reloadWindow();
  if (event.code == 'NumpadMultiply') window.callInside.focusSearch();
});
window.onkeydown = function (e) {
  return !(e.keyCode == 32 && e.target == document.body);
};
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  props: [
    "channel",
    "objSnNumber",
    "socketChannelType",
    "socketChannelCountdown",
    "dealerInfo",
    "totalValue",
    "arrNumber",
    "getDealerDialog",
    "getResultEvent",
    "getAllowScanCard"
  ],

  data() {
    return {
      imgPath: process.env.VUE_APP_API_REPORT_URL,
      defaultPic: 'images/defaultImg.jpg',
      valid: true,
      rules: [(value) => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      updateChanel: [],
      loadingDealerButton: false,
      canUpdateStatus: true,
      dialogResetResult: false,
      dialogResetShoe: false,
      dialogVoidShoe: false,
      scanCard: '',
      barcodeDealer: "",
      defaultCountDown: 0,
      allowInput : true,
      rule_meron_percentage: [(v) => {
        if (
          !isNaN(parseFloat(v)) &&
          v > 0 &&
          v <= this.objKdPayout.target_percentage - this.objKdPayout.wala_percentage &&
          !!v
        )
          return true;
        return "Number has to be bigger than 0 and smaller or equal target" + this.objKdPayout.target_percentage;
      }],
      rule_wala_percentage: [(v) => {
        if (
          v > 0 &&
          v <= this.objKdPayout.target_percentage - this.objKdPayout.meron_percentage &&
          !!v
        )
          return true;
        return "Number has to be bigger than 0 and smaller or equal target " + this.objKdPayout.target_percentage;
      },]
    };
  },
  computed: {

    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_lottoChannel", {
      loading: "getLoading",
      loadingResult: "getLoadingResult",
    }),
  },
  watch: {
    totalValue: function (newV) {
    
      if (newV) this.scanCard = '';
    },
    arrNumber: function (newV) {
      if (newV) this.scanCard = '';
    },
    dealerInfo: function () {
      this.$nextTick(() => this.$refs.search.focus())
    },
    getDealerDialog: function (newV) {
      if (newV == false) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    barcodeDealer: function (newV) {
      if (newV.length == 8) {
        this._submitDealerBarcode(newV);
      }
    },
    socketChannelType: function () { },
    socketChannelCountdown: function (newV) {
      if(newV == 0)
      this.$nextTick(() => this.$refs.search.focus());
    },
    loading: function () { },
    loadingResult: function () { },
    channel: function (newV) {
      if (newV.is_open == true) this.resetCard();
    }
  },
  async created() {
    await this.initialDealer();
  },
  async mounted() {
    window.callInside = this;
  },
  methods: {
    async initialDealer() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        await this._submitDealerBarcode(dealerId.barcode)
      }
      else this._openDialogScanDealer(true);
    },
    moment,
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    alertVoidShoeDialog() {
      this.dialogVoidShoe = true;
    },
    alertResetShoeDialog() {
      this.dialogResetShoe = true;
    },
    _resetShoe() {
      this.$emit("submitResetShoe");
      this.dialogResetShoe = false;
    },
    _voidShoe() {
      this.$emit("submitVoidShoe");
      this.dialogVoidShoe = false;
    },
    _submitDealerBarcode(barcode) {
      this.$emit("submitBarcodeDealer", barcode);
      this.barcodeDealer = '';
    },
    _submitCardcode(cardCode) {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer') {
        if(!cardCode == ''){
          this.$emit("submitCardCode", cardCode);   
          this.allowInput = false;  
          setTimeout( ()=> {
                this.allowInput = true;
                this.$nextTick(() => this.$refs.search.focus());
              },1200);
        }
      }
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
        this.canUpdateStatus = false;
      }
    },

    _openDialogScanDealer(payload) {
      this.$emit("popUpDealer", payload);
    },

    _openDialogResult() {
      this.$emit("submitDynamicRes", true);
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text",
        };
      } else {
        return {
          status: "CLOSE",
          color: "red--text",
        };
      }
    },

    channelUpdateStatus(status) {
      if (status === true) {
        return {
          status: "CLOSE",
          desc: "SET Status TO",
          background: "red",
        };
      } else if (status === false) {
        return {
          status: "Please set RESULT",
          desc: "",
          background: "white",
        };
      } else {
        return {
          status: "OPEN",
          desc: "SET Status TO",
          background: "success",
        };
      }
    },

    _select() {
      this.dialogResult = true;
    },

    _selectGame() {
      this.$socket.emit("server-start-count-down", { status: "start" });
    },
    increaseColumn() {
      this.channel.column = this.channel.column + 1;
      this.$emit("submitUpdateChanel", this.channel);
    },
    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult() {
      // this.confirmDialog = true;
    },
    _preventCardInput() {
      this.allowInput = false;
    },
    _allowCardInput() {
      this.allowInput = true;
    },
    reloadWindow(){
      window.location.reload();
    },
    focusSearch(){
      this.$nextTick(() => this.$refs.search.focus());
    },
    async _updateChanelStatus() {  
      if(this.dealerInfo && this.dealerInfo.position == 'dealer'){
        if(this.channel.is_last_game == false){
        if(this.channel.is_open == false){
          
          this._submitCardcode(this.scanCard);
          this.scanCard = '';
          if(this.scanCard == 2888){
            this.allowInput = false;
            setTimeout(() => {
              this.allowInput = true;
              this.$nextTick(() => this.$refs.search.focus());
            }, 1200);
            this.scanCard = '';
          }
      }
      if (this.channel.is_open == null || this.channel.is_open == true
      ) {
        this.$emit("submitUpdateStatus", this.resultData);
        this.canUpdateStatus = false;
        setTimeout(this.letUpdateChanelStatus, 3000);
      }
    }else{
      if(this.channel.is_open == false){
          
          this._submitCardcode(this.scanCard);
          this.scanCard = '';
          if(this.scanCard == 2888){
            this.allowInput = false;
            setTimeout(() => {
              this.allowInput = true;
              this.$nextTick(() => this.$refs.search.focus());
            }, 1200);
            this.scanCard = '';
          }
      }
      if ((this.channel.is_open == false || !this.canUpdateStatus || Boolean(this.socketChannelCountdown && this.socketChannelCountdown > 2)) == false) {
          this.$emit("submitFinishedCard", this.resultData);
          this.canUpdateStatus = false;
          this.$nextTick(() => this.$refs.search.focus());
          setTimeout(this.letUpdateChanelStatus, 3000);
        }
    }
     }
    },

    async _updateOpenStatusLotto(){
      if(this.dealerInfo && this.dealerInfo.position == 'dealer'){
        if(this.channel.is_last_game == false){
          if(this.channel.is_open == false){
            
            this._submitCardcode(this.scanCard);
            this.scanCard = '';
            if(this.scanCard == 2888){
              this.allowInput = false;
              setTimeout(() => {
                this.allowInput = true;
                this.$nextTick(() => this.$refs.search.focus());
              }, 1200);
              this.scanCard = '';
            }
        }else  if (this.channel.is_open == null) {
          this.$emit("submitUpdateStatus", this.resultData);
          this.canUpdateStatus = false;
          setTimeout(this.letUpdateChanelStatus, 3000);
        }else{
          this.scanCard = '';
        }
        }else{
          if(this.channel.is_open == false){
              
              this._submitCardcode(this.scanCard);
              this.scanCard = '';
              if(this.scanCard == 2888){
                this.allowInput = false;
                setTimeout(() => {
                  this.allowInput = true;
                  this.$nextTick(() => this.$refs.search.focus());
                }, 1200);
                this.scanCard = '';
              }
          }else if (this.channel.is_open == null) {
              this.$emit("submitFinishedCard", this.resultData);
              this.canUpdateStatus = false;
              this.$nextTick(() => this.$refs.search.focus());
              setTimeout(this.letUpdateChanelStatus, 3000);
            }else{
              this.scanCard = '';
            }
        }
      }
    },

    async _finishedCardLotto() {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer')
      if(this.channel.is_last_game == true){
        if ((this.channel.is_open == false || !this.canUpdateStatus || Boolean(this.socketChannelCountdown && this.socketChannelCountdown > 2)) == false) {
          this.$emit("submitFinishedCard", this.resultData);
          this.canUpdateStatus = false;
          this.$nextTick(() => this.$refs.search.focus());
          setTimeout(this.letUpdateChanelStatus, 3000);
        }
      }
    },
    async _clearCard() {
      if (this.dealerInfo && this.dealerInfo.position == 'pr' || this.dealerInfo.position == 'pitbox')
      if (this.channel.is_open == false || this.channel.is_open == null) {
          this.$emit("submitClearCard", this.resultData);
        }
    },

    letUpdateChanelStatus() {
      this.canUpdateStatus = true;
    },
    correctColorPosition(position) {
      switch (position) {
        case 'dealer':
          return 'error';
        case 'pr':
          return 'primary';
        case 'pitbox':
          return 'success';
        default:
          break;

      }
    },

    ...mapMutations("$_lottoChannel", ["LOADING", "UPDATE_KDPAYOUT"]),
    ...mapActions("$_lottoChannel", ["resetCard"]),
  },
};
</script>

<style scoped>
.live-channel-info-container {
  width: 100%;
  height: 440px;
  border: 1px solid black;
  border-radius: 3px;
  position: relative;
  margin-top: 70px;
}
.default-count-down {
  font-size: 120px !important;
  letter-spacing: 9px !important;
  font-weight: 900;
  text-align: center;
  margin-top: 80px;
}

.count-number-container {
  justify-content: right;
  margin-top: 10px;
}

.channel-status {
  font-weight: 900;
  font-size: 40px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
  text-align: center;
  margin-top: 60px;
}

.result-image {
  max-width: fit-content;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}

.confirm-btn {
  color: white !important;
  font-weight: 700;
}

.right-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #fb8c00 !important;
}

.green-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #013220 !important;
}

.sample>>>input {
  margin-top: 5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}

.update-chanel-btn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: -7px !important;
}

.update-chanel {
  margin-top: -2px !important;
}

.cancel-btn {
  color: white !important;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  background-color: #3f9542 !important;
}

.dealer-photo {
  /* width: 45%;
  height: 190px;
  object-fit: cover; */
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: 50px;
  margin-top: 15px;
}

.dealer-info-container {
  width: 100%;
  height: 260px;
  border: 1px solid black;
  border-radius: 3px;
  position: relative;
  margin-top: 50px;
}
.dealer-info {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: -7px;
  text-transform: uppercase;

}

.game-control-container {
  margin-top: 0px;
}

/* .dealer-name {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 15px;
  margin-left:30px;
} */
.dealer-name {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: -40px;
  padding-left: 5px;
}
.dealer-title {
  font-size: 30px;
  text-align: right;
  font-weight: 800;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left:40px;
  text-decoration: underline;

}
.timer{
  font-size: 70px;
  text-align: center;
  font-weight: 800;
  margin-top: 15px;
  margin-bottom: 15px;
}

.dealer-dob {
  /* font-size: 18px;
  text-align: center; */
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 15px;
  margin-left:30px;
}

.dealer-last-login {
  /* font-size: 18px;
  text-align: center; */
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 15px;
  margin-left:30px;
}

.dealer-changer {
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: 0px
}

.player-card-container {
  height: 300px;
  
}

.dealer-card-container {
  height: 300px;
  border-left: 1px solid black;
  text-align: center;

}

.player-stand-card {
  margin-left: 55px;
  width: 150px;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}

.stand-card {
  width: 150px;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}

.player-vertical-card {
  width: 150px;
  height: 200px;
  margin-top: -10px;
  transform: rotate(270deg);
  position: absolute;
  left: 35.5%;
  border: 1px solid;
  border-radius: 5px;
}

.dealer-vertical-card {
  width: 150px;
  height: 200px;
  margin-top: -10px;
  transform: rotate(270deg);
  position: absolute;
  left: 35.5%;
  border: 1px solid;
  border-radius: 5px;
}

.dealer-stand-card {
  margin-left: 55px;
  width: 150px;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}

.type_player {
  justify-content: center !important;
  width: 100%;
}

.type-player-title {
  font-size: 20px !important;
  font-weight: 700;
  color: rebeccapurple;

}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.v-input input{
  max-height: 50px !important;
  
}
</style>

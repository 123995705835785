const UPDATE_CHANNEL = (state, data) => {
  state.currentChannel = data;
};
const UPDATE_KDPAYOUT = (state, data) => {
  state.objKdPayout = data;
};
const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
  state.currentChannel = data;
};
const BTN_RESULT = (state, data) => {
  state.objCoTypeOfBetting = data;
};
const LOADING = (state, data) => {
  state.loading = data;
};

const LOADINGRESULT = (state, data) => {
  state.loadingResult = data;
};

const SET_RESULT = (state, data) => {
  state.selectResult = data;
};

const RESULT_TABLE = (state, data) => {
  state.objCoResult = data;
};

const SUMMARY_RESULT = (state, data) => {
  state.objSummaryCoResult = data;
};

const POPUP_DIALOG_RESULT = (state, data) => {
  state.dialogResult = data;
};

const GET_LAST_RESULT = (state, data) => {
  state.lastResult = data;
};

const GET_SKIP_COLUMN = (state, data) => {
  state.resultSkipColumn = data;
};

const GET_SKIP_ROW = (state, data) => {
  state.resultSkipRow = data;
};

const GET_SKIP_BETWEEN = (state, data) => {
  state.isNoResultBetween = data;
};

const UPDATE_DEALER_INFO = (state, data) => {
  state.dealerInfo = data;
};

const POPUP_DIALOG_SCAN_DEALER = (state, data) => {
  state.dealerDialog = data;
};

const RESULT_EVENT = (state, data) => {
  state.resultEvent = data;
};

const CURRENT_CARD = (state, data) => {
  state.currentCard = data;
};

const CURRENT_TOTAL = (state, data) => {
  state.currentTotal = data;
};

const ALLOW_SCAN = (state, data) => {
  state.allowScanCard = data;
};

export default {
  UPDATE_DEALER_INFO,
  POPUP_DIALOG_SCAN_DEALER,
  UPDATE_CHANNEL,
  BTN_RESULT,
  SET_RESULT,
  SUMMARY_RESULT,
  RESULT_TABLE,
  UPDATE_CHANNEL_AFTER_RESULT,
  LOADING,
  LOADINGRESULT,
  UPDATE_KDPAYOUT,
  POPUP_DIALOG_RESULT,
  GET_LAST_RESULT,
  GET_SKIP_COLUMN,
  GET_SKIP_ROW,
  GET_SKIP_BETWEEN,
  RESULT_EVENT,
  CURRENT_CARD,
  CURRENT_TOTAL,
  ALLOW_SCAN,
};

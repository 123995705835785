<template>
  <div>
    <div class="section-header" hidden>
      <h1>Change Password</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Change Password</div>
      </div>
    </div>
    <v-alert type="success" :value="alertS" style="width: 30%">
      Password Successfully Change
    </v-alert>
    <v-alert type="danger" :value="alertE" style="width: 30%; text-align: center" class="text-right">
      Something When wrong, fail to change password
    </v-alert>
    <section class="section">

      <div class="container mt-5 mx-auto">
        <div class="row">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-3 col-lg-4 offset-lg-4 col-xl-3 offset-xl-4">
            <div class="card card-primary" style="background-color: #babedd !important;">
              <div class="card-header">
                <h4>Reset Password</h4>
              </div>
              <div class="card-body">
                <!-- <p class="text-muted">Enter Your New Password</p> -->
                <form method="POST">
                  <div class="form-group">
                    <label for="password">New Password</label>
                    <input id="password" class="form-control pwstrength"  v-model="password"
                      :counter="10" :rules="rules" label="Change Password"
                      style="border: 1px solid black!important;"
                      tabindex="2" required
                     >
                    <div id="pwindicator" class="pwindicator">
                      <div class="bar"></div>
                      <div class="label"></div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <label for="password-confirm">Confirm Password</label>
                    <input id="password-confirm" type="password" class="form-control" name="confirm-password"
                      tabindex="2" required>
                  </div> -->
                  <div class="form-group">
                    <button type="button" class="btn btn-primary btn-lg btn-block" tabindex="4" @click="save">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      show: false,
      valid: true,
      rules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length <= 10 && v.length >= 6) ||
          "Password must be less than 10 and grater 6",
      ],
      password: null,
      alertS: false,
      alertE: false,
    };
  },
  computed: {
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
  },
  methods: {
    save() {
      let data = { password: this.password };
      let user_id = this.userp._id;
      this.$request
        .put({
          url: "users/changePassword/" + user_id,
          data,
        })
        .then((res) => {
          if (res.data.code) {
            this.alertS = true;
            setTimeout(() => {
              this.alertS = false;
            }, 1500);
          } else {
            this.alertE = true;
            setTimeout(() => {
              this.alertE = false;
            }, 1500);
          }
        });
    },
  },
};
</script>

<style scoped>
  .input-group-text, select.form-control:not([size]):not([multiple]), .form-control:not(.form-control-sm):not(.form-control-lg){
    color: black!important;
    border-radius: 5px !important;
    font-size: 14px !important;
    padding: 10px 15px !important;
    height: 42px !important;
  }
  input:focus, input[type]:focus{
    color: black!important;
  }
</style>

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    list: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentList: {
        result_name: 0,
        fight_no: "",
        group_no: false,
        channel_type: 0
    },
    deleteItems: [],
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

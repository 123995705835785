/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchCurrentChannel = async ({ commit }, data) => {
    const response = await request.get({
        url: 'lotvn/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objChannel);
        commit('RESULT_TABLE', response.data.data.objResult);
    }
};



const reverseResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'lotvn/reverseResult',
        data
    });
    if (response.data.code) {
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
        
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const reverseCancelResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'lotvn/reverseResultToCancel',
        data
    });
    if (response.data.code) {
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};


export default {
    fetchCurrentChannel,
    reverseResult,
    reverseCancelResult
};
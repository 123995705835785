const getStatement = (state) => {
    return state.statement;
};

const getCountStatement = (state) => {
    return state.countStatement;
};
const getLoading = (state) => {
    console.log('loading...');
    return state.loading;
}
export default {
    getLoading,
    getStatement,
    getCountStatement,
};
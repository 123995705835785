/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchCurrentChannel = async ({ commit }, data) => {
    const response = await request.get({
        url: 'fantan/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
        commit('RESULT_TABLE', response.data.data.objCoResult);
    }
};



const reverseResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'fantan/reverseResult',
        data
    });
    if (response.data.code) {
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};

const UpdateChanelStatus = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.put({
        url: 'fantan/updateStatusChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchBtnResult = async ({ commit }, data) => {
    const response = await request.get({
        url: 'fantan/getCoTypeOfBetting',
        data
    });
    if (response.data.code) {
        commit('BTN_RESULT', response.data.data.objCoTypeOfBetting);
    }
};
const updateChannel = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'fantan',
        data
    });
    if (response.data) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const resetResult = async ({commit}) => {
   
    const response = await request.get({
        url: 'fantan/resetResult'
    });
    if (response.data.code) {
        let objSummaryCoResult = {
            BANKER: 0,
            PLAYER: 0,
            TIE: 0,
            CANCEL: 0
        }
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', objSummaryCoResult);
        commit('RESULT_TABLE', []);

    }
};

export default {
    fetchCurrentChannel,
    fetchBtnResult,
    reverseResult,
    updateChannel,
    UpdateChanelStatus,
    resetResult
};
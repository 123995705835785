import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    setting: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentSetting: {
        title_vn: "",
        title_en: "",
        title_thai: "",
        title_kh: ""
    },
    deleteItems: [],
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

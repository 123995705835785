import historyByFightFantan from '@/modules/historyByFightFantan';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightFantan',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightFantan,
        name: 'historyByFightFantan'
    }]
}];

export default routes;
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    currentChannel: {
        _id: null,
        tem_id: null,
        info: null,
        video_info: null,
        date: null,
        fight_no: null,
        live_urls: [],
        maintain_desc: null,
        is_maintain: null,
        // enable: null,
        is_open: null,
    },
    objCoResult: [],
    objCoTypeOfBetting: [],
    results: [],
    objSummaryCoResult: {
        L: 0,
        S: 0,
        CANCEL: 0
    },
    loading: false,
    loadingResult: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListSetting = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true

    const response = await request.post({
        url: 'promotion/getAll',
        data
    });

    if (response.data.code) {
        commit('UPDATE_SETTING', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createSetting = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'promotion',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created app.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created app.`)
};

const updateSetting = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'promotion/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated app.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated app.`)
};

const deleteSetting = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'promotion/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted role.`)
    } else vm.$toastr.e(`'failed delete role.`)
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentSetting = async({ commit }, payload) => {
    commit('UPDATE_CURRENT_SETTING', payload);
};

export default {
    fetchListSetting,
    fetchPopUp,
    fetchCurrentSetting,
    createSetting,
    updateSetting,
    deleteSetting
};

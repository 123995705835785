<template>
  <div>
    <v-row>
      <v-layout class="table-result" style="width:100%!important; padding: 10px 30px">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          <tr>
            
          </tr>
          <tr v-if="objCoResult.length > 0">
            <td>
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span  height="50px"> No </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #cfb006">A</v-btn>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #034fa0">B</v-btn>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #05a723">C</v-btn>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #6c0606">D</v-btn>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn  class="mx-2" fab dark small style="background-color: #ac0707">E</v-btn>
              </div>
            </td>
            <td v-for="(result, i) in objCoResult" :key="i" style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span v-if="result.no" height="50px"> {{result.no }} </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_a" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_a.color}">{{result.result.result_a.number_label[0] }} </v-btn>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_b" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_b.color}">{{result.result.result_b.number_label[0] }} </v-btn>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_c" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_c.color}">{{result.result.result_c.number_label[0] }} </v-btn>
              </div>  
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_d" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_d.color}">{{result.result.result_d.number_label[0] }} </v-btn>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                <v-btn v-if="result.result.result_e" class="mx-2" fab dark small :style="{backgroundColor: result.result.result_e.color}">{{result.result.result_e.number_label[0] }} </v-btn>
              </div> 
            </td>                                 
        </tr>
        <tr v-else>
          <td  style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  &nbsp;
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>  
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
                </div> 
                <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                    
                </div> 
            </td>  
        </tr>
        </tbody>
      </table>
    </v-layout>
   
    </v-row>
  </div>
</template>

<script>
//import { mapGetters, mapMutations } from "vuex";
export default {
  props: ['objCoResult', 'channel',],
  data() {
    return {
      dialogConfirm: false,
      defineResult: {
        chanel_id: null,
        resultTypeId: null,
        sub: []
      },
      disableBlue: false,
      disableRed: false,
      break: false,
      allowToClick: true,
      loadingData: false,
      noCid : 1,
      noRid : 1,
      totalResult : 0,
      rows: 3,
      cols: 20
    }
  },

  computed: {
    // orderResult() {
    //   return this.getDragonResults();
    // },
  },

  watch: {
    objCoResult: function () {
    },
    channel: function () {
    },
  },

  mounted() {

  },

  methods: {
  }
}
</script>


<style scoped>

.stage {
  width: 38px;
  height: 38px;
  padding: 3px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.range_4 {
  width:50px!important;
   background: radial-gradient(circle at 30% 120%, #323232, #79071b 90%, #000 100%)!important;
}
.range_5 {
   background: radial-gradient(circle at 30% 120%, #323232, #ff6882 90%, #000 100%)!important;
}
.range_1 {
  width:50px!important;
  height:50px!important;
   background: radial-gradient(circle at 30% 120%, #323232, #efc60d 90%, #000 100%)!important;
}
.range_2 {
  background: radial-gradient(circle at 30% 120%, #323232, #110de6 90%, #000 100%)!important;
}
.range_3 {
  background: radial-gradient(circle at 30% 120%, #323232, #090 90%, #000 100%)!important;
}
.l-result-icon {
  display: inline-block!important;
  width: 100%!important;
  height: 30px!important;
  margin: 0!important;
  padding-top: 16px;
    padding-left: 11px;
    color: white;
  border-radius: 50%!important;
  /* display: flex!important; */
  align-items: center!important;
  justify-content: center!important;
  position: relative!important;
  font-weight: 700!important;
  font-size: 20px!important;
  
}
.table-result {
  background-color: #acb8bd;
  overflow-x: scroll;
  width: 50%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}

.cancel-btn {
  color: white !important;
  ;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  ;
  background-color: #3F9542 !important;
}

.confirm-circle {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  padding: 20px;
  margin-top: 20px;
}

.meron {
  background-color: #b31013
}

.wala {
  background-color: #0b4592
}

.tie {
  background-color: #3F9542
}

.cancel {
  background-color: gray
}

.meron-text {
  color: #b31013
}

.wala-text {
  color: #0b4592
}

.tie-text {
  color: #3F9542
}

.cancel-text {
  color: gray
}

.fight-no-style {
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color: white
}

td:hover {
  background-color: #ffbf00 !important;
  ;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.p-banker {
  position: absolute;
  border-radius: 25px;
  left: 15px;
  top: 15px;
  width: 9px;
  height: 9px;
  background-color: #f44336;
}

.p-player {
  position: absolute;
  border-radius: 25px;
  right: 15px;
  bottom: 15px;
  width: 9px;
  height: 9px;
  background-color: #1876d2;
}

.result-block {
  position: relative;
}

.result-block .show-fightNo {
  visibility: hidden;
}

.result-block:hover .show-fightNo {
  visibility: visible;
  width: 55px;
  height: 55px;
  color: white;
  position: absolute;
  top: -28px;
  left: -27px;
  font-size: 17px;
  /* font-style: italic; */
  font-weight: bold;

}

.r-cir-blue {
  background-color: #0b4592;
}

.r-cir-red {
  background-color: #b31013;
}

.r-cir-green {
  background-color: #28a745;
}

.r-cir-cancel {
  background-color: grey;
}
.result-number{
  font-family:'khmer mef1'; font-weight:bold;color:black ;font-size: 17px
}
</style>

import baccaratSetting from '@/modules/slotMachineSetting/baccaratSetting';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/baccarat-setting',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'baccaratSetting',
				component: baccaratSetting
			}
		]
	}
];

export default routes;

import dodenSetting from '@/modules/slotMachineSetting/dodenSetting';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/doden-setting',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'dodenSetting',
				component: dodenSetting
			}
		]
	}
];

export default routes;

/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchGetAllKlakloukSetting = async ({ commit }, data) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'slot-klaklouk-setting/getAll',
        data
    });
    console.log("response.data.data",response.data);
    if (response.data.code) {
        commit('UPDATE_KLAKLOUK_SETTIN_DATA', response.data.data);
        commit('LOADING', false);
    }
};
const updateKlakloukSetting = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-klaklouk-setting/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_KLAKLOUK_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};

const updateKlakloukSetting2 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-klaklouk-setting-2/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_KLAKLOUK_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateKlakloukSetting3 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-klaklouk-setting-3/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_KLAKLOUK_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const updateKlakloukSetting4 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-klaklouk-setting-4/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_KLAKLOUK_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateKlakloukSetting5 = async ({ commit }, { vm, data, _id }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'slot-klaklouk-setting-5/'+_id,
        data
    });
   
    if (response.data.code) {
        commit('UPDATE_KLAKLOUK_SETTIN_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchPopUpPercent = async ({commit}, payload) => {
    commit('UPDATE_POPUP_PERCENT', payload);
};
const fetchCurrentKlakloukSettingData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'limitBetting/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchGetAllKlakloukSetting,
    updateKlakloukSetting,
    updateKlakloukSetting2,
    updateKlakloukSetting3,
    updateKlakloukSetting4,
    updateKlakloukSetting5,
    fetchPopUp,
    fetchPopUpPercent,
    fetchCurrentKlakloukSettingData,
    deleteData
};